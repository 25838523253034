/* tslint:disable:max-classes-per-file */
import React from 'react';
import { Utils } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AppService, Types } from '../../Services/AppService';
import { contactUsRoute, dashboardRoute, faqRoute, IAppMenuItem, investorProfileRoute } from '../../Services/RouteService';

@observer
export default class Sidebar extends React.Component {

    private investorService = AppService.get(Types.Services.CurrentInvestorService);
    private routingState = AppService.get(Types.Neo.Routing.GlobalRoutingState);
    private appLayout = AppService.get(Types.Services.AppLayout);

    public render() {

        return (
            <div className={Utils.joinWithSpaces("sidebar", this.appLayout.showMobileMenu ? "show" : "")} onClick={() => this.appLayout.showMobileMenu = false}>
                <div className="sidebar-overlay" />
                <div className="sidebar-menu">

                    {this.renderMenuItem(dashboardRoute)}
                    {this.renderMenuItem(investorProfileRoute)}
                    {this.renderMenuItem(faqRoute)}
                    {this.renderMenuItem(contactUsRoute)}
                </div>
                
            </div>
            
        );
    }

    private renderMenuItem(menuItem: IAppMenuItem) {
        const isSelected = this.routingState.observableRoute?.path === menuItem.path;
        
        if (menuItem.requiresReadyInvestor && !this.investorService.investorData?.readyToInvest) {
            return null;
        }

        return (
            <Neo.Tooltip content={menuItem.name} position="right">
                <Link to={menuItem.path!}>
                    <div className={"sidebar-menu-item" + (isSelected ? " active" : "")}>
                        <div className="sidebar-menu-icon">
                            <i className={Utils.getFontAwesomeClass(menuItem.icon!)} /> 
                        </div>
                        <div className="sidebar-menu-name">
                            {menuItem.name}
                        </div>
                    </div>
                </Link>
            </Neo.Tooltip>)
    }
}