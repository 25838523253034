import React from 'react';
import RegisterVM from './RegisterVM';
import { observer } from 'mobx-react';
import RegistrationPersonalDetailsComponent from './Components/RegistrationPersonalDetailsComponent';
import { RegistrationState } from '../../Models/Registration/Enums/RegistrationState';
import AccountTypeComponent from './Components/AccountTypeComponent';
import IdentificationComponent from './Components/IdentificationComponent';
import PoliticalSensitivityComponent from './Components/PoliticalSensitivityComponent';
import ProofOfResidenceComponent from './Components/ProofOfResidenceComponent';
import BankDetailsComponent from './Components/BankDetailsComponent';
import TaxDetailsComponent from './Components/TaxDetailsComponent';
import MandateComponent from './Components/MandateComponent';
import AccountCreatedComponent from './Components/AccountCreatedComponent';
import ETViewBase from '../ETViewBase';
import { EnumHelper } from '@singularsystems/neo-core';

@observer
export default class RegisterView extends ETViewBase<RegisterVM> {

    constructor(props: unknown) {
        super("Register", RegisterVM, props);

        this.sidebarType = "none";
    }

    public render() {
        if (this.viewModel.registrationState === RegistrationState.AccountCreated) {
            return <AccountCreatedComponent taskRunner={this.taskRunner} />
        }

        return (
            <div className="row">
                <div className="d-none d-md-block col-md-4 col-lg-3">
                    {!!this.viewModel.registrationState && this.renderSidebar()}
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                    <div className="register-main-container" >
                        {this.viewModel.registrationState === RegistrationState.PersonalDetails &&
                            <div>
                                <RegistrationPersonalDetailsComponent viewModel={this.viewModel.registrationPersonalDetailsComponentVM} registerVM={this.viewModel} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.AccountType &&
                            <div>
                                <AccountTypeComponent viewModel={this.viewModel.accountTypeComponentVM} registerVM={this.viewModel} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.Identification &&
                            <div>
                                <IdentificationComponent
                                    investor={this.viewModel.investor}
                                    taskRunner={this.taskRunner}
                                    onRegisterStateChange={state => this.viewModel.registrationState = state} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.PoliticalSensitivity &&
                            <div>
                                <PoliticalSensitivityComponent
                                    taskRunner={this.taskRunner}
                                    onRegisterStateChange={state => this.viewModel.registrationState = state} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.ProofOfResidence &&
                            <div>
                                <ProofOfResidenceComponent
                                    investor={this.viewModel.investor}
                                    taskRunner={this.taskRunner}
                                    onRegisterStateChange={state => this.viewModel.registrationState = state} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.BankDetails &&
                            <div>
                                <BankDetailsComponent
                                    investor={this.viewModel.investor}
                                    taskRunner={this.taskRunner}
                                    onRegisterStateChange={state => this.viewModel.registrationState = state} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.TaxDetails &&
                            <div>
                                <TaxDetailsComponent
                                    investor={this.viewModel.investor}
                                    taskRunner={this.taskRunner}
                                    onRegisterStateChange={state => this.viewModel.registrationState = state} />
                            </div>
                        }
                        {this.viewModel.registrationState === RegistrationState.Mandate &&
                            <div>
                                <MandateComponent
                                    taskRunner={this.taskRunner}
                                    onRegisterStateChange={state => this.viewModel.registrationState = state} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderSidebar() {
        const viewModel = this.viewModel,
            currentState = viewModel.registrationState;

        let nextState = EnumHelper.getItemMetadata(RegistrationState, currentState + 1).display;
        if (!isNaN(parseInt(nextState))) {
            nextState = "Completion";
        }

        const percent = viewModel.registrationState / RegistrationState.Mandate;
        const radius = 32;
        const circumference = radius * 2 * Math.PI;
        const offset = circumference - percent * circumference;

        return (
            <div className="register-sidebar">
                <svg className="progress-ring-svg" viewBox="0 0 72 72" width={72} height={72}>
                    <circle 
                        className="progress-ring-main"
                        strokeWidth="7"
                        fill="transparent"
                        r="32"
                        cx="36"
                        cy="36"
                    />
                    <circle 
                        className="progress-ring-primary"
                        strokeWidth="7"
                        fill="transparent"
                        r="32"
                        cx="36"
                        cy="36"
                        strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={offset}
                    />
                    <text 
                        className="progress-text" 
                        x="50%" 
                        y="43" 
                        textAnchor="middle">{currentState} / {RegistrationState.Mandate}</text>
                    </svg>

                <div className={"step-header"}>{EnumHelper.getItemMetadata(RegistrationState, currentState).display}</div>
                <div className={"next-step"}>Next: {nextState}</div>
            </div>
        )
    }
}