import { NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import ETInvestorVMBase from '../ETInvestorVMBase';

@NeoModel
export default class InvestorProfileVM extends ETInvestorVMBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public async initialise() {
        this.taskRunner.waitFor(this.investorService.investorData!.getFicaStatusesAsync());
    }
}