import { Attributes, DateUtils, EnumHelper, NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';
import { DataFrequency } from '../Enums/DataFrequency';

export enum TimeRange {
    Month,
    Year,
    ThreeYears
}

EnumHelper.decorateEnum(TimeRange, d => {
    d.describe(TimeRange.Month, "Month");
    d.describe(TimeRange.Year, "Year");
    d.describe(TimeRange.ThreeYears, "3 Years");
})

@NeoModel
export default class GraphDataCriteria extends ValueObject {

    @Rules.Required()
    @Attributes.Date()
    public fromDate: Date | null = DateUtils.add(DateUtils.today(), { months: -12 });

    @Rules.Required()
    @Attributes.Date()
    public toDate: Date | null = DateUtils.today();

    public frequency = DataFrequency.Monthly;

    // Client only properties / methods

    @Attributes.OnChanged<GraphDataCriteria>(c => c.onTimeRangeChanged)
    @Attributes.NoTracking()
    public timeRange: TimeRange = TimeRange.Year;

    @Attributes.NoTracking()
    public seriesId: string = "";

    private onTimeRangeChanged() {
        if (this.timeRange === TimeRange.Month) {
            this.fromDate = DateUtils.add(DateUtils.today(), { months: -1 });
            this.frequency = DataFrequency.Daily;
        } else if (this.timeRange === TimeRange.Year) {
            this.fromDate = DateUtils.add(DateUtils.today(), { months: -12 });
            this.frequency = DataFrequency.Weekly;
        } else if (this.timeRange === TimeRange.ThreeYears) {
            this.fromDate = DateUtils.add(DateUtils.today(), { months: -36 });
            this.frequency = DataFrequency.Monthly;
        }
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Graph Data Criteria";
    }
}