import { AppServices, Security } from '@singularsystems/neo-core';
import Types from './AppTypes';

import Axios from 'axios';
import AuthorisationService from './Services/AuthorisationService';
import HeaderTokenAuthService from './Services/AuthenticationService';
import { AppConfig } from './Models/AppConfig';
import { AppDataService } from './Services/AppDataService';
import { RouteService } from './Services/RouteService';
import AppLayout from './Services/AppLayout';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { SketchPicker } from 'react-color';
import AsyncSelect from 'react-select/async'
import ReactSelect from 'react-select';
import SecurityApiClient from './ApiClients/SecurityApiClient';
import { EtfSAErrorHandler } from './App/Components/EtfSAErrorHandler';
import InvestorApiClient from './ApiClients/InvestorApiClient';
import PortfolioApiClient from './ApiClients/PortfolioApiClient';
import DocumentApiClient from './ApiClients/DocumentApiClient';
import PoliticalSensitivityApiClient from './ApiClients/PoliticalSensitivityApiClient';
import ProvinceApiClient from './ApiClients/ProvinceApiClient';
import BankApiClient from './ApiClients/BankApiClient';
import BankAccountTypeApiClient from './ApiClients/BankAccountTypeApiClient';
import CountryApiClient from './ApiClients/CountryApiClient';
import CurrentInvestorService from './Services/CurrentInvestorService';
import InvestorQueryApiClient from './ApiClients/InvestorQueryApiClient';
import CatalogueApiClient from './ApiClients/CatalogueApiClient';
import OldSiteIntegration from './Services/OldSiteIntegration';
import UserInformationVM from './Views/InformationPanel/UserInformationVM';
import FAQApiClient from './ApiClients/FAQApiClient';
import StatementApiClient from './ApiClients/StatementApiClient';
import RecurringTradeRequestApiClient from './ApiClients/RecurringTradeRequestApiClient';
import OtpService from './Services/OtpService';
import TradeRequestApiClient from './ApiClients/TradeRequestApiClient';
import InstrumentApiClient from "./ApiClients/InstrumentApiClient";
import ReportingApiClient from './ApiClients/ReportingApiClient';
import SourceOfFundsApiClient from './ApiClients/SourceOfFundsApiClient';
import CartApiClient from './ApiClients/CartApiClient';
import InvestorDocumentApiClient from './ApiClients/InvestorDocumentApiClient';

const appModule = new AppServices.Module("App", container => {

    // Config
    container.bind(Types.Config).to(AppConfig).inSingletonScope();

    // Security
    container.bind(Types.Neo.Security.AuthenticationService).to(HeaderTokenAuthService).inSingletonScope();
    container.bind(Types.Neo.Security.AuthorisationService).to(AuthorisationService).inSingletonScope();
    container.bind(Types.Neo.Security.SecurityService).to(Security.SecurityService).inSingletonScope();

    // Api clients
    container.bind(Types.Neo.Axios).toConstantValue(Axios);
    container.bind(Types.ApiClients.InvestorApiClient).to(InvestorApiClient).inSingletonScope();
    container.bind(Types.ApiClients.InvestorQueryApiClient).to(InvestorQueryApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SecurityApiClient).to(SecurityApiClient).inSingletonScope();
    container.bind(Types.ApiClients.PortfolioApiClient).to(PortfolioApiClient).inSingletonScope();
    container.bind(Types.ApiClients.DocumentApiClient).to(DocumentApiClient).inSingletonScope();
    container.bind(Types.ApiClients.InvestorDocumentApiClient).to(InvestorDocumentApiClient).inSingletonScope();
    container.bind(Types.ApiClients.PoliticalSensitivityApiClient).to(PoliticalSensitivityApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ProvinceApiClient).to(ProvinceApiClient).inSingletonScope();
    container.bind(Types.ApiClients.BankApiClient).to(BankApiClient).inSingletonScope();
    container.bind(Types.ApiClients.BankAccountTypeApiClient).to(BankAccountTypeApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CountryApiClient).to(CountryApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CatalogueApiClient).to(CatalogueApiClient).inSingletonScope();
    container.bind(Types.ApiClients.FAQApiClient).to(FAQApiClient).inSingletonScope();
    container.bind(Types.ApiClients.StatementApiClient).to(StatementApiClient).inSingletonScope();
    container.bind(Types.ApiClients.RecurringTradeRequestApiClient).to(RecurringTradeRequestApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TradeRequestApiClient).to(TradeRequestApiClient).inSingletonScope();
    container.bind(Types.ApiClients.InstrumentApiClient).to(InstrumentApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ReportingApiClient).to(ReportingApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SourceOfFundsApiClient).to(SourceOfFundsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CartApiClient).to(CartApiClient).inSingletonScope();

    // Services
    container.bind(Types.Services.AppDataCache).to(AppDataService).inSingletonScope();
    container.bind(Types.Services.AppLayout).to(AppLayout).inSingletonScope();
    container.bind(Types.Services.RouteService).to(RouteService).inSingletonScope();
    container.bind(Types.Services.CurrentInvestorService).to(CurrentInvestorService).inSingletonScope();
    container.bind(Types.Services.OldSiteIntegration).to(OldSiteIntegration).inSingletonScope();
    container.bind(Types.Services.OtpService).to(OtpService).inSingletonScope();
    container.bind(Types.Services.UserInformationVM).to(UserInformationVM).inSingletonScope();

    // Components
    container.bind(Types.Neo.Components.Slider).toConstantValue(Slider);
    container.bind(Types.Neo.Components.Range).toConstantValue(Range);
    container.bind(Types.Neo.Components.SketchPicker).toConstantValue(SketchPicker);
    container.bind(Types.Neo.Components.AsyncSelect).toConstantValue(AsyncSelect);
    container.bind(Types.Neo.Components.ReactSelect).toConstantValue(ReactSelect);
    container.bind(Types.Neo.UI.ErrorHandler).to(EtfSAErrorHandler).inSingletonScope();
});

const appTestModule = new AppServices.Module("App", container => {

});

export { appModule, appTestModule };