import { Routing as NeoRouting } from '@singularsystems/neo-core';
import { Routing } from '@singularsystems/neo-react';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';

import Login from '../Views/Security/Login'

import NotFound from '../App/Components/404NotFound';
import LoggedOut from '../Views/Security/LoggedOut';
import RegisterView from '../Views/Registration/RegisterView';
import RegistrationActivation from '../Views/Registration/RegistrationActivationView';
import Dashboard from '../Views/Dashboard/Dashboard';
import ForgotPasswordView from '../Views/Security/ForgotPasswordView';
import ForgotUsernameView from '../Views/Security/ForgotUsernameView';
import InvestorProfileView from '../Views/Investor/InvestorProfileView';
import FAQView from '../Views/FAQView';
import ContactUsView from '../Views/ContactUsView';
import ProductView from '../Views/Investor/Trades/Product';
import PurchaseView from '../Views/Investor/Trades/Purchase';

interface IRouteExtensions {
    
}

export interface IAppRoute extends NeoRouting.IRoute, IRouteExtensions {

}

export interface IAppMenuItem extends NeoRouting.IMenuRoute, IRouteExtensions {
    requiresReadyInvestor?: boolean;
}

export const dashboardRoute: IAppMenuItem = { name: "Dashboard", path: "/", component: Dashboard, icon: "chart-line", requiresReadyInvestor: true };
export const investorProfileRoute: IAppMenuItem = { name: "Profile", path: "/investor/profile", component: InvestorProfileView, icon: "file-alt" };
export const faqRoute: IAppMenuItem = { name: "Frequently asked questions", path: "/faq", component: FAQView, icon: "question", allowAnonymous: true };
export const contactUsRoute: IAppMenuItem = { name: "Contact Us", path: "/contact-us", component: ContactUsView, icon: "phone", allowAnonymous: true };
export const productRoute: IAppMenuItem = { name: "Products", path: "/products", component: ProductView, icon: "phone", requiresReadyInvestor: true };

@injectable()
export class RouteService {

    private routeProvider: Routing.RouteProvider;

    constructor(private config = AppService.get(Types.Config)) {
        
        this.routeProvider = new Routing.RouteProvider(
            this.getMenuRoutes(),
            this.getPureRoutes(),
            NotFound,
        )
    }

    /**
     * Gets the routes provider
     */
    public get routes(): Routing.RouteProvider {
        return this.routeProvider;
    }

    private getMenuRoutes(): IAppMenuItem[] {
        return [
            dashboardRoute,
            investorProfileRoute,
            faqRoute,
            contactUsRoute
        ]
    }

    private getPureRoutes(): IAppRoute[] {
        return [
            {
                path: '/login', component: Login, allowAnonymous: true
            },
            {
                path: "/loggedout", component: LoggedOut, allowAnonymous: true
            },
            {
                path: "/register", component: RegisterView, allowAnonymous: true
            },
            {
                path: "/registerActivation", component: RegistrationActivation, allowAnonymous: true
            },
            {
                path: "/forgot-password", component: ForgotPasswordView, allowAnonymous: true
            },
            {
                path: "/forgot-username", component: ForgotUsernameView, allowAnonymous: true
            },
            {
                path: "/product", component: ProductView, routeChildren: [{
                    path: "/purchase", component: PurchaseView
                }]
            },
            investorProfileRoute
        ]
    }
}