import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import SourceOfFunds from '../Models/Catalogue/SourceOfFunds';

export interface ISourceOfFundsApiClient extends Data.IReadApiClient<SourceOfFunds, string> {

    /** 
     * Returns all Source of funds
     */
    getSourceOfFundsList(): AxiosPromise<Array<Model.PlainTrackedObject<SourceOfFunds>>>;

    // Client only properties / methods
}

@injectable()
export default class SourceOfFundsApiClient extends Data.ReadApiClient<SourceOfFunds, string> implements ISourceOfFundsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/SourceOfFunds`);
    }

    public getSourceOfFundsList(): AxiosPromise<Array<Model.PlainTrackedObject<SourceOfFunds>>> {
        return this.axios.get(`${this.apiPath}/GetSourceOfFundsList`);
    }

    // Client only properties / methods
}