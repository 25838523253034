import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import Bank from '../Models/Catalogue/Bank';
import BankAccountType from '../Models/Catalogue/BankAccountType';
import Country from '../Models/Catalogue/Country';
import Province from '../Models/Catalogue/Province';
import Region from '../Models/Catalogue/Region';
import AssetClass from '../Models/Catalogue/AssetClass';
import PortfolioCatalogue from '../Models/Catalogue/PortfolioCatalogue';
import { AppService, Types } from './AppService';
import { DebitOrderDays } from '../Models/Base/RecurringTradeRequest';
import TradeCost from '../Models/Base/TradeCost';
import Strategy from '../Models/Catalogue/Strategy';
import InstrumentProvider from '../Models/Instruments/InstrumentProvider';
import SourceOfFunds from '../Models/Catalogue/SourceOfFunds';

export enum LifeTime {
    Short = 30,
    Long = 240
}

@injectable()
export class AppDataService extends Data.CachedDataService {

    // TODO: Consider combining all maintenance data into one controller / api client.

    constructor(
        private countryApiClient = AppService.get(Types.ApiClients.CountryApiClient),
        private provinceApiClient = AppService.get(Types.ApiClients.ProvinceApiClient),
        private bankApiClient = AppService.get(Types.ApiClients.BankApiClient),
        private bankAccountTypeApiClient = AppService.get(Types.ApiClients.BankAccountTypeApiClient),
        private catalogueApiClient = AppService.get(Types.ApiClients.CatalogueApiClient),
        private debitOrderApiClient = AppService.get(Types.ApiClients.RecurringTradeRequestApiClient),
        private sourceOfFundsApiClient = AppService.get(Types.ApiClients.SourceOfFundsApiClient)) {
        super();
    }

    public countries = this.registerList(Country, this.countryApiClient.getCountryList, LifeTime.Long);
    public provinces = this.registerList(Province, this.provinceApiClient.getProvinceList, LifeTime.Long);
    public banks = this.registerList(Bank, this.bankApiClient.getBankList, LifeTime.Long);
    public bankAccountTypes = this.registerList(BankAccountType, this.bankAccountTypeApiClient.getBankAccountTypeList, LifeTime.Long);
    public sourceOfFunds = this.registerList(SourceOfFunds, this.sourceOfFundsApiClient.getSourceOfFundsList, LifeTime.Long) 

    private portfolioCataloguePromise: Promise<PortfolioCatalogue> | null = null;
    
    public async getPortfolioCatalogue() {
        if (!this.portfolioCataloguePromise) {
            this.portfolioCataloguePromise = this.catalogueApiClient.getPortfolioCatalogue();
        }
        return this.portfolioCataloguePromise;
    }

    public debitOrderDays = this.registerList(DebitOrderDays, this.debitOrderApiClient.getDebitOrderDaysList, LifeTime.Long);
    public tradeCosts = this.register(new Data.CallbackDataSource(this.catalogueApiClient.getTradeCosts, new TradeCost()), LifeTime.Long);
    public assetClasses = new Data.CallbackDataSource(async () => (await this.getPortfolioCatalogue()).assetClasses, [] as AssetClass[]);
    public regions = new Data.CallbackDataSource(async () => (await this.getPortfolioCatalogue()).regions, [] as Region[]);
    public strategies = new Data.CallbackDataSource(async () => (await this.getPortfolioCatalogue()).strategies, [] as Strategy[]);
    public providers = new Data.CallbackDataSource(async () => (await this.getPortfolioCatalogue()).instrumentProviders, [] as InstrumentProvider[]);
}