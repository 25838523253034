import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import InvestorAddressDetail from '../../../Models/Investors/InvestorAddressDetail';
import UpdateAddressCommand from '../../../Models/Investors/UpdateAddressCommand';
import { AppService, Types } from '../../../Services/AppService';
import ClickLink from '../../../App/Components/ClickLink';
import { FileUtils } from '@singularsystems/neo-core';

interface IUpdatableAddressDetailsProps {
    command: UpdateAddressCommand;
}

@observer
export class UpdatableAddressDetails extends React.Component<IUpdatableAddressDetailsProps> {

    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);
    private downloadTask = AppService.get(Types.Neo.TaskRunner);

    private async downloadDocument(documentID: number) {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getInvestorDocumentById(documentID));
        FileUtils.showSaveFile(response);
    }

    public render() {
        const command = this.props.command,
            addressDetail = command.addressDetail;

        return (
            <div>
                <AddressDetails addressDetail={addressDetail} maxColumns={2} />

                <div className="upload-section mt-3">
                    <label><span className="required-indicator">* </span>Proof of address</label>

                    <Neo.FileContext
                        uploadEndpoint={this.documentApiClient.getInvestorDocumentUploadUrl()}
                        afterUpload={docId => command.documentId = docId}
                        allowedExtensions={this.documentApiClient.allowedInvestorDocumentExtensions}
                        taskRunnerLoadingText={"Uploading Proof of Address"}>
                        <Neo.FileDropArea className={!command.documentId ? "neo-file-drop" : "neo-file-drop-uploaded"}>
                            <Neo.FileUploadButton className={!command.documentId ? "upload-icon" : "upload-completed-icon"}></Neo.FileUploadButton>
                            {command.documentId &&
                                <p className="upload-text">Upload complete</p>
                            }
                            <p className="upload-text">Drag your file here or browse to {!command.documentId ? "upload" : "update"}.</p>
                            <p className="file-type-text">Jpeg or pdf</p>
                            {command.documentId &&
                                <p className="file-download-text">
                                    <ClickLink onClick={() => this.downloadDocument(command.documentId!)}>Download <i className="fa fa-download" /></ClickLink>
                                </p>
                            }
                            <Neo.FileUploadProgress variant={"dark"} type={"animated"} reversed />
                        </Neo.FileDropArea>
                    </Neo.FileContext>
                </div>
            </div>
        )
    }
}

interface IAddressDetailsProps {
    addressDetail: InvestorAddressDetail;
    readOnly?: boolean;
    maxColumns: 1 | 2;
}

export class AddressDetails extends React.Component<IAddressDetailsProps> {

    private appDataCache = AppService.get(Types.Services.AppDataCache);

    public render() {
        const { addressDetail, maxColumns, readOnly } = this.props;

        return (
            <Neo.FormContext allReadOnly={readOnly}>
                <p hidden={readOnly}> <span className="required-indicator">* </span>indicates a required field</p>
                <Neo.FormGroup bind={addressDetail.meta.addressLine1} label={"Building or complex"} />
                <Neo.FormGroup bind={addressDetail.meta.addressLine2} label={<><span hidden={readOnly} className="required-indicator">* </span>Street address 1</>} />
                <Neo.FormGroup bind={addressDetail.meta.addressLine3} label={"Street address 2"} />
                <Neo.FormGroup bind={addressDetail.meta.suburb} label={<><span hidden={readOnly} className="required-indicator">* </span>Suburb</>} />
                <Neo.FormGroup bind={addressDetail.meta.city} label={<><span hidden={readOnly} className="required-indicator">* </span>City</>} />

                <Neo.GridLayout md={maxColumns} >
                    <Neo.FormGroup bind={addressDetail.meta.provinceID} select={{ itemSource: this.appDataCache.provinces }} label={<><span hidden={readOnly} className="required-indicator">* </span>Province</>} />
                    <Neo.FormGroup bind={addressDetail.meta.postalCode} maxLength={10} label={<><span hidden={readOnly} className="required-indicator">* </span>Postal code</>} />
                </Neo.GridLayout>
            </Neo.FormContext>
        )
    }
}