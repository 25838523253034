import { Data, List, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import Bank from '../Models/Catalogue/Bank';

export interface IBankApiClient {

    /**
     * Method fetch the banks.
     */
    getBankList(): AxiosPromise<List<Model.PlainObject<Bank>>>;

}

@injectable()
export default class BankApiClient extends Data.ApiClientBase implements IBankApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/bank`);
    }


    public getBankList(): AxiosPromise<List<Model.PlainObject<Bank>>> {
        return this.axios.get(`${this.apiPath}`)
    }

}