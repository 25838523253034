import { Neo } from '@singularsystems/neo-react';
import React from 'react';
import ClickLink from '../../../App/Components/ClickLink';
import StatusImage from '../../../App/Components/StatusImage';
import InvestorBankDetail from '../../../Models/Investors/InvestorBankDetail';
import { FicaStatus } from '../../../Models/Enums/FicaStatus';
import AccountInformationVM from '../../InformationPanel/AccountInformationVM';
import BankAccountDetails, { ThirdPartyBankAccountDetails } from '../../Investor/Components/BankAccountDetails';

interface IAccountIdentityInfoProps {
    viewModel: AccountInformationVM;
}

export class BankDetailsInfo extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel,
            investor = viewModel.investor!,
            bankDetails = investor.primaryBankDetails;

        return (
            <div>
                <div className="info-panel-status-header">
                    <h4>Bank Details</h4>
                    <StatusImage status={bankDetails?.ficaStatus ?? FicaStatus.NotSent} />
                </div>
                
                {bankDetails &&
                    <div className="mt-3">
                        <BankAccountDetails bankAccount={bankDetails} readOnly />
                    </div>}

                <Neo.Button className="mt-3" onClick={() => { viewModel.beginEditBankDetails(); }}>Change</Neo.Button>
            </div>
        )
    }
}

export class ThirdPartyBankDetailsInfo extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel,
            investor = viewModel.investor!,
            bankDetails = investor.thirdPartyBankDetails;

        return (
            <div>
                <div className="info-panel-status-header">
                    <h4>3rd Party Bank Account</h4>
                    <StatusImage status={bankDetails?.ficaStatus ?? FicaStatus.NotSent} />
                </div>
                
                <div className="mt-3">
                    {bankDetails &&
                        <div>
                            <ThirdPartyBankAccountDetails bankAccount={bankDetails} readOnly />

                            <Neo.Button className="mt-3" onClick={() => { viewModel.beginEdit3rdPartyBankDetails(); }}>Change</Neo.Button>
                        </div>}

                    {!bankDetails &&
                        <div>
                            <ClickLink onClick={() => { viewModel.beginEdit3rdPartyBankDetails(); }}><i className="fa fa-plus" /> Add 3rd Party Bank Details</ClickLink>
                        </div>}
                </div>
            </div>
        )
    }
}

export class BankDetailsEditModal extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel;
        
        return (
            <Neo.Modal title="New Bank Details" bindModel={viewModel.meta.editBankDetail} 
                closeButton={false}
                acceptButton={{ text: "Save Details", onClick: () => viewModel.saveBankDetail()}}>

                {(bankDetails: InvestorBankDetail) => (
                    <div>
                        <BankAccountDetails bankAccount={bankDetails} />
                    </div>
                )}
            </Neo.Modal>
        )
    }
}

export class ThirdPartyBankDetailsEditModal extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel;
        
        return (
            <Neo.Modal title="New 3rd Party Bank Details" bindModel={viewModel.meta.edit3rdPartyBankDetail} 
                size="lg"
                closeButton={false}
                acceptButton={{ text: "Save Details", onClick: () => viewModel.save3rdPartyBankDetail()}}>

                {(bankDetails: InvestorBankDetail) => (
                    <div>
                        <ThirdPartyBankAccountDetails bankAccount={bankDetails} />
                    </div>
                )}
            </Neo.Modal>
        )
    }
}