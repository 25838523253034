import { Attributes, DateUtils, Misc, NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class StatementCriteria extends ValueObject {

    @Rules.Required()
    @Attributes.Display("Investment Type")
    public portfolioTypeId: number = 0;

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public startDate: Date | null = DateUtils.monthStart(new Date());

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public endDate: Date | null = DateUtils.today();

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Statement Criteria";
    }
}