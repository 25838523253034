import { Attributes, DateUtils, LookupBase, Misc, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import InvestorLookup from '../Investors/InvestorLookup';
import { OtpType } from '../Registration/Enums/OtpType';

@NeoModel
export class TokenInfo extends LookupBase {

    public authToken: string = "";

    /**
     * Gets the no of seconds until the token expires.
     */
    @Attributes.Integer()
    public expiresIn: number = 0;

    // Client only properties / methods
    
    @Attributes.Date()
    private createdOn = new Date();

    @Attributes.Date()
    public get expiryDate(): Date {
        return DateUtils.add(this.createdOn, { seconds: this.expiresIn });
    }
}

@NeoModel
export default class LoginResult extends ModelBase {

    @Attributes.ChildObject(TokenInfo)
    public tokenInfo!: TokenInfo;

    public userID: number = 0;

    public userName: string = "";

    public fullName: string = "";

    public administrator: boolean = false;

    @Attributes.Date()
    @Rules.Required()
    public passwordChangeDate: Date | null = null;

    public emailAddress: string = "";

    public cellNo: string = "";

    public otpType: OtpType = OtpType.Email;

    public firstTimeLogin: boolean = false;

    @Attributes.Nullable()
    public investorID: number | null = 0;

    @Attributes.Nullable()
    @Attributes.Serialisation(Misc.SerialiseType.FullOnly)
    @Attributes.ChildObject(InvestorLookup)
    public investor: InvestorLookup | null = null;

    // Client only properties / methods

    public pendingOtpType: OtpType | null = null;

    public get displayOtpType() {
        return this.otpType;
    }
    public set displayOtpType(value: OtpType) {
        this.pendingOtpType = value;
    }

    public get canChangeOtpType() {
        return !!this.emailAddress && !!this.cellNo;
    }
}