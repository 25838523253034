import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotifyUtils } from '@singularsystems/neo-core';
import { Neo, Routing } from '@singularsystems/neo-react';
import { observer, Observer } from 'mobx-react';
import './Styles/App.scss';
import LeftPanel from './App/Components/LeftPanel';
import HeaderPanel from './App/Components/HeaderPanel';
import RouteView from './App/Components/RouteView';
import Footer from './App/Components/Footer';
import { AppService, Types } from './Services/AppService';
import AppLayout from './Services/AppLayout';
import OTPModal from "./App/Components/OTPModal";

@observer
export default class App extends React.Component {

    private appLayout = AppService.get(Types.Services.AppLayout);
    private config = AppService.get(Types.Config);

    componentDidMount() {
        setTimeout(() => this.appLayout.setup(), 0);
    }

    public render() {

        const layout = this.appLayout;
        const containerAppendStyle = layout.sidebarType === "main-logo" ? " big-sidebar-container" : (layout.sidebarType === "menu" ? " has-side-menu" : "");
        
        return (
            <BrowserRouter basename={this.config.baseUrl} getUserConfirmation={Routing.PageLeaveHandler.showNavigationConfirmation}>
                <main>
                    <div className={"app-container" + containerAppendStyle}>

                        {/* Left menu panel */}
                        <LeftPanel />

                        {/* Top header panel */}
                        <HeaderPanel />

                        {/* Right content panel */}
                        <div id="content-panel" className="app-right-panel">
                            <div className="container">

                                <Observer>
                                    {() => (
                                        <Neo.Loader task={AppLayout.globalTaskRunner} className="page-loader" showSpinner={false} />
                                    )}
                                </Observer>

                                <div className="app-content-area">
                                    <React.Suspense fallback={<div>Loading...</div>}>
                                        <RouteView />
                                    </React.Suspense>
                                </div>
                            </div>
                        </div>

                        {/* Page Footer */}
                        <Footer />

                        <Neo.ModalContainer />
                        <Neo.ToastContainer notificationStore={NotifyUtils.store} />
                        <Neo.TooltipProvider />
                        <OTPModal />
                    </div>
                </main>
            </BrowserRouter >
        );
    }
}