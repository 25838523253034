import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import { Purchase, Sale, Switch } from '../Models/Base/TradeRequest';
import APIResponse from '../Models/APIResponse';

export interface ITradeRequestApiClient {

    /** 
     * This method create a purchase to be submitted to global when the scheduler runs.
     */
    purchase(Model: Model.PartialPlainObject<Purchase>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /** 
     * This method create a Sale Request to be submitted to global when the scheduler runs.
     */
    sale(Model: Model.PartialPlainObject<Sale>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /** 
     * This method is used to transfer one instrument to another.
     * Currently only used the switch from the Money market Instrument but can be used for any switch.
     */
    switch(Model: Model.PartialPlainObject<Switch>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    // Client only properties / methods
}

@injectable()
export default class TradeRequestApiClient extends Data.ApiClientBase implements ITradeRequestApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/TradeRequest`);
    }

    public purchase(Model: Model.PartialPlainObject<Purchase>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/purchase`, Model);
    }

    public sale(Model: Model.PartialPlainObject<Sale>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/Sale`, Model);
    }

    public switch(Model: Model.PartialPlainObject<Switch>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/Switch`, Model);
    }

    // Client only properties / methods
}