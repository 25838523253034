import { EnumHelper } from '@singularsystems/neo-core';

export enum MaintenanceDocumentType {
    NormalMandate =	1,
    TaxFreeMandate = 2,
    TermsAndConditions = 3,
    PrivacyPolicy = 4,
    RetirementAnnuityMandate = 5,
    DebitOrderAuthority = 6,
    InvestmentAdviceMandate = 7,
    ThirdPartyDebitOrderAuthority = 8
}

EnumHelper.decorateEnum(MaintenanceDocumentType, decorator => {
    decorator.describe(MaintenanceDocumentType.TermsAndConditions, "Terms & Conditions");
    decorator.describe(MaintenanceDocumentType.ThirdPartyDebitOrderAuthority, "3rd Party Debit Order Authority");
});