import { Components, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../Models/Enums/NotificationDuration';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class RegistrationActivationVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    ) {
        super(taskRunner);
    }

    public token: string = "";
    public registrationSuccessful: boolean | null = null;

    public setToken(token: string) {
        this.token = token;
        this.activateRegistration()
    }

    public activateRegistration() {
        this.taskRunner.run(async (opt) => {
            opt.errorHandling = Components.ErrorDisplayType.ThrowError;
            var response = await this.securityApiClient.activateRegistrationEmail(this.token);
            if (response.data) {
                if(response.data.success){
                    this.notifications.addSuccess("Registration Successful", null, NotificationDuration.Standard);
                    this.registrationSuccessful = true;
                } else {
                    this.registrationSuccessful = false;
                }
            }
        }).catch((ex: string) => {
            this.registrationSuccessful = false;
        });
    }
}