import { Attributes, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class ChangePassword extends ApiModelBase {

    @Rules.Required()
    public oldPassword: string = "";

    @Rules.Required()
    public newPassword: string = "";

    // Client only properties / methods

    @Attributes.NoTracking()
    public confirmPassword: string = ""

    @Attributes.NoTracking()
    public passwordError: string | null = null;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => !!c.newPassword && c.newPassword !== c.confirmPassword, "Confirmation password must match new password.").onProperties(c => c.confirmPassword);
    }

    public toString(): string {
        if (this.isNew || !this.oldPassword) {
            return "New change password";
        } else {
            return this.oldPassword;
        }
    }
}