import { FileUtils } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import React from 'react';
import ClickLink from '../../../App/Components/ClickLink';
import StatusImage from '../../../App/Components/StatusImage';
import { DocumentType } from '../../../Models/Enums/DocumentType';
import UpdateIdentityCommand from '../../../Models/Investors/UpdateIdentityCommand';
import { AppService, Types } from '../../../Services/AppService';
import AccountInformationVM from '../../InformationPanel/AccountInformationVM';
import IdentityDetails from '../../Investor/Components/IdentityDetails';

interface IAccountIdentityInfoProps {
    viewModel: AccountInformationVM;
}

export default class AccountIdentityInfo extends React.Component<IAccountIdentityInfoProps> {

    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);

    private downloadTask = AppService.get(Types.Neo.TaskRunner);

    private async downloadIDDocument() {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getInvestorDocument(DocumentType.Identity));
        FileUtils.showSaveFile(response);
    }

    public render() {
        const viewModel = this.props.viewModel,
            investor = viewModel.investor!;

        return (
            <div>
                <div className="info-panel-status-header">
                    <h4>ID</h4>
                    <StatusImage status={investor.investorFICAStatusIdentity} />
                </div>
                
                <div className="mt-3">
                    <Neo.FormGroup label="Number" display={investor.meta.idNo} />
                </div>

                <div>
                    <ClickLink onClick={e => this.downloadIDDocument()}>Download ID <i className="fa fa-download" /></ClickLink>
                </div>

                {/* 
                    Id number cannot be changed for now.
                    <Neo.Button className="mt-3" onClick={() => { viewModel.beginEditIdentity(); }}>Change</Neo.Button> */}
            </div>
        )
    }
}

export class AccountIdentityEditModal extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <Neo.Modal title="ID" bindModel={viewModel.meta.editIdentity}
                size="lg"
                closeButton={false}
                acceptButton={{ text: "Submit", onClick: () => viewModel.saveIdentity()}}>
                {(command: UpdateIdentityCommand) => (
                    <IdentityDetails command={command} />
                )}
            </Neo.Modal>)
    }
}