import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import APIResponse from '../Models/APIResponse';
import PoliticalSensitivity from '../Models/Base/PoliticalSensitivity';

export interface IPoliticalSensitivityApiClient {

    /**
     * Method fetch the investors political sensitivity.
     */
    getInvestorPoliticalSensitivity(): AxiosPromise<Model.PlainObject<PoliticalSensitivity>>;

    /**
     * Method to save the investors political sensitivity.
     */     
    saveInvestorPoliticalSensitivity(PoliticalSensitivity: Model.PlainObject<PoliticalSensitivity>): AxiosPromise<Model.PlainObject<APIResponse>>;

}

@injectable()
export default class PoliticalSensitivityApiClient extends Data.ApiClientBase implements IPoliticalSensitivityApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/political-sensitivity`);
    }

    public getInvestorPoliticalSensitivity(): AxiosPromise<Model.PlainObject<PoliticalSensitivity>> {
        return this.axios.get(`${this.apiPath}`)
    }

    public saveInvestorPoliticalSensitivity(PoliticalSensitivity: Model.PlainObject<PoliticalSensitivity>): AxiosPromise<Model.PlainObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}`, PoliticalSensitivity)
    }

}