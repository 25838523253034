import { NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { injectable } from "inversify";
import InfoPanelController from "../../App/Components/InfoPanel/InfoPanelController";
import { NotificationDuration } from "../../Models/Enums/NotificationDuration";
import ChangeOTPPreference from "../../Models/Security/ChangeOTPPreference";
import ChangePassword from "../../Models/Security/ChangePassword";
import AppLayout from "../../Services/AppLayout";
import { AppService, Types } from "../../Services/AppService";

@injectable()
@NeoModel
export default class UserInformationVM extends Views.ViewModelBase {

    constructor(
        private authService = AppService.get(Types.Services.AppAuthenticationService),
        private globalNotifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private apiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        private investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient)) {
        super();
    }

    public userInfoPanelController = new InfoPanelController();
    
    public passwordDetails: ChangePassword | null = null;

    public get userInfo() {
        return this.authService.user?.userInfo;
    }

    // Password

    public beginChangePassword() {
        this.passwordDetails = new ChangePassword();
        this.userInfoPanelController.forward("PasswordDetails");
    }

    public changePassword() {
        this.passwordDetails!.passwordError = null;

        AppLayout.globalTaskRunner!.run(async () => {

            const result = await this.apiClient.changePassword(this.passwordDetails!.toJSObject());
            if (result.data) {
                this.passwordDetails!.passwordError = result.data;
            } else {
                this.globalNotifications.addSuccess("Password Changed Successfully", "", NotificationDuration.Standard);
                this.passwordDetails = null;
                this.userInfoPanelController.beginHideInfoPanel();
            }
        });
    }

    // OTP Preference

    public otpChangeDetails: ChangeOTPPreference | null = null;

    public beginChangeOTPPreference() {
        if (this.userInfo?.pendingOtpType) {
            this.otpChangeDetails = new ChangeOTPPreference(this.userInfo.pendingOtpType);
            this.userInfoPanelController.forward("ChangeOTPPreference");
        }
    }

    public confirmChangeOTPPreference() {
        this.investorApiClient.beginChangeOTPMethod(this.otpChangeDetails!.newMethod);
        this.otpChangeDetails!.areYouSure = true;
    }

    public endChangeOTPPreference() {
        this.taskRunner.run(async () => {
            const result = await this.investorApiClient.endChangeOTPMethod(this.otpChangeDetails!.otp);
            if (result.data === "Success") {
                this.userInfo!.otpType = this.otpChangeDetails!.newMethod;
                this.userInfo!.pendingOtpType = null;
                this.userInfoPanelController.back("Home");

                this.globalNotifications.addSuccess("OTP Preference", "Your OTP send method has been changed.", NotificationDuration.Standard);
            } else {

                this.globalNotifications.addDanger("OTP Preference", result.data, NotificationDuration.Long);
            }
        });
    }
}