import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { AppService, Types } from '../../Services/AppService';
import logo from "../../assets/img/ETFSA_On Black.png";
import hamburger from "../../assets/img/hamburger-icon.svg";
import { IETViewBase } from '../../Views/ETViewBase';
import UserStatus from './UserStatus';
import AccountInformationPanel from '../../Views/InformationPanel/AccountInformationPanel';
import UserInformationPanel from '../../Views/InformationPanel/UserInformationPanel';
import DocumentPanel from '../../Views/InformationPanel/DocumentPanel';

@observer
export default class HeaderPanel extends React.Component {

    private appLayout = AppService.get(Types.Services.AppLayout);
    private investorService = AppService.get(Types.Services.CurrentInvestorService);

    componentDidMount() {
        this.appLayout.setup();
    }

    componentDidUpdate() {
        this.appLayout.setup();
    }

    public render() {

        const layout = this.appLayout;

        const showHeader = !(Views.ViewBase.currentView as unknown as IETViewBase)?.hideHeader;
        const globalTask = Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined;

        let headerClassName = "app-header-panel";

        if (!layout.isScrollTop) {
            headerClassName += " scrolled";
        }

        return (
            <div>
                {globalTask && globalTask.isBusy &&
                    <Neo.ProgressBar className="page-progress-bar" progressState={globalTask.progressState} variant="dark" reverse type="animated" />}

                {showHeader && 
                    <div id="header-panel" className={headerClassName}>

                        <div className="container">
                            <div className="app-hamburger" onClick={() => this.appLayout.showMobileMenu = !this.appLayout.showMobileMenu}>
                                <img src={hamburger} alt="" />
                            </div>

                            <div className="app-header-logo">
                                <img className="header-logo" src={logo} alt="etfSA logo" style={{ width: '135px' }}/>
                            </div>

                            <div className="app-header-end">
                                <UserStatus />
                            </div>

                            {this.investorService.investorData &&
                                <>
                                    <AccountInformationPanel viewModel={this.investorService.investorData.accountInformationVM} />
                                    <DocumentPanel viewModel={this.investorService.investorData.documentsVM} />
                                </>}
                            <UserInformationPanel />
                        </div>

                    </div>}
            </div>
        )
    }
}