import { NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class Country extends APIModelBase {

    public countryID: number = 0;

    public countryName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.countryName) {
            return "New country";
        } else {
            return this.countryName;
        }
    }
}