import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { ITaskRunner, NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';
import { MaintenanceDocumentType } from '../../../Models/Base/Enums/MaintenanceDocumentType';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import DocumentHandler from '../../../Services/DocumentHandler'

export interface IMandataComponentProps {
    taskRunner: ITaskRunner;
    onRegisterStateChange: (state: RegistrationState) => void;
}

@injectable()
@NeoModel
export class MandateComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        public portfolioApiClient = AppService.get(Types.ApiClients.PortfolioApiClient),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        public investorService = AppService.get(Types.Services.CurrentInvestorService)) {

        super(taskRunner);
    }

    public agreeToTerms: boolean = false;

    public requireInvestmentAdvice: boolean = false;

    public agreeDebitOrderInstruction: boolean = false;

    public hasDebitOrderInstruction: boolean = this.investorService.investorData?.investor?.hasRecurringTradeRequest ?? false;

    initialise() {
    }

    public async acceptMandates() {
        return this.taskRunner.run(async () => {
            const response = await this.portfolioApiClient.signPortfolio(this.requireInvestmentAdvice);
            if (response.data.success) {
                NotifyUtils.addSuccess("Mandates signed", "You have successfully signed all mandates linked to your investment profile", NotificationDuration.Standard);
                this.investorService.investorData!.investor!.investorStatusID = RegistrationState.Mandate;
                return true;
            }
            return false;
        });
    }
}

@observer
export default class MandateComponent extends React.Component<IMandataComponentProps> {

    private viewModel = new MandateComponentVM(this.props.taskRunner);

    public async acceptMandates() {
        if (await this.viewModel.acceptMandates()) {
            this.props.onRegisterStateChange(RegistrationState.AccountCreated);
        }
    }

    private downloadMaintenanceDocument(maintenanceDocumentType: MaintenanceDocumentType) {
        this.viewModel.taskRunner.run("Fetching Document", async () => {
            var response = await this.viewModel.documentApiClient.fetchMaintenanceDocument(maintenanceDocumentType);
            if (response) {
                DocumentHandler.convertAndDownloadDocument(response, true);
            }
        });
    }

    private tcTextColor() {
        return this.viewModel.agreeToTerms ? "#369afe" : "#636e95";
    }

    private adviceTextColor() {
        return this.viewModel.requireInvestmentAdvice ? "#369afe" : "#636e95";
    }

    private debitOrderTextColor() {
        return this.viewModel.agreeDebitOrderInstruction ? "#369afe" : "#636e95";
    }

    private checkValidSignatures() {
        if (this.viewModel.hasDebitOrderInstruction) {
            return this.viewModel.agreeToTerms && this.viewModel.agreeDebitOrderInstruction;
        } else {
            return this.viewModel.agreeToTerms;
        }
    }

    public render() {

        const viewModel = this.viewModel;
        return (
            <div>
                <section>
                    <div className="register-mandate">
                        <h4>Sign your mandate</h4>
                        <p> <span className="required-indicator">* </span>indicates a required field</p>
                        <p>Please sign and read the mandate below to proceed</p>

                        <Neo.FormGroup className="mb-1" bind={viewModel.meta.agreeToTerms} label={<><span className="required-indicator">* </span>I agree to the terms</>} style={{ color: this.tcTextColor() }} ></Neo.FormGroup>
                        <a href="/" onClick={e => { this.downloadMaintenanceDocument(MaintenanceDocumentType.TermsAndConditions); e.preventDefault() }}>
                            Download terms and conditions
                        </a>

                        {viewModel.hasDebitOrderInstruction &&
                            <>
                                <Neo.FormGroup className="mt-3 mb-1" bind={viewModel.meta.agreeDebitOrderInstruction} label={<><span className="required-indicator">* </span>I have read and accepted the debit order authority</>} style={{ color: this.debitOrderTextColor() }} ></Neo.FormGroup>
                                <a href="/" onClick={e => { this.downloadMaintenanceDocument(MaintenanceDocumentType.DebitOrderAuthority); e.preventDefault() }}>
                                    Download debit order authority
                                </a>
                            </>
                        }
                        <div className="my-4">
                            <Neo.Button className="next-step" onClick={() => this.acceptMandates()} disabled={!this.checkValidSignatures()} >NEXT STEP</Neo.Button>
                        </div>
                    </div>
                </section >
            </div >
        );
    }
}