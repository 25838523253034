import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import Home from '../../Dashboard/Dashboard';

export interface IAccountCreatedComponentProps {
    taskRunner: ITaskRunner;
}

@injectable()
@NeoModel
export class AccountCreatedComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);
    }

    initialise() {
    }
}

@observer
export default class AccountCreatedComponent extends React.Component<IAccountCreatedComponentProps> {

    private viewModel = new AccountCreatedComponentVM();

    public navigateToDashboard() {
        this.viewModel.navigation.navigateToView(Home);
    }

    public render() {

        return (
            <div>
                <section>
                    <div className="register-account-created text-center">

                        <h3>Account Created</h3>

                        <Neo.Button className="mt-4" onClick={() => this.navigateToDashboard()}>TAKE ME TO MY DASHBOARD</Neo.Button>
                    </div>
                </section >
            </div >
        );
    }
}


