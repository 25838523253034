import { Attributes, NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class FAQ extends ApiModelBase {

    @Attributes.Integer()
    public faqid: number = 0;

    public question: string = "";

    public answer: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.question) {
            return "New faq";
        } else {
            return this.question;
        }
    }
}