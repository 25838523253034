import { Attributes, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../ApiModelBase';

@NeoModel
export default class ForgotUsername extends APIModelBase {

    @Rules.Required()
    @Attributes.Display("ID Number")
    public idNo: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew ) {
            return "New forgot username";
        } else {
            return this.idNo;
        }
    }
}