import { NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class Province extends ApiModelBase {

    public provinceID: number = 0;

    public provinceName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.provinceName) {
            return "New province";
        } else {
            return this.provinceName;
        }
    }
}