import { NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class SourceOfFund extends APIModelBase {

    public sourceOfFundsID: number = 0;

    public sourceOfFundsName: string = "";

    public requiresAdditionalInfo: boolean = false;

    public additionalInfoPrompt: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.additionalInfoPrompt) {
            return "New source of funds";
        } else {
            return this.additionalInfoPrompt;
        }
    }
}