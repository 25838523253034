import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import PortfolioCatalogue from '../Models/Catalogue/PortfolioCatalogue';
import TradeCost from '../Models/Base/TradeCost';

export interface ICatalogueApiClient {

    /**
     * Gets data used on the portfolio pages.
     */
    getPortfolioCatalogue(): Promise<PortfolioCatalogue>;

    /** 
     * Returns all Trade Costs and Rates
     */
    getTradeCosts(): Promise<TradeCost>;

    // Client only properties / methods
}

@injectable()
export default class CatalogueApiClient extends Data.ApiClientBase implements ICatalogueApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/catalogue`);
    }

    public async getPortfolioCatalogue(): Promise<PortfolioCatalogue> {

        const result = await this.axios.get(`${this.apiPath}/portfolio-catalogue`);
        return PortfolioCatalogue.fromJSObject<PortfolioCatalogue>(result.data);
    }

    public async getTradeCosts(): Promise<TradeCost> {

        const result = await this.axios.get(`${this.apiPath}/trade-costs`);
        return TradeCost.fromJSObject<TradeCost>(result.data);
    }

    // Client only properties / methods
}