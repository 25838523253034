class StringConstants {
    notFicadTooltip = "You are not fica verified."
    notReadyToInvestTooltip = "You are not ready to invest."
    pendingTradesTooltip = "This instrument has current pending trades."
    noValueAvailable = "This instrument is not available or has insufficient funds."

    getErrorNotificationText(message: string) {
        return `An error occured ${message}. Please contact ETFSA by email using hub@etfsa.co.za.`;
    }
}

export default new StringConstants();