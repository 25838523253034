import {  EnumHelper, ITaskRunner, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import InvestorBankDetail from "../../Models/Investors/InvestorBankDetail";
import InvestorTaxDetail from "../../Models/Investors/InvestorTaxDetail";
import { FicaStatus } from "../../Models/Enums/FicaStatus";
import { NotificationDuration } from "../../Models/Enums/NotificationDuration";
import UpdateAddressCommand from "../../Models/Investors/UpdateAddressCommand";
import UpdateIdentityCommand from "../../Models/Investors/UpdateIdentityCommand";
import Investor from "../../Models/Registration/Investor";
import { AppService, Types } from "../../Services/AppService";
import AppInvestorData from "../../Models/Investors/AppInvestorData";
import AppLayout from "../../Services/AppLayout";
import { InfoPanelController } from "../../App/Components/InfoPanel";
import InvestorLinkedPortfolioLookup from "../../Models/Investors/InvestorLinkedPortfolioLookup";
import InvestmentInfoVM from "./Components/InvestmentInfoVM";
import { PortfolioSign } from "../../Models/Registration/Portfolio";
import { PortfolioType } from "../../Models/Portfolio/PortfolioType";
import { MaintenanceDocumentType } from "../../Models/Base/Enums/MaintenanceDocumentType";

export enum InfoPanelView {
    Identity = 1,
    Address = 2,
    BankDetails = 3,
    AdditionalBankDetails = 4,
    TaxDetails = 5
}

@NeoModel
export default class AccountInformationVM extends Views.ViewModelBase {

    constructor(public readonly investorId: number,
        public appInvestorData: AppInvestorData,
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public portfolioApiClient = AppService.get(Types.ApiClients.PortfolioApiClient)) {
        super();
    }

    public selectedView: InfoPanelView | null = null;

    public panelController = new InfoPanelController();

    public async showInfoPanel(view: InfoPanelView | null) {

        this.selectedView = view;
        this.panelController.showInfoPanel(view ? "InvestorDetails" : "Home");

        await this.ensureInvestor(this.taskRunner);
    }

    private async ensureInvestor(taskRunner: ITaskRunner) {
        if (!this.investor) {
            const result = await taskRunner.waitFor(this.investorApiClient.getInvestor());
            this.investor = Investor.fromJSObject<Investor>(result.data);
        }
    }

    public beginHideInfoPanel() {
        this.panelController.beginHideInfoPanel();
    }

    public investor: Investor | null = null;

    // Identity

    public editIdentity: UpdateIdentityCommand | null = null;

    public beginEditIdentity() {
        this.editIdentity = new UpdateIdentityCommand(this.investorId);
        this.editIdentity.idNo = this.investor!.idNo;
        this.beginHideInfoPanel();
    }

    public saveIdentity() {
        AppLayout.globalTaskRunner!.run(async () => {
            if (this.editIdentity) {
                await this.investorApiClient.updateInvestorIdentity(this.editIdentity.toJSObject());
                this.investor!.idNo = this.editIdentity.idNo;
                this.investor!.investorFICAStatusIdentity = FicaStatus.Pending;
                this.appInvestorData.clearFicaStatuses();
                this.editIdentity = null;
                this.notifications.addSuccess("ID", "Your documents have been updated.", NotificationDuration.Standard);
            }
        });
    }

    // Address details

    public editAddress: UpdateAddressCommand | null = null;

    public async beginEditAddress(taskRunner?: ITaskRunner) {

        await this.ensureInvestor(taskRunner ?? this.taskRunner);

        this.editAddress = new UpdateAddressCommand(this.investorId, this.investor!.addressDetails.clone());
        this.beginHideInfoPanel();
    }

    public saveAddress() {
        AppLayout.globalTaskRunner!.run(async () => {
            if (this.editAddress) {
                await this.investorApiClient.updateInvestorAddressDetail(this.editAddress.toJSObject());
                if (this.investor) {
                    this.investor.addressDetails = this.editAddress.addressDetail;
                    this.investor.investorFICAStatusAddress = FicaStatus.Pending;
                }
                this.appInvestorData.clearFicaStatuses();
                this.editAddress = null;
                this.notifications.addSuccess("Address details", "Your address has been updated.", NotificationDuration.Standard);
            }
        });
    }

    // Bank details

    public editBankDetail: InvestorBankDetail | null = null;

    public beginEditBankDetails() {
        this.editBankDetail = new InvestorBankDetail();
        this.editBankDetail.investorID = this.investorId;
        this.beginHideInfoPanel();
    }

    public saveBankDetail() {
        AppLayout.globalTaskRunner!.run(async () => {
            if (this.editBankDetail) {
                await this.investorApiClient.updateInvestorBankDetail([this.editBankDetail.toJSObject()]);
                if (this.investor) {
                    this.investor.primaryBankDetails = this.editBankDetail;
                }
                this.appInvestorData.clearFicaStatuses();
                this.editBankDetail = null;
                this.appInvestorData.refetchInvestor();
                this.notifications.addSuccess("Bank details", "Your bank details have been updated.", NotificationDuration.Standard);
            }
        });
    }

    // 3rd Party Bank Details

    public edit3rdPartyBankDetail: InvestorBankDetail | null = null;

    public beginEdit3rdPartyBankDetails() {
        this.edit3rdPartyBankDetail = new InvestorBankDetail();
        this.edit3rdPartyBankDetail.investorID = this.investorId;
        this.edit3rdPartyBankDetail.isThirdParty = true;
        this.beginHideInfoPanel();
    }

    public save3rdPartyBankDetail() {
        AppLayout.globalTaskRunner!.run(async () => {
            if (this.edit3rdPartyBankDetail) {
                await this.investorApiClient.updateInvestorBankDetail([this.edit3rdPartyBankDetail.toJSObject()]);
                if (this.investor) {
                    this.investor.thirdPartyBankDetails = this.editBankDetail;
                }
                this.appInvestorData.clearFicaStatuses();
                this.edit3rdPartyBankDetail = null;
                this.notifications.addSuccess("3rd Party Bank Account", "Your bank details have been updated.", NotificationDuration.Standard);
            }
        });
    }

    // Tax Details

    public editTaxDetails: InvestorTaxDetail | null = null;

    public async beginEditTaxDetails() {
        await this.ensureInvestor(this.taskRunner);

        this.editTaxDetails = this.investor!.investorTaxDetail.clone(); 
        this.beginHideInfoPanel();
    }

    public saveTaxDetails() {
        AppLayout.globalTaskRunner!.run(async () => {
            if (this.editTaxDetails) {
                this.editTaxDetails.beforeSave();
                
                await this.investorApiClient.updateInvestorTaxDetail(this.editTaxDetails.toJSObject());
                if (this.investor) {
                    this.investor.investorTaxDetail = this.editTaxDetails;
                    this.investor.investorFICAStatusTaxNumber = FicaStatus.Pending;
                }
                this.appInvestorData.clearFicaStatuses();
                this.editTaxDetails = null;
                this.notifications.addSuccess("Tax details", "Your tax details have been updated.", NotificationDuration.Standard);
            }
        });
    }

    // Investment

    public investmentVM: InvestmentInfoVM | null = null;

    public selectPortfolio(portfolio: InvestorLinkedPortfolioLookup) {
        this.investmentVM = new InvestmentInfoVM(portfolio, this.panelController);
        this.investmentVM.initialise();
        this.panelController.forward("InvestmentDetails");
    }

    // New Investment

    public newInvestmentCommand: PortfolioSign | null = null;

    public beginNewInvestment(portfolioTypeId: number) {
        this.newInvestmentCommand = new PortfolioSign();
        this.newInvestmentCommand.portfolioTypeID = portfolioTypeId;
        // TODO: need portfolio type to document type mapping.
        this.newInvestmentCommand.maintenanceDocumentType = portfolioTypeId === PortfolioType.Discretionary ? 
            MaintenanceDocumentType.NormalMandate : 
            MaintenanceDocumentType.TaxFreeMandate;

        this.panelController.forward("StartInvestment");
    }

    public createInvestment() {

        this.taskRunner.run(async () => {
            if (this.newInvestmentCommand) {
                const accountName = EnumHelper.getItemMetadata(PortfolioType, this.newInvestmentCommand.portfolioTypeID).display;

                await this.portfolioApiClient.portfolioOpenAndSign(this.newInvestmentCommand.toJSObject());
                this.notifications.addSuccess("Open account", `Your ${accountName} account has been opened.`, NotificationDuration.Standard);

                if (this.appInvestorData?.investor) {
                    // Add the new portfolio to the cached local data, and select the account.
                    const lookup = new InvestorLinkedPortfolioLookup();
                    lookup.portfolioTypeID = this.newInvestmentCommand.portfolioTypeID;
                    lookup.portfolioType = accountName;
    
                    this.appInvestorData!.investor.portfolioList.push(lookup);
                    this.panelController.beginHideInfoPanel();
                    this.appInvestorData.selectedPortfolio = lookup;
                }
            }
        });
    }
}