import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import FAQ from '../Models/Base/FAQ';

export interface IFAQApiClient {

    /** 
     * Returns all Frequently asked questions
     */
    getFAQList(): AxiosPromise<Array<Model.PlainTrackedObject<FAQ>>>;

    // Client only properties / methods
}

@injectable()
export default class FAQApiClient extends Data.ApiClientBase implements IFAQApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/FAQ`);
    }

    public getFAQList(): AxiosPromise<Array<Model.PlainTrackedObject<FAQ>>> {
        return this.axios.get(`${this.apiPath}`);
    }

    // Client only properties / methods
}