import { List, Misc, ModalUtils, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import InvestorOTPDetails from "../../../Models/Investors/InvestorOTPDetails";
import TradeCost, { TradeFees } from "../../../Models/Base/TradeCost";
import { Switch } from "../../../Models/Base/TradeRequest";
import { NotificationDuration } from "../../../Models/Enums/NotificationDuration";
import Instrument from '../../../Models/Instruments/Instrument';
import { SwitchInstrumentDetail } from '../../../Models/Instruments/InstrumentDetail';
import { PortfolioInstrumentBalance } from "../../../Models/Portfolio/PortfolioBalance";
import Investor from "../../../Models/Registration/Investor";
import { AppService, Types } from '../../../Services/AppService';
import StringConstants from "../../../Services/StringConstants";
import Home from '../../Dashboard/Dashboard';

@NeoModel
export default class SwitchVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private otpService = AppService.get(Types.Services.OtpService),
        private tradeRequestApiClient = AppService.get(Types.ApiClients.TradeRequestApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache),
        private currentInvestorService = AppService.get(Types.Services.CurrentInvestorService),
        private investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        private instrumentApiClient = AppService.get(Types.ApiClients.InstrumentApiClient)
    ) {
        super(taskRunner);
    }

    public currentSwitch = new Switch();
    public investor: Investor | null = null;
    public investorOTPDetails: InvestorOTPDetails | null = null;
    public showSuccessModal: boolean = false;
    public isPercentage: boolean = true;
    public toInstruments = new List(Instrument);
    public totalPercentage: number = 0;
    public hasTradeCosts: boolean = true;
    public hasTradeCostsList: boolean[] = [];

    public async load(portfolioInstrumentBalance: PortfolioInstrumentBalance | null) {
        if (portfolioInstrumentBalance !== null) {
            this.setCurrentSwitch(await this.taskRunner.waitFor(this.appDataCache.tradeCosts.getDataAsync()), portfolioInstrumentBalance);
            var instrumentResult = await this.taskRunner.waitFor(this.instrumentApiClient.getTradeableInstrumentList(this.currentSwitch.portfolioTypeID));

            this.toInstruments.set(instrumentResult.data);
            var switchInstrument = this.toInstruments.find(c => c.instrumentID === this.currentSwitch.instrumentID_From);
            this.hasTradeCosts = portfolioInstrumentBalance.hasTradeCosts;
            
            // Remove Instrument_From from the available switch instruments
            if (switchInstrument) {
                this.toInstruments.remove(switchInstrument);
            }
            
            if (!this.investor) {
                const investorResult = await this.taskRunner.waitFor(this.investorApiClient.getInvestorOTPDetails());

                this.investorOTPDetails = InvestorOTPDetails.fromJSObject<InvestorOTPDetails>(investorResult.data);
                if (!this.investorOTPDetails) {
                    this.showNotificationAndClose("Error loading investor", "while opening the switch page");
                }
            }
        } else {
            this.showNotificationAndClose("Error loading portfolio", "while opening the switch page");
        }
    }

    private showNotificationAndClose(title: string, message: string) {
        this.notifications.addDanger(title, StringConstants.getErrorNotificationText(message), NotificationDuration.Long);
        this.navigateToDashboard();
    }

    public recalculateSwitch(currentInstrumentDetail: SwitchInstrumentDetail) {

        // Setting the rand amount before starting on the calculations
        if (!this.isPercentage) {
            currentInstrumentDetail.amount = this.currentSwitch.valueAvailable * currentInstrumentDetail.percentage;
        } else {
            currentInstrumentDetail.percentage = currentInstrumentDetail.amount / this.currentSwitch.valueAvailable;
        }

        // Setting the switch totals before doing the calculations
        this.currentSwitch.amount = this.currentSwitch.switchToInstrumentDetails.sum(c => c.amount);
        this.totalPercentage = this.currentSwitch.switchToInstrumentDetails.sum(c => c.percentage);
        this.currentSwitch.amount = Math.trunc(this.currentSwitch.amount * 100) / 100;
        this.hasTradeCostsList = [];

        if (this.currentSwitch.switchToInstrumentDetails.length > 0) {

            this.currentSwitch.switchToInstrumentDetails.forEach(instrumentDetail => {
                var currentInstrument = new Instrument();
                currentInstrument = this.toInstruments.find(i => i.instrumentID === instrumentDetail.instrumentID) ?? new Instrument();
                this.hasTradeCostsList.push(currentInstrument.hasTradeCosts);
                instrumentDetail.setSwitchTradeValues(this.currentSwitch, currentInstrument);
            });

            if (!this.hasTradeCostsList.find(c => c === true) && !this.currentSwitch.hasTradeCosts) {
                this.hasTradeCosts = false;
            } else {
                this.hasTradeCosts = true;
            }

        } else {
            this.setEmptySwitch();
        }
    }

    public onInstrumentSelected(currentInstrumentDetail: SwitchInstrumentDetail) {

        // Checking if the instrument added is not already in the list of switch instruments
        if (currentInstrumentDetail.instrumentID !== null && currentInstrumentDetail.instrumentID !== 0) {
            var duplicateCount = this.currentSwitch.switchToInstrumentDetails.filter(c => c.instrumentID === currentInstrumentDetail.instrumentID).length;
            if (duplicateCount > 1) {
                this.notifications.addDanger("Error loading instrument", "This instrument has already been chosen. Please choose another instrument.", NotificationDuration.Long);
                this.currentSwitch.switchToInstrumentDetails.remove(currentInstrumentDetail);
            }
            this.recalculateSwitch(currentInstrumentDetail);
        } else {
            currentInstrumentDetail.estimatedUnits = 0;
            currentInstrumentDetail.amount = 0;
            currentInstrumentDetail.percentage = 0;
            currentInstrumentDetail.tradeFees = new TradeFees();
            this.recalculateSwitch(currentInstrumentDetail);
        }
    }

    public checkInstrumentInd(currentInstrumentDetail: SwitchInstrumentDetail, isDisabled: boolean) {
        return isDisabled || (currentInstrumentDetail.instrumentID === undefined || currentInstrumentDetail.instrumentID === 0);
    }

    public switchPreferredProportions() {
        this.isPercentage = this.isPercentage ? false : true;
    }

    public async sendOtp(sendOtp: boolean = true) {
        const result = await this.otpService.showOtpModal(this.investorOTPDetails!, sendOtp);

        if (result.otp) {
            this.currentSwitch.otp = result.otp;
            await this.SubmitSwitch();
        }
        return result;
    }

    public async showOtp(resendOtp: boolean = true) {
        const result = await this.sendOtp(resendOtp);
        if (result.cancelled) {
            if ((await ModalUtils.showYesNo("Cancel Switch", "Are you sure you want to cancel the switch?")) === Misc.ModalResult.No) {
                this.showOtp(false);
            } else {
                this.otpService.currentModel = null;
            }
        }
    }

    private async SubmitSwitch() {
        if (this.currentSwitch.portfolioTypeID !== null) {
            const newSwitchInstruction = this.currentSwitch.toJSObject();
            await this.taskRunner.run(async (options) => {
                options.onError = (e) => {
                    if (!e.isServerError) {
                        var errorDetail = e.body.toLocaleLowerCase();
                        if (errorDetail.includes("otp")) {
                            if (errorDetail.includes("locked out")) {
                                this.otpService.currentModel = null;
                                this.navigateToDashboard();
                            } else {
                                this.showOtp(false);
                            }
                        };
                    } else {
                        this.notifications.addWarning("OTP", StringConstants.getErrorNotificationText("during the submission of your OTP"), NotificationDuration.Long);
                    }
                };
                await this.tradeRequestApiClient.switch(newSwitchInstruction);
                this.otpService.currentModel = null;
                this.showSuccessModal = true;
            });
        } else {
            this.setEmptySwitch();
            this.notifications.addWarning("Encountered an issue during submission", "Please try again", NotificationDuration.Long);
        }
    }

    private setEmptySwitch() {
        this.currentSwitch.switchToInstrumentDetails = new List(SwitchInstrumentDetail);
        this.currentSwitch.tradeFees = new TradeFees();
        this.currentSwitch.amount = 0;
        this.currentSwitch.switchToInstrumentDetails.addNew();
        this.hasTradeCosts = this.currentSwitch.hasTradeCosts;
    }

    private setCurrentSwitch(tradeCost: TradeCost, portfolioInstrumentBalance: PortfolioInstrumentBalance) {
        this.currentSwitch.tradeCosts = tradeCost;
        this.currentSwitch.valueAvailable = portfolioInstrumentBalance.valueAvailable;
        this.currentSwitch.instrumentID_From = portfolioInstrumentBalance.instrumentID;
        this.currentSwitch.instrumentName = portfolioInstrumentBalance.instrument;
        this.currentSwitch.portfolioTypeID = this.currentInvestorService.investorData!.selectedPortfolio!.portfolioTypeID;
        this.currentSwitch.hasTradeCosts = portfolioInstrumentBalance.hasTradeCosts;
        this.currentSwitch.switchToInstrumentDetails.addNew();
    }

    public navigateToDashboard(success: boolean = false) {
        if (success) {
            this.currentInvestorService.portfolioData!.resetPortfolioData();
            this.navigation.navigateToView(Home);
        } else {
            this.navigation.navigateToView(Home);
        }
    }
}