import React from 'react';
import { observer } from 'mobx-react';
import { IGraphData } from '../../../Models/Portfolio/GraphData';
import PopoverButton from '../../../App/Components/PopoverButton';
import GraphDataCriteria, { TimeRange } from '../../../Models/Portfolio/GraphDataCriteria';
import { EnumHelper, ITaskRunner } from '@singularsystems/neo-core';
import LineChart from './LineChart';
import ETLoader from '../../../App/Components/ETLoader';

interface IModalLineChartProps {
    graphTask: ITaskRunner;
    graphState: IGraphData;
    graphCriteria: GraphDataCriteria;
    setLineGraphTimeRange(timeRange: TimeRange): void;
}

@observer
export default class ModalLineChart extends React.Component<IModalLineChartProps> {
    
    public render() {
        const graphState = this.props.graphState,
            criteria = this.props.graphCriteria;

        return (
            <>
                <div className="dashboard-main-graph-header">
                    <div className="graph-title"></div>

                    {graphState && !this.props.graphTask.isBusy &&
                        <div className="graph-criteria content-box grey">
                            <div className="popover-drop-down">
                                <small>Period: {EnumHelper.getItemMetadata(TimeRange, criteria.timeRange).display}</small>
                                <PopoverButton menuItems={EnumHelper.asList(TimeRange).map(c => ({ text: c.name, onClick: () => this.props.setLineGraphTimeRange(c.id as TimeRange) }))} />
                            </div>
                        </div>}
                </div >
                {this.props.graphTask.isBusy && <ETLoader palette="blue" />}
                <LineChart graphData={graphState!} />
            </>

        );
    }
}