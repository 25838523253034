import { Attributes, EnumHelper, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import { InvestorTypes } from '../Registration/Enums/InvestorTypes';
import InvestorLinkedPortfolioLookup from './InvestorLinkedPortfolioLookup';

@NeoModel
export default class InvestorLookup extends LookupBase {

    public investorID: number = 0;

    @Attributes.Observable()
    public investorStatusID: number = 0;

    public investorTypeID: number = 0;

    public firstName: string = "";

    public lastName: string = "";

    public hasRecurringTradeRequest: boolean = false;

    public ficaVerified: boolean = false;

    @Attributes.Observable()
    public portfolioList = new List(InvestorLinkedPortfolioLookup);

    // Client only properties / methods

    public get investorType() {
        return EnumHelper.getItemMetadata(InvestorTypes, this.investorTypeID).display;
    }

    public get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}