import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import { DebitOrderDays, RecurringTradeRequest, RecurringTradeRequestCancel, RecurringTradeRequestPost, TAUDebitOrder } from '../Models/Base/RecurringTradeRequest';

export interface IRecurringTradeRequestApiClient extends Data.IReadApiClient<RecurringTradeRequest, string> {

    /**
     * Returns a list of all recurring trade requests for the specified PortfolioTypeID.
     */
     getRecurringTradeRequestDetails(portfolioTypeID: number): AxiosPromise<Model.PlainTrackedObject<RecurringTradeRequest>>;

    /**
     * Inserts or Updates the recurring trade request.
     */
    postRecurringTradeRequest(model: Model.PartialPlainObject<RecurringTradeRequestPost>): AxiosPromise;

    /**
     * This method cancels the recurring Trade requests
     */
    cancelRecurringTradeRequest(model: Model.PartialPlainObject<RecurringTradeRequestCancel>): AxiosPromise;

    /**
     * Returns all Debit Order Days
     */
    getDebitOrderDaysList(): AxiosPromise<Array<Model.PlainTrackedObject<DebitOrderDays>>>;

    /** 
     * Returns historical debit orders
     */
    getHistoricalDebitOrderList(PortfolioTypeID: number): AxiosPromise<Array<Model.PlainTrackedObject<TAUDebitOrder>>>;

    // Client only properties / methods
}

@injectable()
export default class RecurringTradeRequestApiClient extends Data.ReadApiClient<RecurringTradeRequest, string> implements IRecurringTradeRequestApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/debit-orders`);
    }

    public getRecurringTradeRequestDetails(portfolioTypeID: number): AxiosPromise<Model.PlainTrackedObject<RecurringTradeRequest>> {
        return this.axios.get(`${this.apiPath}?portfolioTypeID=${portfolioTypeID}`);
    }

    public postRecurringTradeRequest(model: Model.PartialPlainObject<RecurringTradeRequestPost>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/create`, model);
    }

    public cancelRecurringTradeRequest(model: Model.PartialPlainObject<RecurringTradeRequestCancel>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/cancel`, model);
    }

    public getDebitOrderDaysList(): AxiosPromise<Array<Model.PlainTrackedObject<DebitOrderDays>>> {
        return this.axios.get(`${this.apiPath}/allowed-days`);
    }

    public getHistoricalDebitOrderList(portfolioTypeID: number): AxiosPromise<Array<Model.PlainTrackedObject<TAUDebitOrder>>> {
        return this.axios.get(`${this.apiPath}/historical-debit-orders?PortfolioTypeID=${portfolioTypeID}`);
    }

    // Client only properties / methods
}