import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { NeoModel } from '@singularsystems/neo-core';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import RegisterVM from '../RegisterVM';
import { InvestorTypes } from '../../../Models/Registration/Enums/InvestorTypes';
import { PortfolioSign } from '../../../Models/Registration/Portfolio';
import { PortfolioType } from '../../../Models/Portfolio/PortfolioType';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';

export interface IAccountTypeComponentProps {
    viewModel: AccountTypeComponentVM;
    registerVM: RegisterVM;
}

@injectable()
@NeoModel
export class AccountTypeComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        public portfolioApiClient = AppService.get(Types.ApiClients.PortfolioApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);
    }

    public individualType: boolean = false;
    public companyType: boolean = false;
    public trustType: boolean = false;
    public clubType: boolean = false;
    public discretionaryInvestment: boolean = false;
    public taxFreeInvestment: boolean = false;

    public PortfolioSignTaxFree = new PortfolioSign();
    public PortfolioSignDiscrentionary = new PortfolioSign();
    public AccountOpened: boolean = false;
}

@observer
export default class AccountTypeComponent extends React.Component<IAccountTypeComponentProps> {

    public checkTypeValid() {
        if (this.props.viewModel.individualType && (this.props.viewModel.discretionaryInvestment || this.props.viewModel.taxFreeInvestment)) {
            return false;
        } else {
            return true;
        }
    }

    public updateAccountType() {
        this.props.viewModel.taskRunner.run(async () => {

            if (this.props.viewModel.individualType) {
                this.props.registerVM.investor.investorTypeID = InvestorTypes.Individual
            }
            var response = await this.props.viewModel.investorApiClient.putInvestor(this.props.registerVM.investor.toJSObject());
            if (response.data !== null) {
                if (response.data.success) {
                    this.openPortfolio()
                    this.props.registerVM.registrationState = RegistrationState.Identification;
                } else {
                    this.props.viewModel.notifications.addDanger("Investor", "An error occured when selecting your investor type. Please try again", NotificationDuration.Standard);
                }
            }
        }).catch((ex: string) => {
            this.props.viewModel.notifications.addDanger("Investor", "An error occured when selecting your investor type. Please try again", NotificationDuration.Standard);
        });
    }

    public async openPortfolio() {
        if (this.props.viewModel.meta.discretionaryInvestment.value) {
            this.props.viewModel.PortfolioSignDiscrentionary.portfolioTypeID = PortfolioType.Discretionary
            this.createPortfolio(this.props.viewModel.PortfolioSignDiscrentionary);
        };

        if (this.props.viewModel.meta.taxFreeInvestment.value) {
            this.props.viewModel.PortfolioSignTaxFree.portfolioTypeID = PortfolioType.TaxFree
            this.createPortfolio(this.props.viewModel.PortfolioSignTaxFree);
        };
        if (this.props.viewModel.AccountOpened) {
        }

    };

    public async createPortfolio(portfolioSign: PortfolioSign) {
        var response = await this.props.viewModel.portfolioApiClient.portfolioOpen(portfolioSign.toJSObject());
        var account = portfolioSign.portfolioTypeID === PortfolioType.TaxFree ? "Tax free" : "Discretionary";
        if (response.data.success) {
            this.props.viewModel.notifications.addSuccess("Account Selected", account + " account selected successfully", NotificationDuration.Standard);
            this.props.viewModel.AccountOpened = true;
        } else {
            this.props.viewModel.notifications.addDanger("Account Selected", "An error occured when selecting your " + account + " account. Please retry resubmit", NotificationDuration.Standard);
            this.props.viewModel.AccountOpened = false;
        }
    }

    public render() {

        return (
            <div>
                <p> <span className="required-indicator">* </span>indicates a required field</p>
                <div className="register-account-type">
                    <Neo.Card title={<div> <span className="required-indicator">* </span>Choose your account type</div>}>
                        <Neo.GridLayout md={1} lg={1}  >
                            <Neo.Input bind={this.props.viewModel.meta.individualType} label={"Individual"} />
                            <Neo.Input bind={this.props.viewModel.meta.companyType} label={"Company (coming soon)"} disabled={true} />
                            <Neo.Input bind={this.props.viewModel.meta.trustType} label={"Trust (coming soon)"} disabled={true} />
                            <Neo.Input bind={this.props.viewModel.meta.clubType} label={"Club/Association/Stokvel (coming soon)"} disabled={true} />
                        </Neo.GridLayout>
                    </Neo.Card>

                    <Neo.Card title={<div> <span className="required-indicator">* </span>Your investment type</div>}>
                        <Neo.GridLayout md={1} lg={1}  >
                            <Neo.Input bind={this.props.viewModel.meta.discretionaryInvestment} label={"Discretionary"} />
                            <Neo.Input bind={this.props.viewModel.meta.taxFreeInvestment} label={"Tax Free"} />
                        </Neo.GridLayout>
                    </Neo.Card>

                    <div className="my-4">
                        <Neo.Button onClick={this.updateAccountType.bind(this)} disabled={this.checkTypeValid()} >Next Step</Neo.Button>
                    </div>
                </div>
            </div>

        );
    }
}
