import { Misc, ModalUtils, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { injectable } from 'inversify';
import InvestorBankDetail from '../../../Models/Investors/InvestorBankDetail';
import { TradeFees } from '../../../Models/Base/TradeCost';
import { Sale } from '../../../Models/Base/TradeRequest';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import { PortfolioInstrumentBalance } from '../../../Models/Portfolio/PortfolioBalance';
import Investor from '../../../Models/Registration/Investor';
import { AppService, Types } from '../../../Services/AppService';
import StringConstants from "../../../Services/StringConstants";
import Home from '../../Dashboard/Dashboard';

@injectable()
@NeoModel
export default class WithdrawCashVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        public otpService = AppService.get(Types.Services.OtpService),
        private tradeRequestApiClient = AppService.get(Types.ApiClients.TradeRequestApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache),
        private currentInvestorService = AppService.get(Types.Services.CurrentInvestorService),
        private investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient)
        ) {
        super(taskRunner);
    }

    public currentSale = new Sale();
    public instrumentBalanceDetails: PortfolioInstrumentBalance | null = null;
    public investor: Investor | null = null;
    public bankDetails: InvestorBankDetail | null = null;
    private portfolioTypeID: number | null = 0;
    public isWithdrawAll: boolean = false;
    public showSuccessModal: boolean = false;

    public async initialise() {
        if(!this.instrumentBalanceDetails) {
            this.navigateToDashboard()
        } else {
            this.currentSale.tradeCosts = await this.taskRunner.waitFor(this.appDataCache.tradeCosts.getDataAsync());
            this.currentSale.valueAvailable = this.instrumentBalanceDetails!.valueAvailable;
            this.portfolioTypeID = this.currentInvestorService!.investorData!.selectedPortfolio!.portfolioTypeID;
    
            if (!this.investor) {
                const investorResult = await this.taskRunner.waitFor(this.investorApiClient.getInvestor());
                this.investor = Investor.fromJSObject<Investor>(investorResult.data);
                if (!this.investor) {
                    this.notifications.addDanger("Error loading investor", StringConstants.getErrorNotificationText("opening the withdrawal page"), NotificationDuration.Long);
                    this.navigateToDashboard();
                }
            }
        }
    }

    public setPortfolioInstrumentBalance(portfolioInstrumentBalance: PortfolioInstrumentBalance | null){
        if (portfolioInstrumentBalance === null) {
            this.notifications.addDanger("Error loading portfolio", StringConstants.getErrorNotificationText("opening the withdrawal page"), NotificationDuration.Long);
            this.navigateToDashboard();
        } else {
            this.instrumentBalanceDetails = portfolioInstrumentBalance;
        }
    }

    public setTransactionFees() {
        if (this.instrumentBalanceDetails!.hasTradeCosts) {
            if (this.currentSale.amount !== 0) {
                this.currentSale.tradeFees = this.currentSale.tradeCosts.getFees(this.currentSale.amount);
            } else {
                this.setEmptyTransaction();
            }
        }
    }

    public setBankDetails(){
        this.bankDetails = this.investor!.primaryBankDetails!;
    }

    public editBankDetails(){
        this.bankDetails = null;
        this.currentInvestorService!.investorData!.accountInformationVM.beginEditBankDetails();
        this.navigateToDashboard();
    }

    private setEmptyTransaction() {
        this.currentSale.tradeFees = new TradeFees();
        this.currentSale.amount = 0;
    }

    public withdrawAvailableUnits(){
        this.currentSale.amount = this.currentSale.valueAvailable;
        this.setTransactionFees();
        if (this.isWithdrawAll) {
            this.currentSale.amount = 0;
            this.setEmptyTransaction();
        }
        this.isWithdrawAll = this.isWithdrawAll ? false : true;
    }

    private async SubmitSale() {
        if (this.instrumentBalanceDetails !== null && this.portfolioTypeID !== null) {
            // Populate sell instruction
            this.currentSale.instrumentID = this.instrumentBalanceDetails.instrumentID;
            this.currentSale.portfolioTypeID = this.portfolioTypeID!;

            // Check OTP then submit sell instruction
            const newSellInstruction = this.currentSale.toJSObject();
            await this.taskRunner.run(async (options) => {
                options.onError = (e) => {
                    if (!e.isServerError) {
                        var errorDetail = e.body.toLocaleLowerCase();
                        if (errorDetail.includes("otp")) {
                            if (errorDetail.includes("locked out")) {
                                this.otpService.currentModel = null;
                                this.navigateToDashboard();
                            } else {
                                this.showOtp(false);
                            }
                        };
                    } else {
                        this.notifications.addWarning("OTP", StringConstants.getErrorNotificationText("during the submission of your OTP"), NotificationDuration.Long);
                    }
                }
                await this.tradeRequestApiClient.sale(newSellInstruction);
                this.otpService.currentModel = null;
                this.showSuccessModal = true;
            })
        } else {
            this.setEmptyTransaction();
            this.notifications.addWarning("Issue during submition of sale", "Please try again", NotificationDuration.Long);
        }
    }

    public async sendOtp(sendOtp: boolean = true) {
        this.bankDetails = null;
        const result = await this.otpService.showOtpModal(this.investor!, sendOtp);

        if (result.otp) {
            this.currentSale.otp = result.otp;
            await this.SubmitSale();
        }
        return result;
    }

    public async showOtp(resendOtp: boolean = true) {
        const result = await this.sendOtp(resendOtp);
        if (result.cancelled) {
            if ((await ModalUtils.showYesNo("Cancel Withdrawal", "Are you sure you want to cancel the withdrawal?")) === Misc.ModalResult.No) {
                this.showOtp(false);
            } else {
                this.otpService.currentModel = null;
            }
        }
    }

    public navigateToDashboard(success: boolean = false) {
        if (success) {
            this.currentInvestorService.portfolioData!.resetPortfolioData();
            this.navigation.navigateToView(Home);
        } else {
            this.navigation.navigateToView(Home);
        }
    }
}