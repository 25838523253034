import { observer } from 'mobx-react';
import React from 'react';
import InfoPanelBump from '../../../assets/img/info-panel-bump.png';
import InfoPanelController from './InfoPanelController';

interface IInfoPanelProps {
    controller: InfoPanelController;
    arrowAnchorSelector: string;
}

@observer
export default class InfoPanel extends React.Component<IInfoPanelProps> {

    public render() {
        return this.props.controller._show &&
            <InfoPanelContent {...this.props} />
    }
    
}

class InfoPanelContextProps {
    constructor(public readonly controller?: InfoPanelController) {

    }
}

export const InfoPanelContext = React.createContext<InfoPanelContextProps>(new InfoPanelContextProps());

@observer
class InfoPanelContent extends React.Component<IInfoPanelProps> {
    
    constructor(props: IInfoPanelProps) {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
    }

    private panelRef = React.createRef<HTMLDivElement>();
    private arrowRef = React.createRef<HTMLImageElement>();
    private timerHandle: any;
    private isHiding = false;

    componentDidMount() {
        window.addEventListener("mousedown", this.onMouseDown);

        if (this.panelRef.current) {
            const panelRect = this.panelRef.current.getBoundingClientRect();
            const height = window.innerHeight - panelRect.top;
            this.panelRef.current.style.minHeight = height + "px";
            this.panelRef.current.className += " show";

            if (this.arrowRef.current) {
                const anchor = document.querySelector(this.props.arrowAnchorSelector);
                if (anchor) {
                    const pointerRect = anchor.getBoundingClientRect();
                    const panelLeft = panelRect.left;
                    
                    this.arrowRef.current.style.left = Math.max(0, (pointerRect.left - panelLeft) + (pointerRect.width / 2) - 12) + "px";
                }
            }
        }
        
    }

    componentDidUpdate() {
        if (this.isHiding) {
            this.beginHide();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.onMouseDown);
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
        }
    }

    private onMouseDown(e: MouseEvent) {
        let target = e.target as HTMLElement | null;
        let level = 0;
        do {
            if (target === this.panelRef.current || target?.className.indexOf("modal ") === 0) {
                return;
            }
            target = target!.parentElement;
        }  while (target && ++level < 20)

        this.beginHide();
    }

    private beginHide() {
        if (this.panelRef.current) {
            this.panelRef.current.classList.remove("show");
            if (!this.timerHandle) {
                this.timerHandle = setTimeout(() => this.props.controller.endHideInfoPanel(), 300);
            }
        }
    }

    public render() {
        const controller = this.props.controller;
        this.isHiding = controller._isHiding;

        return (
            <InfoPanelContext.Provider value={new InfoPanelContextProps(this.props.controller)}>
                <div ref={this.panelRef} className="app-info-panel">
                    <img ref={this.arrowRef} src={InfoPanelBump} alt="" className="info-panel-bump" />

                    {this.props.children}
                </div>
            </InfoPanelContext.Provider>
        )
    }
}