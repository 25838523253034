import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class InvestorLinkedPortfolioLookup extends LookupBase {

    public portfolioID: number = 0;

    public portfolioTypeID: number = 0;

    public portfolioType: string = "";

    public isPrimary: boolean = false;

    public reinvestDividends: boolean = false;

    // Client only properties / methods

    @Attributes.Observable()
    public pendingReinvestDividends: boolean = false;

    protected onDeserialiseCompleted(wasCreated: boolean): void {
        this.pendingReinvestDividends = this.reinvestDividends;
    }
}