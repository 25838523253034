import { Data, List, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import BankAccountType from '../Models/Catalogue/BankAccountType';

export interface IBankAccountTypeApiClient {

    /**
     * Method fetch the bank account types.
     */
     getBankAccountTypeList(): AxiosPromise<List<Model.PlainObject<BankAccountType>>>;

}

@injectable()
export default class BankAccountTypeApiClient extends Data.ApiClientBase implements IBankAccountTypeApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/bank-account-type`);
    }


    public getBankAccountTypeList(): AxiosPromise<List<Model.PlainObject<BankAccountType>>> {
        return this.axios.get(`${this.apiPath}`)
    }

}