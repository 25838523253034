import { Views } from "@singularsystems/neo-react";
import { RouteParameterObject } from "@singularsystems/neo-react/dist/Routing/IRouteParameter";
import ETInvestorVMBase from './ETInvestorVMBase';

type SidebarType = "main-logo" | "menu" | "none";

export interface IETViewBase {
    readonly hideHeader: boolean;
    readonly sidebarType: SidebarType;
}

export default class ETViewBase<TViewModel extends Views.ViewModelBase, TParams extends RouteParameterObject<TParams> = {}> extends Views.ViewBase<TViewModel, TParams> {

    public hideHeader = false;
    public sidebarType: SidebarType = "menu";
 
    public get requiresInvestor() {
        return this.viewModel instanceof ETInvestorVMBase;
    }
}