import React from 'react';

interface ETLoaderProps {
    palette?: "red" | "blue";
}

export default class ETLoader extends React.Component<ETLoaderProps> {

    private canvasRef = React.createRef<HTMLCanvasElement>();
    private drawContext!: CanvasRenderingContext2D;

    constructor(props: {}) {
        super(props);

        this.animate = this.animate.bind(this);
        this.colors = this.props.palette === "blue" ? 
            ["#369AFE", "#1fd0a3", "#2e3643", "#FFC800"] : 
            ["#fa6464", "#fb996d", "#2e3643", "#FFC800"];
    }

    componentDidMount() {
        if (this.canvasRef.current) {
            this.drawContext = this.canvasRef.current.getContext("2d")!;

            window.requestAnimationFrame(this.animate);
        }
    }

    public render() {
        return (
            <div className="mt-3">
                <canvas ref={this.canvasRef} width={150} height={20} />
            </div>
        )
    }

    private endTime: number = 0;
    private colors: string[];
    private colorOffset = -1;

    private animate() {
        const context = this.drawContext;

        const radius = context.canvas.height / 2,
            speed = 1000,
            count = this.colors.length,
            countMinus1 = count - 1,
            gap = (context.canvas.width - (radius * 2)) / (count - 2);

        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        const now = Date.now();
        if (now >= this.endTime) {
            this.endTime = now + speed;
            this.colorOffset += 1;
            if (this.colorOffset === count) {
                this.colorOffset = 0;
            }
        }

        const start = this.endTime - speed;
        const progress = Math.max(0, Math.min(1, (now - start) / (this.endTime - start))),
            easeValue = this.easeOutQuart(progress);

        context.fillStyle = this.colors[countMinus1 - (this.colorOffset % count)];
        context.beginPath();
        context.arc(radius + (gap * (count - 2)), radius, (1 - easeValue) * radius, 0, 90);
        context.fill();

        context.fillStyle = this.colors[countMinus1 - ((this.colorOffset + count - 1) % count)];
        context.beginPath();
        context.arc(radius, radius, easeValue * radius, 0, 90);
        context.fill();

        for (let i = 0; i < count - 2; i++) {

            context.fillStyle = this.colors[countMinus1 - ((this.colorOffset + count - (i + 2)) % count)];
            context.beginPath();
            context.arc(radius + (gap * i) + (easeValue * gap), radius, radius, 0, 90);
            context.fill();
        }

        window.requestAnimationFrame(this.animate);
    }

    private easeOutQuart(x: number): number {
        return 1 - Math.pow(1 - x, 4);
    }
}