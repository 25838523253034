import { ModelBase, NeoModel } from "@singularsystems/neo-core";
import { runInAction } from "mobx";

interface IPanel {
    name: string;
    backToText?: string;
}

@NeoModel
export default class InfoPanelController extends ModelBase {

    public _show = false;
    public _isHiding = false;

    public get isVisible() {
        return this._show;
    }

    public showInfoPanel(startColumn = "Home") {
        this._direction = 0;
        this._currentColumn = startColumn;
        this._previousColumn = startColumn;
        
        this.endHideInfoPanel();
        this._show = true;
    }

    public beginHideInfoPanel() {
        if (this._show) {
            this._isHiding = true;
        }
    }

    public endHideInfoPanel() {
        this._show = false;
        this._isHiding = false;
    }

    public toggle() {
        if (this._show) {
            this._show = false;
        } else {
            this.showInfoPanel();
        }
    }

    private _slideAnimationActive = false;
    private _slideAnimationTimer: any = undefined;
    private _direction: number = 0;
    private _currentColumn = "";
    private _previousColumn = "";
    private history: string[] = [];
    private panels = new Map<String, IPanel>();

    public registerPanel(panel: IPanel) {
        if (!this.panels.has(panel.name)) {
            this.panels.set(panel.name, panel);
        }
    }

    public get lastHistory() {
        const lastHistoryName = this.history.length > 0 ? this.history[this.history.length - 1] : "Home";
        return this.panels.get(lastHistoryName) ?? { name: lastHistoryName, backToText: lastHistoryName};
    }

    public canShow(columnName: string) {
        return columnName === this._currentColumn || (columnName === this._previousColumn && this._slideAnimationActive);
    }

    public getClass(columnName: string, wasHidden: boolean) {
        if (this._direction !== 0) {
            if (columnName === this._previousColumn) {
                return `${this._direction > 0 ? "offset-left" : "offset-right"}`;
            } else if (wasHidden) {
                return `${this._direction > 0 ? "offset-right" : "offset-left"}`;
            }
        }
        return "";
    }

    public forward(columnName: string) {
        this.history.push(this._currentColumn);

        runInAction(() => {
            this._direction = 1;
            this._previousColumn = this._currentColumn;
            this._currentColumn = columnName;
            this.beginSlide();
        });
        
    }

    /** Goes back to the previous column unless a column name is specified. */
    public back(columnName?: string) {
        runInAction(() => {
            this._direction = -1;
            const history = this.history.pop() ?? "Home";
            this._previousColumn = this._currentColumn;
            this._currentColumn = columnName ?? history;
            
            this.beginSlide();
        });
    }

    public removeCurrentHistory() {
        this.history.pop();
    }

    private beginSlide() {
        this._slideAnimationActive = true;
        if (this._slideAnimationTimer) {
            clearTimeout(this._slideAnimationTimer);
        }
        this._slideAnimationTimer = setTimeout(() => {
            this._slideAnimationActive = false;
        }, 400);
    }
}