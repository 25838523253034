export enum InstrumentProvider {
    Invest = 1,
    ABSA = 2,
    Ashburton = 3,
    Cloud = 4,
    CoreShares = 5,
    FirstRand = 6,
    etfSA = 7,
    Coronation = 8,
    Investec = 9,
    Satrix = 10,
    Sygnia = 11,
    StandardBank = 12
}
