import { NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class PoliticalSensitivity extends APIModelBase {

    public politicalSensitivityID: number = 0;

    public investorID: number = 0;

    public associatedInd: boolean = false;

    public jurisdiction: string = "";

    public duration: string = "";

    public year: string = "";

    public position: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(ps => ps.jurisdiction.trim().length === 0 && ps.associatedInd, "Jurisdiction is required")
        rules.failWhen(ps => ps.duration.trim().length === 0 && ps.associatedInd, "Duration is required")
        rules.failWhen(ps => ps.year.trim().length === 0 && ps.associatedInd, "Year is required")
        rules.failWhen(ps => ps.position.trim().length === 0 && ps.associatedInd, "Position is required")
    }

    public toString(): string {
        if (this.isNew || !this.jurisdiction) {
            return "New political sensitivity";
        } else {
            return this.jurisdiction;
        }
    }
}