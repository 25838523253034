import { AppServices } from '@singularsystems/neo-core';
import { NeoReactTypes } from '@singularsystems/neo-react';
import { AppConfig } from './Models/AppConfig';
import { AppDataService } from './Services/AppDataService';
import { RouteService } from './Services/RouteService';
import AppLayout from './Services/AppLayout';
import { ISecurityApiClient } from './ApiClients/SecurityApiClient';
import HeaderTokenAuthService from './Services/AuthenticationService';
import { IPortfolioApiClient } from './ApiClients/PortfolioApiClient';
import { IInvestorApiClient } from './ApiClients/InvestorApiClient';
import { IInvestorQueryApiClient } from './ApiClients/InvestorQueryApiClient';
import { IDocumentApiClient } from './ApiClients/DocumentApiClient';
import { IPoliticalSensitivityApiClient } from './ApiClients/PoliticalSensitivityApiClient';
import { IProvinceApiClient } from './ApiClients/ProvinceApiClient';
import { IBankApiClient } from './ApiClients/BankApiClient';
import { IBankAccountTypeApiClient } from './ApiClients/BankAccountTypeApiClient';
import { ICountryApiClient } from './ApiClients/CountryApiClient';
import CurrentInvestorService from './Services/CurrentInvestorService';
import { ICatalogueApiClient } from './ApiClients/CatalogueApiClient';
import OldSiteIntegration from './Services/OldSiteIntegration';
import UserInformationVM from './Views/InformationPanel/UserInformationVM';
import { IFAQApiClient } from './ApiClients/FAQApiClient';
import { IStatementApiClient } from './ApiClients/StatementApiClient';
import { IRecurringTradeRequestApiClient } from './ApiClients/RecurringTradeRequestApiClient';
import { ITradeRequestApiClient } from './ApiClients/TradeRequestApiClient';
import { IInstrumentApiClient } from './ApiClients/InstrumentApiClient';
import OtpService from './Services/OtpService';
import { IReportingApiClient } from './ApiClients/ReportingApiClient'
import { ISourceOfFundsApiClient } from './ApiClients/SourceOfFundsApiClient';
import { ICartApiClient } from './ApiClients/CartApiClient'
import { IInvestorDocumentApiClient } from './ApiClients/InvestorDocumentApiClient';

const Types = {
    ApiClients: {
        SecurityApiClient: new AppServices.ServiceIdentifier<ISecurityApiClient>("SecurityApiClient"),
        InvestorApiClient: new AppServices.ServiceIdentifier<IInvestorApiClient>("InvestorApiClient"),
        InvestorQueryApiClient: new AppServices.ServiceIdentifier<IInvestorQueryApiClient>("InvestorQueryApiClient"),
        PortfolioApiClient: new AppServices.ServiceIdentifier<IPortfolioApiClient>("PortfolioApiClient"),
        DocumentApiClient: new AppServices.ServiceIdentifier<IDocumentApiClient>("DocumentApiClient"),
        InvestorDocumentApiClient: new AppServices.ServiceIdentifier<IInvestorDocumentApiClient>("InvestorDocumentApiClient"),
        PoliticalSensitivityApiClient: new AppServices.ServiceIdentifier<IPoliticalSensitivityApiClient>("PoliticalSensitivityApiClient"),
        ProvinceApiClient: new AppServices.ServiceIdentifier<IProvinceApiClient>("ProvinceApiClient"),
        BankApiClient: new AppServices.ServiceIdentifier<IBankApiClient>("BankApiClient"),
        BankAccountTypeApiClient: new AppServices.ServiceIdentifier<IBankAccountTypeApiClient>("BankAccountTypeApiClient"),
        CountryApiClient: new AppServices.ServiceIdentifier<ICountryApiClient>("CountryApiClient"),
        CatalogueApiClient: new AppServices.ServiceIdentifier<ICatalogueApiClient>("CatalogueApiClient"),
        FAQApiClient: new AppServices.ServiceIdentifier<IFAQApiClient>("FAQApiClient"),
        StatementApiClient: new AppServices.ServiceIdentifier<IStatementApiClient>("StatementApiClient"),
        RecurringTradeRequestApiClient: new AppServices.ServiceIdentifier<IRecurringTradeRequestApiClient>("RecurringTradeRequestApiClient"),
        TradeRequestApiClient: new AppServices.ServiceIdentifier<ITradeRequestApiClient>("TradeRequestApiClient"),
        InstrumentApiClient: new AppServices.ServiceIdentifier<IInstrumentApiClient>("InstrumentApiClient"),
        ReportingApiClient: new AppServices.ServiceIdentifier<IReportingApiClient>("ReportingApiClient"),
        SourceOfFundsApiClient: new AppServices.ServiceIdentifier<ISourceOfFundsApiClient>("SourceOfFundsApiClient"),
        CartApiClient: new AppServices.ServiceIdentifier<ICartApiClient>("CartApiClient")
    },
    Services: {
        AppAuthenticationService: AppServices.NeoTypes.Security.AuthenticationService.asType<HeaderTokenAuthService>(),
        AppDataCache: new AppServices.ServiceIdentifier<AppDataService>("Services.AppDataCache"),
        AppLayout: new AppServices.ServiceIdentifier<AppLayout>("Services.AppLayout"),
        RouteService: new AppServices.ServiceIdentifier<RouteService>("Services.RouteService"),
        CurrentInvestorService: new AppServices.ServiceIdentifier<CurrentInvestorService>("Services.CurrentInvestorService"),
        OldSiteIntegration: new AppServices.ServiceIdentifier<OldSiteIntegration>("Services.OldSiteIntegration"),
        OtpService: new AppServices.ServiceIdentifier<OtpService>("Services.OtpService"),
        UserInformationVM: new AppServices.ServiceIdentifier<UserInformationVM>("Services.UserInformationVM")
    },
    Config: AppServices.NeoTypes.Config.ConfigModel.asType<AppConfig>(),
    Neo: NeoReactTypes,
};

export default Types;