import { NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class Bank extends ApiModelBase {

    public bankID: number = 0;

    public bankName: string = "";

    public defaultBranchCode: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.bankName) {
            return "New bank";
        } else {
            return this.bankName;
        }
    }
}