import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import { InfoPanelView } from '../Views/InformationPanel/AccountInformationVM';
import AppInvestorData from '../Models/Investors/AppInvestorData';
import AppPortfolioData from '../Models/Portfolio/AppPortfolioData';

@injectable()
@NeoModel
export default class CurrentInvestorService extends ModelBase {

    constructor(private authenticationService = AppService.get(Types.Services.AppAuthenticationService)) {
        super();
    }

    @Attributes.Observable(false)
    private _investorData: AppInvestorData | null = null;

    @Attributes.Observable(false)
    private _portfolioData: AppPortfolioData | null = null;

    public get investorData() {
        const userInvestorId = this.authenticationService.user?.investorID;
        if (userInvestorId) {
            if (this._investorData?.investorId !== userInvestorId) {
                this._investorData?.dispose();
                this._investorData = new AppInvestorData(userInvestorId);
            }
            return this._investorData;
        } else {
            return null
        }
    }

    public beginShowAccountInformationPanel(view?: InfoPanelView) {
        if (this.investorData === null) {
            throw Error("Investor is required");
        }
        this.investorData.accountInformationVM.showInfoPanel(view ?? null);
    }

    public toggleAccountInformationPanel() {
        if (this.investorData?.accountInformationVM.panelController.isVisible) {
            this.investorData.accountInformationVM.panelController.beginHideInfoPanel();
        } else {
            this.beginShowAccountInformationPanel();
        }
    }

    public toggleDocumentPanel() {
        if (this.investorData?.documentsVM.panelController.isVisible) {
            this.investorData.documentsVM.panelController.beginHideInfoPanel();
        } else {
            this.investorData?.documentsVM.panelController.showInfoPanel();
        }
    }

    public get portfolioData() {
        const selectedPortfolio = this.investorData?.selectedPortfolio;
        if (selectedPortfolio) {
            if (this._portfolioData?.portfolio.portfolioID !== selectedPortfolio.portfolioID) {
                this._portfolioData = new AppPortfolioData(selectedPortfolio);
            }
            return this._portfolioData;
        }
        return null;
    }

    public async getPortfolioAsync() {
        await this.investorData!.getInvestorAsync();
        const portfolioData = this.portfolioData;
        if (portfolioData) {
            return await portfolioData.getPortfolioAsync();
        }
        return null;
    }
}