import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import StatementCriteria from '../Models/Portfolio/StatementCriteria';

export interface IStatementApiClient {

    /** 
     * Requests a statement from Global and returns the Statement as a Byte Array.
     */
    getStatementDocument(criteria: Model.PartialPlainNonTrackedObject<StatementCriteria>): AxiosPromise<Blob>;

    // Client only properties / methods
}

@injectable()
export default class StatementApiClient extends Data.ApiClientBase implements IStatementApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/Statement`);
    }

    public getStatementDocument(criteria: Model.PartialPlainNonTrackedObject<StatementCriteria>): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}?${Utils.getQueryString(criteria)}`, { responseType: "blob" });
    }

    // Client only properties / methods
}