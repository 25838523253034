import React from "react";
import { AppService, Types } from "../../Services/AppService";
import DownArrow from "../../assets/img/down-arrow-icon.svg";
import { observable } from "mobx";
import { observer } from "mobx-react";
import Popover from './Popover';
import ContactUsView from "../../Views/ContactUsView";
import settingsIcon from "../../assets/img/settings-icon.svg";
import documentsIcon from "../../assets/img/documents-icon.svg";

@observer
export default class UserStatus extends React.Component {

    private authService = AppService.get(Types.Services.AppAuthenticationService);
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);
    private investorService = AppService.get(Types.Services.CurrentInvestorService);
    private userInfomationVM = AppService.get(Types.Services.UserInformationVM);

    @observable
    private showPopover = false;

    private containerRef = React.createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);

        this.hideCard = this.hideCard.bind(this);
        this.logoutClicked = this.logoutClicked.bind(this);
    }

    private logoutClicked(e: React.MouseEvent) {
        this.authService.beginSignOut();
    }

    private hideCard(e: MouseEvent) {
        const parent = (e.target as HTMLElement).closest(".app-user-container");
        if (!parent) {
            this.showPopover = false;
        }
    }

    public render() {
        const user = this.authService.user;
        const isRegistered = !this.investorService.investorData?.mustCompleteRegistration;

        return (
            user &&
            <div>
                <div className="app-user-container">

                    {isRegistered &&
                        <div className="app-menu-items">
                            <div className="app-icon" id="user-documents-wrapper" onClick={() => this.investorService.toggleDocumentPanel()}>
                                <img src={documentsIcon} alt="Documents" />
                                <p>Downloads</p>
                            </div>
                            <div className="app-icon" id="user-settings-wrapper" onClick={() => this.investorService.toggleAccountInformationPanel()} >
                                <img src={settingsIcon} alt="Settings" />
                                <p>Settings</p>
                            </div>
                        </div>
                    }

                    <div className="app-user-header" onClick={e => { this.showPopover = !this.showPopover; }} ref={this.containerRef}>
                        <span className="app-user-icon">
                            <i className="fa fa-user" />
                        </span>
                        <span className="app-user-name">
                            {user.displayName}
                        </span>
                        <img alt="arrow" src={DownArrow} className="ml-1" />
                    </div>
                </div>

                {this.showPopover && this.containerRef.current &&
                    <Popover anchorElement={this.containerRef.current} alignment="right" yOffset={30} onHide={() => this.showPopover = false}>
                        {isRegistered && <div className="popover-item" onClick={() => this.userInfomationVM.userInfoPanelController.showInfoPanel()}>
                            Personal Details
                        </div>}
                        <div className="popover-item" onClick={() => this.navigation.navigateToView(ContactUsView)}>
                            Need Help?
                        </div>
                        <div className="popover-item" onClick={this.logoutClicked}>
                            Sign Out
                        </div>
                    </Popover>}
            </div>
        )
    }
}