import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import ForgotPasswordVM from './ForgotPasswordVM';
import { observer } from 'mobx-react';
import { AppService, Types } from '../../Services/AppService';
import ETViewBase from '../ETViewBase';

class ForgotPasswordParams {
    public token = {}
}

@observer
export default class ForgotPasswordView extends ETViewBase<ForgotPasswordVM, ForgotPasswordParams> {

    public static params = new ForgotPasswordParams();

    constructor(props: unknown) {
        super("Forgot Password", ForgotPasswordVM, props);

        this.hideHeader = true;
        this.sidebarType = "main-logo";
    }

    private appLayout = AppService.get(Types.Services.AppLayout);

    protected viewParamsUpdated() {
        this.viewModel.setToken(this.viewParams.token.asString());
    }

    public render() {

        const viewModel = this.viewModel;

        return (
            <div className="reset-password public-form-container">
                {!viewModel.emailReceived &&
                    <div>
                        <h2>Reset Password</h2>
                        <Neo.Form onSubmit={() => this.viewModel.resetPassword()}>
                            <Neo.GridLayout md={1} lg={1}  >
                                <div className="mb-5">
                                    <p>Please enter the username you used to register to retrieve your password</p>
                                </div>
                                
                                <Neo.FormGroup label="Your Username" bind={viewModel.forgotPassword.meta.username} />

                                <div className="text-right">
                                    <Neo.Button disabled={!viewModel.forgotPassword.isValid} isSubmit>Submit</Neo.Button>
                                </div>
                            </Neo.GridLayout>
                        </Neo.Form>
                    </div>
                }
                {viewModel.emailReceived &&
                    <div>
                        <h2>Update Password</h2>
                        <Neo.Form onSubmit={() => this.viewModel.updateUserPassword()}>
                            <Neo.GridLayout md={1} lg={1}  >
                                <div className="mb-5">
                                    <p>Please enter your new password below:</p>
                                </div>
                                <Neo.FormGroup bind={viewModel.updatePassword.meta.newPassword} input={{ type: "password" }} />
                                <Neo.FormGroup bind={viewModel.updatePassword.meta.confirmPassword} input={{ type: "password" }} />
                                <Neo.Button disabled={viewModel.emailReceived && !viewModel.updatePassword.isValid} isSubmit>Submit</Neo.Button>
                            </Neo.GridLayout>
                        </Neo.Form>
                    </div>}
            </div>
        );
    }
}