import React from 'react';
import { observer } from 'mobx-react';
import investorHubLogo from "../../assets/img/ETFSA_On White.png";
import investorHubLogo2x from "../../assets/img/ETFSA_On White@2x.png";
import investorHubLogo3x from "../../assets/img/ETFSA_On White@3x.png";
import { AppService, Types } from '../../Services/AppService';
import DocumentHandler from '../../Services/DocumentHandler';
import { MaintenanceDocumentType } from '../../Models/Base/Enums/MaintenanceDocumentType';
import { Neo } from '@singularsystems/neo-react';

@observer
export default class Footer extends React.Component {

    private appLayout = AppService.get(Types.Services.AppLayout);
    private taskRunner = AppService.get(Types.Neo.TaskRunner);
    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);

    private downloadMaintenanceDocument(maintenanceDocumentType: MaintenanceDocumentType) {
        this.taskRunner.run("Fetching Document", async () => {
            var response = await this.documentApiClient.fetchPublicMaintenanceDocument(maintenanceDocumentType);
            if (response) {
                DocumentHandler.convertAndDownloadDocument(response, true)
            }
        });
    };    

    public render() {

        return (
            <div id="footer-panel" className="app-footer">
                <div className="app-footer-divider"></div>
                
                <div className="app-footer-content container">
                    <div className="row flex-centered">
                        <div className="col-6 col-md-4 order-2 order-md-1 col-footer-content ">
                            <span>© {new Date().getFullYear()} ETFSA. All rights reserved.</span>
                        </div>
                        <div className="col-12 col-md-4 order-1 order-md-2 text-center ">
                            <img
                                src={investorHubLogo}
                                srcSet={`${investorHubLogo2x}, ${investorHubLogo3x}`}
                                alt="etfSA Investor Hub logo" className="footer-logo" />
                        </div>
                        <div className="col-6 col-md-4 order-3 text-right col-footer-content">
                            <Neo.Button variant="link" className="btn-link-inline maintenance-doc-links" onClick={() => this.downloadMaintenanceDocument(MaintenanceDocumentType.TermsAndConditions)}>T&Cs</Neo.Button>
                            <> | </>
                            <Neo.Button variant="link" className="btn-link-inline maintenance-doc-links" onClick={() => this.downloadMaintenanceDocument(MaintenanceDocumentType.PrivacyPolicy)}>Privacy Policy</Neo.Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}