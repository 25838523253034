import { observable, action } from 'mobx';
import { Utils } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { IETViewBase } from '../Views/ETViewBase';
import { Views } from '@singularsystems/neo-react';

export enum ScreenSize {
    ExtraSmall = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
    ExtraLarge = 5,
    Huge = 6
}

/**
 * Contains logic about the layout of the app. E.g. screensize, theme etc.
 * Use AppLayout.current for the singleton instance.
 */
@injectable()
export default class AppLayout {

    public static get globalTaskRunner() {
        return Views.ViewBase.currentView?.taskRunner;
    }

    @observable.ref
    public currentScreenSize = ScreenSize.Medium;

    @observable.ref
    public isScrollTop = true;

    @observable.ref
    public showMobileMenu = false;

    public get sidebarType() {
        return (Views.ViewBase.currentView as unknown as IETViewBase)?.sidebarType ?? "menu";
    }

    public get showHeader() {
        return !(Views.ViewBase.currentView as unknown as IETViewBase)?.hideHeader;
    }

    constructor() {
        window.addEventListener("resize", this.onWindowResize.bind(this));
        
        document.addEventListener("scroll", () => {
            // This event is fired a lot, which is why we are using peek().
            if ((window.scrollY === 0) !== Utils.peek(this, "isScrollTop")) {
                this.isScrollTop = window.scrollY === 0;
            }
        });
    }

    @action
    private onWindowResize() {
        
        if (window.innerWidth <= 576) {
            this.currentScreenSize = ScreenSize.ExtraSmall;
        } else if (window.innerWidth <= 768) {
            this.currentScreenSize = ScreenSize.Small;
        } else if (window.innerWidth <= 992) {
            this.currentScreenSize = ScreenSize.Medium;
        } else if (window.innerWidth <= 1200) {
            this.currentScreenSize = ScreenSize.Large;
        } else if (window.innerWidth <= 1360) {
            this.currentScreenSize = ScreenSize.ExtraLarge;
        } else {
            this.currentScreenSize = ScreenSize.Huge;
        }

        this.setContentHeight();
        this.setContainerMargin();
    }

    private header?: HTMLDivElement;
    private footer?: HTMLDivElement;
    private contentPanel?: HTMLDivElement;

    public setup() {
        this.header = document.getElementById("header-panel") as HTMLDivElement;
        this.footer = document.getElementById("footer-panel") as HTMLDivElement;
        this.contentPanel = document.getElementById("content-panel") as HTMLDivElement;

        this.onWindowResize();
    }

    private setContentHeight() {
        if (this.contentPanel) {
            let headerHeight = 0;
            if (this.header) {
                headerHeight = this.header.clientHeight + parseInt(window.getComputedStyle(this.header).marginBottom);
            }
            this.contentPanel.style.minHeight = (window.innerHeight - headerHeight - this.footer!.clientHeight - 1) + "px";
        }
    }

    private setContainerMargin() {

        // Due to the fixed left menu, the bootstrap container needs to be shifted to the right, and narrowed when the screen gets too small.
        // Below the medium breakpoint, the menu will be hidden by default, so the behaviour only applies above this size.
        const containers = document.querySelectorAll(".container");
        
        const first = containers[0];
        if (first) {
            containers.forEach(c => (c as HTMLElement).style.width = "100%");
            const menuHidden = window.innerWidth <= 768;

            const windowWidth = window.innerWidth - 17;
            const remainingSpace = windowWidth - first.getBoundingClientRect().width;

            containers.forEach(c => {
                const element = c as HTMLElement;
                element.style.marginLeft = remainingSpace < 140 && !menuHidden ? "70px" : "auto";
                if (remainingSpace <= 70 && !menuHidden) {
                    element.style.width = (windowWidth - 70) + "px";
                }
            });
        }
    }
}