import { EnumHelper, Misc, NumberUtils, Validation } from "@singularsystems/neo-core";
import { Neo, NeoGrid, Routing, Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { TradeRequest } from "../../../Models/Base/TradeRequest";
import { InvestmentType } from "../../../Models/Registration/Enums/InvestmentType";
import { PaymentType } from "../../../Models/Registration/Enums/PaymentType";
import TradeCostDetails from "../Components/TradeCostDetails";
import PurchaseVM from "./PurchaseVM";
import ClickLink from "../../../App/Components/ClickLink";
import TradeConfirmationComponent from "../../Registration/Components/TradeConfirmationComponent";
import BreadCrumb from "../../../App/Components/BreadCrumb";
import { AppService, Types } from "../../../Services/AppService";
import { ScreenSize } from "../../../Services/AppLayout";

class PurchaseParams {
    instrumentCode = {};
}

EnumHelper.decorateEnum(PaymentType, (d) => {
    d.describe(PaymentType.EFT, "EFT");
    d.describe(PaymentType.OnceOffCollection, "Once Off Collection");
});

@observer
export default class PurchaseView extends Views.ViewBase<PurchaseVM, PurchaseParams> {
    static params = new PurchaseParams();

    private layoutService = AppService.get(Types.Services.AppLayout);

    constructor(props: any) {
        super("Buys", PurchaseVM, props);
    }

    async beforeRouteChanged(props: Routing.IRouteChangedProps) {
        if (!this.viewModel.purchaseSubmitted) {
            var errorInfo = this.viewModel.checkAndSaveCart();
            if (errorInfo) {
                props.leavePrompt = "Leaving without fixing the following issues will result in not saving your information: " + errorInfo;
            }
        }
    }

    public getBreadCrumbList() {
        const breadcrumbs = super.getBreadCrumbList();

        breadcrumbs.splice(0, 0, {
            label: 'Product',
            link: '/product'
        });

        return breadcrumbs;
    }

    protected async viewParamsUpdated() {
        await this.viewModel.load(this.viewParams.instrumentCode.asString() === "none" ? "" : this.viewParams.instrumentCode.asString());
    }

    render() {
        const viewModel = this.viewModel;
        const validation = viewModel.tradeRequest.validator.getRuleResult(TradeRequest.validTradeRules);
        const debitOrderDays = viewModel.appDataCache.debitOrderDays.currentData;

        return (
            <div className="transaction-page">
                <BreadCrumb rootItem={{ label: "Home", link: "/" }} />
                <div className="search-btn row">
                    <div className="title col-10">You are investing in:</div>
                    <div className="col-2">
                        <Neo.Button variant="info" className="mr-2" tooltip={"Go to search"} onClick={() => viewModel.navigateToDashboard(false)}>
                            <div className="icon-btn">
                                <span>Search</span>
                                <i className="fa fa-search-plus" />
                            </div>
                        </Neo.Button>
                    </div>
                </div>
                <div className="page-container">
                    <div className="row">
                        <div className="col-md-9 purchase-section">
                            <Neo.Form model={viewModel.tradeRequest} onSubmit={() => viewModel.tradeConfirmation.showHideConfirmationModal()} validationDisplayMode={Validation.DisplayMode.Always}>
                                <div className="row">
                                    <div className="fieldset-content-box ml-3 col-3" key={viewModel.tradeRequest.meta.investmentTypeID.uniqueID}>
                                        <label>{"Investment Type"}</label>
                                        <fieldset>
                                            <Neo.RadioList
                                                key={viewModel.tradeRequest.meta.investmentTypeID.uniqueID}
                                                bind={viewModel.tradeRequest.meta.investmentTypeID}
                                                radioList={{ enumType: InvestmentType }}
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="fieldset-content-box ml-4 col-3">
                                        <label>{viewModel.tradeRequest.investmentTypeID === InvestmentType.OnceOffInvestment ? "Payment Type" : "Debit Order Date"}</label>
                                        {viewModel.tradeRequest.investmentTypeID === InvestmentType.OnceOffInvestment
                                            ?
                                            <fieldset>
                                                <Neo.RadioList
                                                    key={viewModel.tradeRequest.meta.paymentMethodID.uniqueID}
                                                    bind={viewModel.tradeRequest.meta.paymentMethodID}
                                                    radioList={{ enumType: PaymentType }}
                                                />
                                            </fieldset>
                                            :
                                            <fieldset>
                                                <Neo.RadioList
                                                    key={viewModel.tradeRequest.meta.debitOrderDay.uniqueID}
                                                    bind={viewModel.tradeRequest.meta.debitOrderDay}
                                                    radioList={{ items: debitOrderDays, valueMember: "dayOfMonth", displayMember: "description" }}
                                                />
                                            </fieldset>
                                        }
                                    </div>
                                    <div className="fieldset-content-box ml-3 col-5" key={viewModel.tradeRequest.meta.sourceOfFundsID.uniqueID}>
                                        <label>{"Source of Funds"}</label>
                                        <fieldset>
                                            <Neo.FormGroup bind={viewModel.tradeRequest.meta.sourceOfFundsID}
                                                select={{
                                                    items: viewModel.appDataCache.sourceOfFunds.currentData,
                                                    displayMember: "sourceOfFundsName",
                                                    onItemSelected: (i) => viewModel.setSourceOfFundsValues(i!)
                                                }} suppressLabel />
                                            <Neo.FormGroup bind={viewModel.tradeRequest.meta.sourceOfFundsAdditionalInfo} placeholder={viewModel.sourceOfFundPlaceholder} hidden={!viewModel.showFreeTextInput} label="Source of Funds additional info" />
                                        </fieldset>
                                    </div>
                                </div>
                                <hr />
                                <NeoGrid.Grid items={viewModel.tradeRequest.selectedInstruments} addButton={{ text: "Add Instrument", variant: "info", onClick: () => viewModel.tradeRequest.selectedInstruments.addNew() }} className="mb-1">
                                    {(item, meta) => (
                                        
                                            <NeoGrid.Row onBlur={async () => (item.instrumentID !== 0 && item.amount > 0) && viewModel.checkAndSaveCart()}>
                                                <NeoGrid.Column bindContext={item.meta.instrumentID} className="column-width-30" label={"Instrument"} alignment={"left"}>
                                                    <Neo.AutoCompleteDropDown
                                                        items={viewModel.availableInstruments}
                                                        bind={item.meta.instrumentID}
                                                        bindDisplay={item.meta.instrument}
                                                        displayMember="instrumentName"
                                                        onItemSelected={() => viewModel.calculateTradeFees()}
                                                        valueMember="instrumentID"
                                                    />
                                                </NeoGrid.Column>                                              
                                                <NeoGrid.Column
                                                    bind={meta.amount} numProps={{ format: Misc.NumberFormat.CurrencyDecimals, currencySymbol: "R" }}
                                                    width={this.layoutService.currentScreenSize >= ScreenSize.Medium ? "20%" : "30%"}
                                                    label="Amount"
                                                    disabled={item.instrumentID === null}
                                                    onChange={() => viewModel.calculateTradeFees()}
                                                />
                                                <NeoGrid.Column
                                                    display={meta.estimatedUnits}
                                                    width={"15%"}
                                                    label="Est. Units"
                                                    numProps={{ format: Misc.NumberFormat.NoDecimals, zeroText: "-" }}
                                                    hideBelow="md"
                                                />
                                                <NeoGrid.ButtonColumn
                                                    showDelete
                                                    onClick={() => viewModel.deleteCartDetail(item.meta.instrumentID.value)}
                                                    buttonSize="sm"
                                                />
                                            </NeoGrid.Row>                                       
                                    )}
                                </NeoGrid.Grid>
                                <div className="mb-3">
                                    {(viewModel.tradeRequest.investmentTypeID === InvestmentType.RecurringInvestment && viewModel.recurringTradeRequest) &&
                                        <div className="row">
                                            <div className="content-box col-md-11 ml-4 mb-3 mt-2">
                                                <label className="font-medium">You have existing recurring investments, this instruction will be added to that list. Click <ClickLink onClick={() => viewModel.showAccountInformationPanel()}>here</ClickLink> to manage your existing recurring trade requests</label>
                                            </div>
                                        </div>
                                    }
                                    <div hidden={!viewModel.tradeRequest.hasTradeCosts}>
                                        <h4>Total Costs for this Transaction</h4>
                                        <TradeCostDetails tradeFees={viewModel.tradeRequest.tradeFees} />
                                    </div>
                                    <hr />
                                    <div className="row-breakdown" style={{ paddingBottom: "Inherit" }}>
                                        <div className="col-8">Estimated Final Amount:</div>
                                        <div className="col-4 text-right">{NumberUtils.format(viewModel.tradeRequest.estimatedFinalAmount, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                    </div>

                                    {this.layoutService.currentScreenSize < ScreenSize.Medium &&
                                        <div className="content-box">
                                            <label>Total Purchase Amount</label>
                                            <div>{NumberUtils.format(viewModel.tradeRequest.totalPurchaseAmount, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                        </div>}
                                </div>
                                <div className="text-right">
                                    <Neo.Button
                                        variant="primary"
                                        isSubmit
                                        disabled={!viewModel.tradeRequest.isValid}
                                        tooltip={validation?.getDisplayInfo(true).displayText}>
                                        Confirm
                                    </Neo.Button>
                                </div>
                            </Neo.Form>
                        </div>
                        <div className="col-md-3 mb-3 total-amounts">
                            {this.layoutService.currentScreenSize >= ScreenSize.Medium &&
                                <div className="content-box">
                                    <label>Total Purchase Amount</label>
                                    <div>{NumberUtils.format(viewModel.tradeRequest.totalPurchaseAmount, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                </div>}
                        </div>
                    </div>
                </div>
                <TradeConfirmationComponent viewModel={viewModel} tradeConfirmationVM={viewModel.tradeConfirmation} />
            </div >
        );
    }
}
