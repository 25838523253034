import { NeoModel, Validation, ValueObject, Rules } from '@singularsystems/neo-core';
import InvestorAddressDetail from './InvestorAddressDetail';

@NeoModel
export default class UpdateAddressCommand extends ValueObject {

    constructor(
        public investorId: number, 
        public addressDetail: InvestorAddressDetail) {
            super();
            
            this.documentId = addressDetail.documentId;
    }

    @Rules.Required()
    public documentId: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Update Address Command";
    }
}