import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import { RegistrationPersonalDetailsComponentVM } from './Components/RegistrationPersonalDetailsComponent';
import Registration from '../../Models/Registration/Registration';
import { AccountTypeComponentVM } from './Components/AccountTypeComponent';
import Investor from '../../Models/Registration/Investor';
import { NotificationDuration } from '../../Models/Enums/NotificationDuration';
import { RegistrationState } from '../../Models/Registration/Enums/RegistrationState';

@NeoModel
export default class RegisterVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        private investorService = AppService.get(Types.Services.CurrentInvestorService),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);
    }

    public registration = new Registration();
    public investor = new Investor()

    public registrationState: number = 0;

    // components
    public registrationPersonalDetailsComponentVM = new RegistrationPersonalDetailsComponentVM(this);
    public accountTypeComponentVM = new AccountTypeComponentVM(this.taskRunner);

    public async initialise() {
        const investorData = this.investorService.investorData;
        if (investorData) {
            const investorInfo = await this.taskRunner.waitFor(investorData.getInvestorAsync());

            const response = await this.taskRunner.waitFor(this.investorApiClient.getInvestor());
            if (response.data !== null) {
                this.investor.set(response.data);
            } else {
                this.notifications.addDanger("Investor", "Failed to fetch investor", NotificationDuration.Standard);
            }

            this.registrationState = investorInfo.investorStatusID + 1;
        } else {
            this.registrationState = RegistrationState.PersonalDetails;
        }
    }
}