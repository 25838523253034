import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import InvestorProfileVM from './InvestorProfileVM';
import { observer } from 'mobx-react';
import ETViewBase from '../ETViewBase';
import InvestmentSelectionComponent from '../Dashboard/Components/InvestmentSelectionComponent';
import StatusImage from '../../App/Components/StatusImage';
import { InfoPanelView } from '../InformationPanel/AccountInformationVM';
import ClickLink from '../../App/Components/ClickLink';
import { FicaStatus } from '../../Models/Enums/FicaStatus'

@observer
export default class InvestorProfileView extends ETViewBase<InvestorProfileVM> {

    constructor(props: unknown) {
        super("Investor Profile", InvestorProfileVM, props);
    }

    public render() {
        const viewModel = this.viewModel,
            investorService = viewModel.investorService,
            investorData = investorService.investorData!,
            ficaStatuses = investorData.ficaStatuses;

        return (
            <div>
                <InvestmentSelectionComponent hideInvestmentType />

                <div className="mt-4">

                    {ficaStatuses &&
                        <Neo.GridLayout md={2} xl={3} verticalSpace="2rem">

                            {this.getSection(ficaStatuses.identityStatus,
                                <div>
                                    <span>Identification Document</span>
                                    <ClickLink onClick={() => investorService.beginShowAccountInformationPanel(InfoPanelView.Identity)}>VIEW ID</ClickLink>
                                    {(ficaStatuses.identityStatus === FicaStatus.Rejected &&
                                        <small>{ficaStatuses.identityFailedReason}</small>)}
                                </div>)}

                            {this.getSection(ficaStatuses.addressStatus,
                                <div>
                                    <span>Proof of residence</span>
                                    <ClickLink onClick={() => investorService.beginShowAccountInformationPanel(InfoPanelView.Address)}>VIEW PROOF</ClickLink>  
                                    {ficaStatuses.addressStatus === FicaStatus.Rejected &&
                                        <div>
                                            <small>{ficaStatuses.addressFailedReason}</small>
                                            <div className="mt-3">
                                                <Neo.Button isOutline onClick={() => investorData.accountInformationVM.beginEditAddress(this.taskRunner)}>UPLOAD NEW</Neo.Button>
                                            </div>
                                        </div>}
                                </div>)}

                            {this.getSection(ficaStatuses.bankDetailsStatus,
                                <div>
                                    <span>Bank Details</span>
                                    <ClickLink onClick={() => investorService.beginShowAccountInformationPanel(InfoPanelView.BankDetails)}>VIEW DETAILS</ClickLink>
                                    {(ficaStatuses.bankDetailsStatus === null || ficaStatuses.bankDetailsStatus === FicaStatus.Rejected) &&
                                        <div className="mt-2">
                                            <Neo.Button isOutline onClick={() => investorData.accountInformationVM.beginEditBankDetails()}>UPLOAD NEW</Neo.Button>
                                        </div>}
                                </div>)}

                            {this.getSection(ficaStatuses.taxStatus,
                                <div>
                                    <span>Tax Details</span>
                                    <ClickLink onClick={() => investorService.beginShowAccountInformationPanel(InfoPanelView.TaxDetails)}>VIEW DETAILS</ClickLink>
                                    {ficaStatuses.taxStatus === FicaStatus.Rejected &&
                                        <div>
                                            <small>{ficaStatuses.taxFailedReason}</small>
                                            <div className="mt-3">                                         
                                                <Neo.Button isOutline onClick={() => investorData.accountInformationVM.beginEditTaxDetails()}>UPLOAD NEW</Neo.Button>
                                            </div>
                                        </div>}
                                </div>)}

                            {this.getSection(ficaStatuses.thirdPartyBankDetailsStatus,
                                <div>
                                    <span>3rd Party Bank Account</span>
                                    <ClickLink onClick={() => investorService.beginShowAccountInformationPanel(InfoPanelView.AdditionalBankDetails)}>VIEW DETAILS</ClickLink>
                                    {(ficaStatuses.thirdPartyBankDetailsStatus === null) &&
                                        <div className="mt-2">
                                            <ClickLink onClick={() => investorData.accountInformationVM.beginEdit3rdPartyBankDetails()}><i className="fa fa-plus" /> Add 3rd Party Bank Details</ClickLink>
                                        </div>}
                                    {(ficaStatuses.thirdPartyBankDetailsStatus === FicaStatus.Rejected) &&
                                        <div className="mt-2">
                                            <Neo.Button isOutline onClick={() => investorData.accountInformationVM.beginEdit3rdPartyBankDetails()}>UPLOAD NEW</Neo.Button>
                                        </div>}
                                </div>)}

                        </Neo.GridLayout>}
                </div>

            </div>
        );
    }

    private getSection(statusId: number | null, content: JSX.Element) {
        return (
            <div className="content-box">
                <div className="dashboard-status-box">
                    <div className="pr-2">
                        {content}
                    </div>
                    <div>
                        <StatusImage status={statusId} />
                    </div>
                </div>
            </div>
        )
    }
}