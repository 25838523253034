import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { Attributes, ITaskRunner, NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';
// eslint-disable-next-line
import InvestorBankDetail from '../../../Models/Investors/InvestorBankDetail';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import BankAccountDetails, { ThirdPartyBankAccountDetails } from '../../Investor/Components/BankAccountDetails';
import Investor from '../../../Models/Registration/Investor';

export interface IBankDetailsComponentProps {
    taskRunner: ITaskRunner;
    investor: Investor;
    onRegisterStateChange: (state: RegistrationState) => void;
}

@injectable()
@NeoModel
export class BankDetailsComponentVM extends Views.ViewModelBase {

    constructor(
        private investor: Investor,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);

        this.investorBankDetail = investor.primaryBankDetails ?? new InvestorBankDetail();
        this.investorBankDetail.investorID = investor.investorID;
    }

    public investorBankDetail: InvestorBankDetail;

    @Attributes.ChildObject(InvestorBankDetail)
    public investorThirdPartyBankDetail: InvestorBankDetail | null = null;

    public get addThirdPartyDetails() {
        return this.investorThirdPartyBankDetail !== null;
    }
    public set addThirdPartyDetails(value: boolean) {
        if (value) {
            const investorThirdPartyBankDetail = new InvestorBankDetail();
            investorThirdPartyBankDetail.investorID = this.investor.investorID;
            investorThirdPartyBankDetail.isThirdParty = true;
            this.investorThirdPartyBankDetail = investorThirdPartyBankDetail;
        } else {
            this.investorThirdPartyBankDetail = null;
        }
    }
    
    public updateInvestorBankDetails() {
        this.taskRunner.run("Updating Bank Details", async () => {

            const toUpdate = [this.investorBankDetail.toJSObject()];

            if (this.investorThirdPartyBankDetail) {
                toUpdate.push(this.investorThirdPartyBankDetail.toJSObject());
            }

            await this.investorApiClient.updateInvestorBankDetail(toUpdate);
        });
    }
}

@observer
export default class BankDetailsComponent extends React.Component<IBankDetailsComponentProps> {

    private viewModel = new BankDetailsComponentVM(this.props.investor, this.props.taskRunner);

    private async updateBankDetails() {
        await this.viewModel.updateInvestorBankDetails();
        
        NotifyUtils.addSuccess("Success", "You have successfully updated your bank details", NotificationDuration.Standard);
        this.props.onRegisterStateChange(RegistrationState.TaxDetails);
    }

    private checkBankDetailsValid() {
        if (this.viewModel.investorThirdPartyBankDetail) {
            return this.viewModel.investorBankDetail.isValid && this.viewModel.investorThirdPartyBankDetail.isValid;
        } else {
            return this.viewModel.investorBankDetail.isValid;
        }
    }

    public render() {
        const viewModel = this.viewModel;

        return (
            <div>
                <section>
                    <div className="register-bank-details">

                        <h4 className="mb-4">Your bank details</h4>

                        <BankAccountDetails bankAccount={viewModel.investorBankDetail} />

                        <Neo.Checkbox bind={viewModel.meta.addThirdPartyDetails} label={"Would you like to add a 3rd party bank account for debit orders?"} />

                        {viewModel.investorThirdPartyBankDetail &&
                            <div>
                                <h4 className="my-4">Adding a 3rd party bank account for debit orders</h4>

                                <ThirdPartyBankAccountDetails bankAccount={viewModel.investorThirdPartyBankDetail} registration={true}  />
                            </div>}

                        <div className="my-4">
                            <Neo.Button onClick={() => this.updateBankDetails()} disabled={!this.checkBankDetailsValid()} >Next Step</Neo.Button>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}