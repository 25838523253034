import { NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class Strategy extends APIModelBase {

    public strategyID: number = 0;

    public strategyName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.strategyName) {
            return "New strategy";
        } else {
            return this.strategyName;
        }
    }
}