import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import RegistrationActivationVM from './RegistrationActivationVM';
import { observer } from 'mobx-react';
import Login from '../../Views/Security/Login';
import RegisterView from './RegisterView';
import ETViewBase from '../ETViewBase';

class RegistrationActivationParams {
    public token = { required: true, isQuery: true }
}

@observer
export default class RegistrationActivationView extends ETViewBase<RegistrationActivationVM, RegistrationActivationParams> {

    public static params = new RegistrationActivationParams();

    constructor(props: unknown) {
        super("Registration Activation", RegistrationActivationVM, props);

        this.sidebarType = "none";
    }

    protected viewParamsUpdated() {
        this.viewModel.setToken(this.viewParams.token.asString());
    }

    public render() {
        return (
            <div>
                <section>
                    <div className="registration-activation text-center">
                        {this.viewModel.registrationSuccessful &&
                            <div>
                                <h4 className="mb-4">Registration Successful</h4>
                                
                                <p>You can now proceed to login</p>

                                <Neo.Button onClick={() => this.navigation.navigateToView(Login)}>Login Now</Neo.Button>
                            </div>
                        }
                        {this.viewModel.registrationSuccessful === false &&
                            <div>
                                <h4 className="mb-4">Registration Failed</h4>
                                
                                <p>This link has expired. Try <a href={this.navigation.getPathForView(Login)}>logging in</a> or <a href={this.navigation.getPathForView(RegisterView)}>re-registering</a>.</p>
                                    
                            </div>
                        }
                    </div>
                </section>
            </div>
        );
    }
}