import { NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class InstrumentProvider extends ApiModelBase {

    public instrumentProviderID: number = 0;

    public instrumentProviderName: string = "";

    public imageID: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.instrumentProviderName) {
            return "New instrument provider";
        } else {
            return this.instrumentProviderName;
        }
    }
}