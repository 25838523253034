import { Attributes, NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class CartDetailLookup extends APIModelBase {

    public instrumentID: number = 0;

    public instrumentName: string = "";

    @Attributes.Float()
    public amount: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.instrumentName) {
            return "New cart detail lookup";
        } else {
            return this.instrumentName;
        }
    }
}