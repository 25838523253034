import { Attributes, List, NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../ApiModelBase';
import InvestorForeignTax from './InvestorForeignTax';

@NeoModel
export default class InvestorTaxDetail extends APIModelBase {

    public taxNumber: string = "";

    public documentId: number | null = null;

    public investorForeignTaxes = new List(InvestorForeignTax);

    // Client only properties / methods

    @Attributes.NoTracking()
    public isRegisteredForLocalTax: boolean | null = null;

    @Attributes.NoTracking()
    @Attributes.OnChanged<InvestorTaxDetail>(t => t.onForeignTaxChanged)
    public hasForeignTaxDetails: boolean | null = null;

    public removeForeignTaxItem(item: InvestorForeignTax) {
        this.investorForeignTaxes.remove(item);

        if (this.investorForeignTaxes.length === 0) {
            this.hasForeignTaxDetails = false;
        }
    }

    public validTaxNumber() {

        if (this.taxNumber) {
            var pattern = new RegExp('^[0-3][0-9]{9}$');
            return pattern.test(this.taxNumber);
        } else {
            return true;
        }
    }

    public beforeSave() {
        if (!this.isRegisteredForLocalTax) {
            this.taxNumber = "";
        }
    }

    private onForeignTaxChanged() {
        if (this.hasForeignTaxDetails) {
            if (this.investorForeignTaxes.length === 0) {
                this.investorForeignTaxes.addNew();
            }
        } else {
            for (let i = this.investorForeignTaxes.length - 1; i >= 0; i--) {
                this.investorForeignTaxes.remove(this.investorForeignTaxes[i]);
            }
        }
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(it => it.isRegisteredForLocalTax === null, "Please select whether you are registered for local tax.");
        rules.failWhen(it => it.hasForeignTaxDetails === null, "Please select whether you are a foreign tax resident.");
        rules.failWhen(it => it.taxNumber !== "" && it.validTaxNumber() === false, "Invalid tax number");
        rules.failWhen(it => it.isRegisteredForLocalTax === true && it.taxNumber.trim() === "", "Tax number is required");
        rules.failWhen(it => it.isRegisteredForLocalTax === true && !it.documentId, "SARS issued document is required.");
    }

    public toString(): string {
        if (this.isNew || !this.taxNumber) {
            return "New investor tax detail";
        } else {
            return this.taxNumber;
        }
    }

    onDeserialiseCompleted() {
        this.isRegisteredForLocalTax = this.taxNumber !== "0000000000";
        this.hasForeignTaxDetails = this.investorForeignTaxes.length > 0;
    }
}