import { NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import Home from '../Dashboard/Dashboard';
import { NotificationDuration } from '../../Models/Enums/NotificationDuration';
import ForgotPassword from '../../Models/Security/ForgotPassword';
import UpdatePassword from '../../Models/Security/UpdatePassword';

@NeoModel
export default class ForgotPasswordVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);
    }

    public async initialise() {
    }

    public forgotPassword = new ForgotPassword();

    public updatePassword = new UpdatePassword();

    public emailReceived: boolean = false;

    public async resetPassword() {
        this.taskRunner.run("Resetting Password", async (opt) => {
            var responseForgotPassword = await this.securityApiClient.forgotPassword(this.forgotPassword.toJSObject());
            if (responseForgotPassword.data) {
                NotifyUtils.addSuccess("Password reset", "If the username entered corresponds to a user, an email containing a verification link has been sent to your email address. Please click on this link within 24 hours in order to update your password.", NotificationDuration.Standard);
                this.navigation.navigateToView(Home);
            }
        })
    }

    public setToken(token: string) {
        this.updatePassword.queryString = token;
        this.emailReceived = true;
    }

    public async updateUserPassword() {
        this.taskRunner.run("Updating password", async (opt) => {
            var response = await this.securityApiClient.updatePassword(this.updatePassword.toJSObject());
            if (response.data.success) {
                NotifyUtils.addSuccess("Password updated", "Your password has been updated.", NotificationDuration.Standard);
                this.navigation.navigateToView(Home);
             }
        });
    }
}