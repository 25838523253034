import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { InfoPanelContext } from './InfoPanel';

interface InfoPanelColumnProps {
    name: string;
    backToText?: string;
    renderBackLink?: boolean;
}

@observer
export default class InfoPanelColumn extends React.Component<InfoPanelColumnProps> {
    static contextType = InfoPanelContext;
    context!: React.ContextType<typeof InfoPanelContext>;

    private divRef = React.createRef<HTMLDivElement>();
    @observable
    private wasHidden = true;

    componentDidMount() {
        this.afterRender();
    }

    componentDidUpdate() {
        this.afterRender();
    }

    private afterRender() {
        if (this.divRef.current) {
            if (this.wasHidden) {
                setTimeout(() => {
                    // On first render, the column will be offset.
                    // This causes the component to re-render, losing the offset class, and animate back to center.
                    this.wasHidden = false;
                }, 100);  
            }
        } else {
            this.wasHidden = true;
        }
    }

    public render(): React.ReactNode {
        const controller = this.context.controller!;
        controller!.registerPanel({ name: this.props.name, backToText: this.props.backToText})

        return (
            controller.canShow(this.props.name) &&
                <div ref={this.divRef} className={"info-panel-column " + controller.getClass(this.props.name, this.wasHidden)}>
                    {this.props.renderBackLink && 
                        <a className="back" href="/" onClick={e => {controller.back(); e.preventDefault();}}><i className="fa fa-angle-left"/> Back to {controller.lastHistory.backToText}</a>}
                    {this.props.children}
                </div>
        )
    }
}