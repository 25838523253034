import { Attributes, FileUtils, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { InfoPanelController } from '../../App/Components/InfoPanel';
import AppInvestorData from '../../Models/Investors/AppInvestorData';
import StatementCriteria from '../../Models/Portfolio/StatementCriteria';
import { AppService, Types } from '../../Services/AppService';
import InvestorDocument from '../../Models/Investors/InvestorDocument';

@NeoModel
export default class DocumentPanelVM extends Views.ViewModelBase {

    constructor(
        public readonly appInvestorData: AppInvestorData,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private statementApiClient = AppService.get(Types.ApiClients.StatementApiClient),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public investorDocumentApiClient = AppService.get(Types.ApiClients.InvestorDocumentApiClient)) {
        super(taskRunner);
    }

    public panelController = new InfoPanelController();

    public statementCriteria = new StatementCriteria();

    public downloadTask = AppService.get(Types.Neo.TaskRunner);

    @Attributes.Display("Investment Type")
    public selectedPortfolioType: number = 0;

    public taxCertificates = new List(InvestorDocument);

    get taxCertificateListByPortfolio() {
        return this.taxCertificates.filter(c => c.portfolioTypeID === this.selectedPortfolioType);
    }

    public async viewTaxCertificates() {
        var result = await this.downloadTask.waitFor(this.investorDocumentApiClient.getTaxCertificates());

        this.taxCertificates.set(result.data);
        this.panelController.forward("TaxCertificate");
    }

    public beginViewStatement() {
        if (this.appInvestorData.investor?.portfolioList.length === 1) {
            this.statementCriteria.portfolioTypeId = this.appInvestorData.investor.portfolioList[0].portfolioTypeID;
        }
        this.panelController.forward("Statement");
    }

    public async downloadStatement() {
        const response = await this.downloadTask.waitFor(this.statementApiClient.getStatementDocument(this.statementCriteria.toQueryObject()));

        FileUtils.showSaveFile(response.data, response.headers['x_filename']);
    }

    public async downloadTaxCertificate(documentID: number) {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getInvestorDocumentById(documentID));

        FileUtils.showSaveFile(response);
    }
}