import { Data, List, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import Province from '../Models/Catalogue/Province';

export interface IProvinceApiClient {

    /**
     * Method fetch the provinces.
     */
     getProvinceList(): AxiosPromise<List<Model.PlainObject<Province>>>;
}

@injectable()
export default class ProvinceApiClient extends Data.ApiClientBase implements IProvinceApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/province`);
    }

    public getProvinceList(): AxiosPromise<List<Model.PlainObject<Province>>> {
        return this.axios.get(`${this.apiPath}`)
    }

}