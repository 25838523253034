import { Attributes, Validation } from '@singularsystems/neo-core';
import APIModelBase from './APIModelBase';

export default abstract class PurchaseTradeRequestBase extends APIModelBase {

    public portfolioTypeID: number = 0;

    @Attributes.Nullable()
    public sourceOfFundsID: number | null = null;

    public sourceOfFundsAdditionalInfo: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}