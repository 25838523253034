import { DateUtils, EnumHelper, FileUtils, NeoModel } from '@singularsystems/neo-core';
import { IGraphData } from '../../Models/Portfolio/GraphData';
import StatementCriteria from "../../Models/Portfolio/StatementCriteria";
import { AppService, Types } from '../../Services/AppService';
import ETInvestorVMBase from '../ETInvestorVMBase';
import SwitchVM from "../Investor/Trades/SwitchVM";
import WithdrawCashVM from '../Investor/Trades/WithdrawCashVM';
import Dashboard from './Dashboard';
import GraphDataCriteria, { TimeRange } from '../../Models/Portfolio/GraphDataCriteria';
import TradeCost from '../../Models/Base/TradeCost';

export enum DashboardViewType {
    Withdrawal = "withdrawal",
    Switch = "switch",
    Distributions = "distributions"
}

EnumHelper.decorateEnum(DashboardViewType, d => {
    d.describe(DashboardViewType.Withdrawal, "Withdrawal");
    d.describe(DashboardViewType.Switch, "Switch");
    d.describe(DashboardViewType.Distributions, "Distribution");
});

@NeoModel
export default class DashboardVM extends ETInvestorVMBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public reportingApiClient = AppService.get(Types.ApiClients.ReportingApiClient),
        public investorService = AppService.get(Types.Services.CurrentInvestorService),
        public catalogueApiClient = AppService.get(Types.ApiClients.CatalogueApiClient)
    ) {
        super(taskRunner);
        this.registerReaction(() => investorService.investorData!.selectedPortfolio?.portfolioID, () => this.setCanTransactAvailableCash());
    }

    public viewType: DashboardViewType | null = null;
    public instrumentGraphData: IGraphData | null = null;
    public instrumentGraphCriteria = new GraphDataCriteria();
    public withdrawCashVM: WithdrawCashVM | null = null;
    public switchVM: SwitchVM | null = null;
    public statementCriteria: StatementCriteria | null = null;
    public canWithdrawCash: boolean = false;
    public canSwitchCash: boolean = false;
    public readonly shareCashZAR = "SHARECASHZAR";
    public readonly disabledToolTip = "Minimum requirements not met";
    
    public instrumentGraphTask = AppService.get(Types.Neo.TaskRunner);
    public tradeCosts: TradeCost | null = new TradeCost();

    public async initialise() {
        this.tradeCosts = await this.catalogueApiClient.getTradeCosts();
        await this.setCanTransactAvailableCash();
    }

    public showInstrumentGraphData(instrumentCode: string, isModelPortfolio: boolean = false) {
        let portfolioData = this.investorService.portfolioData;

        if (portfolioData) {
            if (isModelPortfolio) {
                instrumentCode = portfolioData.getInstrumentName(instrumentCode) ?? '';
            }

            if (instrumentCode) {
                this.instrumentGraphData = portfolioData!.getGraphData(instrumentCode);
                this.instrumentGraphCriteria.seriesId = instrumentCode;
                this.instrumentGraphCriteria.timeRange = portfolioData.graphCriteria.timeRange;
            }
        }

        if (!this.instrumentGraphData) {
            this.notifications.showMessage("Graph", "Graph data not available for this instrument.");
        }
    }

    public async setLineGraphTimeRange(timeRange: TimeRange) {
        if (timeRange !== this.instrumentGraphCriteria.timeRange) {
            this.instrumentGraphCriteria.timeRange = timeRange;
            this.instrumentGraphData = await this.investorService.portfolioData!.getGraphDataForInstrument(this.instrumentGraphTask, this.instrumentGraphCriteria)
        }   
    }

    public async beginWithdraw(instrumentCode: string) {
        this.withdrawCashVM = this.registerViewModel(WithdrawCashVM);
        if (this.portfolioBalance !== null) {
            var currentInstrument = this.portfolioBalance?.instruments.find(c => c.instrumentCode === instrumentCode) ?? null;
            this.withdrawCashVM!.setPortfolioInstrumentBalance(currentInstrument);
            await this.withdrawCashVM.initialise();
        }
        else {
            this.navigation.navigateToView(Dashboard);
        }
    }

    public async beginSwitch(instrumentCode: string) {
        this.switchVM = this.registerViewModel(SwitchVM);
        if (this.portfolioBalance !== null) {
            var currentInstrument = this.portfolioBalance.instruments.find(c => c.instrumentCode === instrumentCode) ?? null;
            await this.switchVM.load(currentInstrument);
        }
        else {
            this.navigation.navigateToView(Dashboard);
        }
    }

    public setDistributionExportCriteria() {
        this.statementCriteria = new StatementCriteria();
        var today = DateUtils.today();
        this.statementCriteria.endDate = DateUtils.monthEnd(today);
        this.statementCriteria.startDate = DateUtils.monthStart(today);
        this.statementCriteria!.portfolioTypeId = this.investorService.portfolioData!.portfolio.portfolioTypeID;
    }

    public downloadDistributionReport() {
        if (this.statementCriteria) {
            this.taskRunner.run("Fetching distributions", async () => {
                const response = await this.reportingApiClient.getDistributionReport(this.statementCriteria!.toQueryObject());
                FileUtils.showSaveFile(response);
                this.statementCriteria = null;
            });
        }
    }

    public beginViewStatement(portfolioTypeID?: number) {
        var today = DateUtils.today();

        this.investorService.toggleDocumentPanel();
        this.statementCriteria = new StatementCriteria();

        if (portfolioTypeID) {
            this.statementCriteria.portfolioTypeId = portfolioTypeID;
            this.statementCriteria.endDate = DateUtils.monthEnd(today);
            this.statementCriteria.startDate = DateUtils.monthStart(today);
            this.investorService.investorData?.documentsVM.statementCriteria.mapFrom(this.statementCriteria);
            this.investorService.investorData?.documentsVM.panelController.forward("Statement");
        }
    }

    public async setCanTransactAvailableCash(){
        let valuePending = 0;
        let hasPendingShareCashTransaction = false;
        let portfolioBalance = await this.investorService.getPortfolioAsync();

        if (portfolioBalance) {
            valuePending = portfolioBalance.instruments
                .filter(c => c.isCashInstrument)
                .reduce((sum, currentValue) => sum + currentValue.valuePending, 0);
        }
        hasPendingShareCashTransaction = valuePending !== 0;

        if (this.investorInfo?.ficaVerified && !hasPendingShareCashTransaction) {
            const cashAvailable = portfolioBalance?.cashAvailable ?? 0;
            const minTradeValue = this.tradeCosts?.instrumentMinimumTradeValue ?? 0;
        
            this.canSwitchCash = cashAvailable > minTradeValue;
            this.canWithdrawCash = cashAvailable > 0;
        } else {
            this.canSwitchCash = false;
            this.canWithdrawCash = false;
        }
    }
}