import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';
// eslint-disable-next-line
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import { UpdatableAddressDetails } from '../../Investor/Components/AddressDetails';
import UpdateAddressCommand from '../../../Models/Investors/UpdateAddressCommand';
import Investor from '../../../Models/Registration/Investor';

export interface IProofOfResidenceComponentProps {
    taskRunner: ITaskRunner;
    investor: Investor;
    onRegisterStateChange: (state: RegistrationState) => void;
}

@injectable()
@NeoModel
export class ProofOfResidenceComponentVM extends Views.ViewModelBase {

    constructor(
        public investor: Investor,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        public authenticationService = AppService.get(Types.Services.AppAuthenticationService)) {

        super(taskRunner);

        this.command = new UpdateAddressCommand(investor.investorID!, investor.addressDetails);
    }

    public command: UpdateAddressCommand | null = null;

    updateInvestorAddress() {
        this.taskRunner.run("Updating address details", async () => {
            await this.investorApiClient.updateInvestorAddressDetail(this.command!.toJSObject());            
            this.notifications.addSuccess("Address Details", "Your address details have been successfully updated", NotificationDuration.Standard);
        });
    }
}

@observer
export default class ProofOfResidenceComponent extends React.Component<IProofOfResidenceComponentProps> {

    private viewModel = new ProofOfResidenceComponentVM(this.props.investor, this.props.taskRunner);

    componentDidMount() {
        this.viewModel.initialise();
    }

    componentWillUnmount() {
        this.viewModel.dispose();
    }

    async updateInvestorAddress() {
        await this.viewModel.updateInvestorAddress();
        this.props.onRegisterStateChange(RegistrationState.BankDetails);
    }

    public render() {

        const viewModel = this.viewModel;
        return (
            <div className="register-card">
                <h4 className="mb-4">Your proof of residence</h4>
                {viewModel.command &&
                    <div>
                        <UpdatableAddressDetails command={viewModel.command} />

                        <div className="my-4">
                            <Neo.Button onClick={() => this.updateInvestorAddress()} disabled={!viewModel.command.isValid}>Next Step</Neo.Button>
                        </div>
                    </div>}
            </div>
        );
    }
}
