import React from 'react';
import { observer } from 'mobx-react';
import { IValueByClass } from '../../../Models/Portfolio/ValueByClass';
import { chartColors } from './ChartColors';
import { observable } from 'mobx';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

interface IPieChartProps {
    graphData: IValueByClass[];
    animate: boolean;
}

@observer
export default class PieChart extends React.Component<IPieChartProps> {

    @observable
    private hasMounted = false;

    public render() {
        return (
            <div>
                {this.hasMounted && 
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.getPieChartOptions(this.props.graphData)} />}
            </div>
        );
    }

    componentDidMount() {
        this.hasMounted = true;
    }

    private getPieChartOptions(data: IValueByClass[]) {
        const symbol = "R";

        return {
            chart: { type: "pie", backgroundColor: "#efefef" },
            plotOptions: { pie: { innerSize: "70%", borderWidth: 0 }},
            title: { text: undefined },
            series: [{
                animation: this.props.animate,
                tooltip: { valueDecimals: 0, pointFormat: "<b>" + symbol + "{point.y}</b>" },
                type: "pie",
                name: "Value",
                data: data.map(c => [c.assetClass, c.value]),
                colors: chartColors,
                showInLegend: true,
                dataLabels: [{ enabled: true, format: symbol + "{y:,.0f}", style: { fontSize: "10px", textOutline: false } },]
            }],
            credits: { enabled: false }
        } as Highcharts.Options;
    }
}