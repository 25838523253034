import { Data, List, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import Country from '../Models/Catalogue/Country';

export interface ICountryApiClient {

    /**
     * Method fetch the countries.
     */
     getCountryList(): AxiosPromise<List<Model.PlainObject<Country>>>;

}

@injectable()
export default class CountryApiClient extends Data.ApiClientBase implements ICountryApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/country`);
    }

    public getCountryList(): AxiosPromise<List<Model.PlainObject<Country>>> {
        return this.axios.get(`${this.apiPath}`)
    }

}