import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Popover from './Popover';

interface IPopoverButtonProps {
    menuItems: IPopoverMenuItem[];
    dropDownClassName?: string;
}

export interface IPopoverMenuItem {
    text: string;
    subText?: string;
    onClick: () => void;
    isDivider?: boolean;
}

@observer
export default class PopoverButton extends React.Component<IPopoverButtonProps> {
    
    private anchorRef = React.createRef<HTMLDivElement>();

    @observable
    private show = false;

    public render() {

        return (
            <>
                <div ref={this.anchorRef} className="popover-drop-anchor" onClick={() => this.show = !this.show}>
                    <i className="fa fa-angle-down"></i>
                </div>
                {this.show && this.anchorRef.current && 
                    <Popover anchorElement={this.anchorRef.current} alignment="center" onHide={() => this.show = false} yOffset={10} className={this.props.dropDownClassName}>
                        {this.props.menuItems.map(menuItem => (
                            <>
                                {menuItem.isDivider && <div className="popover-item-divider" />}
                                <div className="popover-item" onClick={menuItem.onClick}>
                                    {menuItem.text}
                                    {menuItem.subText && <small>{menuItem.subText}</small>}
                                </div>
                            </>
                        ))}
                    </Popover>}
            </>
        )
    }
}