import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { PlotAreaOptions } from 'highcharts';
import { observer } from 'mobx-react';
import { IGraphData } from '../../../Models/Portfolio/GraphData';
import { chartColors } from './ChartColors';
import { observable } from 'mobx';

interface ILineChartProps {
    graphData: IGraphData;
}

@observer
export default class LineChart extends React.Component<ILineChartProps> {

    @observable
    private hasMounted = false;

    public render() {
        const { graphData } = this.props;

        return (
            <>
                {graphData && this.hasMounted && <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getChartOptions(graphData)} />}
            </>
        );
    }

    componentDidMount() {
        // Without this, the graph doesn't take up the full width of it's container.
        this.hasMounted = true;
    }

    private getChartOptions(graphData: IGraphData) {

        const symbol = "R";
        const seriesData = graphData.seriesData;
        const chartType = seriesData.length === 1 ? "area" : "line";
        const tooltipHeader = seriesData.length === 1 ? "{point.x:%d %b %Y}<br>" : "{point.series.name}<br>{point.x:%d %b %Y}<br>";

        const chartOptions = {
            chart: { type: chartType, backgroundColor: "transparent" },
            title: { text: undefined },
            xAxis: {
                type: "datetime",
                labels: { format: "{value:%d %b %y}" },
            },
            yAxis: {
                labels: { format: "R{value:,.0f}" },
                title: { text: undefined },
            },
            series: seriesData.map((s, index) => ({
                animation: true,
                marker: { enabled: false },
                tooltip: { valueDecimals: 0, headerFormat: tooltipHeader, pointFormat: "<b>" + symbol + "{point.y}</b>" },
                name: s.seriesName,
                type: chartType,
                data: s.values.map(c => [c.valueDate!.getTime(), c.value]),
                dataLabels: [{ enabled: false }],
                lineWidth: 3,
                color: chartColors[index % chartColors.length]
            })),
            legend: { enabled: seriesData.length > 1 },
            credits: { enabled: false }
        } as Highcharts.Options;

        if (chartOptions.series!.length === 1) {
            const series = chartOptions.series![0] as PlotAreaOptions;
            series.color = "#369afe";

            series.fillColor = {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, 'rgba(54, 154, 254, 0.2)'],
                    [0.5, 'rgba(54, 154, 254, 0.05)'],
                    [1, 'rgba(54, 154, 254, 0)']
                ]
            };
        }

        return chartOptions;
    }
}