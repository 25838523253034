import React from 'react';
import { FicaStatus } from '../../Models/Enums/FicaStatus';
import verified from '../../assets/img/verified.png';
import verified2x from '../../assets/img/verified@2x.png';
import declined from '../../assets/img/declined.png';
import declined2x from '../../assets/img/declined@2x.png';
import pending from '../../assets/img/pending.png';
import pending2x from '../../assets/img/pending@2x.png';

interface IStatusImageProps extends React.HTMLProps<HTMLDivElement> {
    status: FicaStatus | null;
}

export default class StatusImage extends React.Component<IStatusImageProps> {

    public render() {
        const { status, ...rest } = this.props;

        const isPending = status !== FicaStatus.Approved && status !== FicaStatus.Rejected;

        return status && 
            <div {...rest}>
                <div className="status-image">
                    {status === FicaStatus.Approved && <img src={verified} srcSet={verified2x} alt="Verified" />}
                    {status === FicaStatus.Rejected && <img src={declined} srcSet={declined2x} alt="Declined" />}
                    {isPending && <img src={pending} srcSet={pending2x} alt="Pending" />}
                </div>
            </div>
    }
}