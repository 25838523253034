import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import React from 'react';
import { ModalUtils } from '@singularsystems/neo-core';

@injectable()
export default class OldSiteIntegration {

    constructor(
        private config = AppService.get(Types.Config),
        private authService = AppService.get(Types.Services.AppAuthenticationService),
        private investorService = AppService.get(Types.Services.CurrentInvestorService)) {

    }

    public redirect(path: string, instrumentId: number | null = null): void {

        let portfolioTypeId = this.investorService.investorData?.selectedPortfolio?.portfolioTypeID ?? 0;
        let url = '';

        if (instrumentId) {
            url = `${this.config.oldSitePath}/#/landing-redirect?to=${encodeURIComponent(path)}&portfolioTypeId=${portfolioTypeId}&instrumentId=${instrumentId}`;
        } else {
            url = `${this.config.oldSitePath}/#/landing-redirect?to=${encodeURIComponent(path)}&portfolioTypeId=${portfolioTypeId}`;
        }

        if (this.config.isDevelopment) {

            // In development, the origin of the old site is different (because the port no is different).
            // This means the old site will not be able to access the sessionStorage of this site, so we need to pass the user info as a query parameter.
            url += `&auth=${encodeURIComponent(this.authService.getUserJson())}`;
            
            let modal = 
                ModalUtils.showModal("Url", 
                    <div style={{ overflowWrap: "anywhere" }}>
                        {url}
                    </div>, { 
                    acceptButton: { text: "Copy", onClick: () => {
                        navigator.clipboard.writeText(url);
                        modal.close();
                    }}
                });

        } else {
            window.location.href = url;
        }
    }
}