import { NeoModel } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class InvestorOTPDetails extends ApiModelBase {

    public emailAddress: string = "";

    public cellNo: string = "";

    public otpMethod: number = 0;
}