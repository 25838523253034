import React from "react";
import { Neo } from "@singularsystems/neo-react";
import ForgotUsernameVM from "./ForgotUsernameVM";
import { observer } from "mobx-react";
import ETViewBase from "../ETViewBase";

class ForgotUsernameParams {
  public token = {};
}

@observer
export default class ForgotUsernameView extends ETViewBase<ForgotUsernameVM,ForgotUsernameParams> {

  public static params = new ForgotUsernameParams();

  constructor(props: unknown) {
    super("Forgot Username", ForgotUsernameVM, props);

    this.hideHeader = true;
    this.sidebarType = "main-logo";
  }

  public render() {
    
    const viewModel = this.viewModel;

    return (
      <div className="reset-password public-form-container">
        <h2>Forgot Username</h2>
        <Neo.Form onSubmit={() => this.viewModel.SendUsername()}>
          <Neo.GridLayout md={1} lg={1}  >
              <div className="mb-5">
                  <p>Please enter your ID number below:</p>
              </div>
              <Neo.FormGroup bind={viewModel.forgotUsername.meta.idNo} />
              <Neo.Button disabled={!viewModel.isValid} isSubmit>Submit</Neo.Button>

          </Neo.GridLayout>
      </Neo.Form>
      </div>
    );
  }
}
