import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { NeoModel } from '@singularsystems/neo-core';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import { AppService, Types } from '../../../Services/AppService';
import Registration from '../../../Models/Registration/Registration';
import { injectable } from 'inversify';
import OTPComponent, { OTPComponentVM } from './OTPComponent';
import RegisterVM from '../RegisterVM';

export interface IRegistrationPersonalDetailsComponentProps {
    viewModel: RegistrationPersonalDetailsComponentVM;
    registerVM: RegisterVM;
}

@injectable()
@NeoModel
export class RegistrationPersonalDetailsComponentVM extends Views.ViewModelBase {

    constructor(
        registerVM: RegisterVM,
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(registerVM.taskRunner);

        this.registration = registerVM.registration;
        this.otpComponentVM = new OTPComponentVM(this.taskRunner, this.registration);

        this.register = this.register.bind(this);
    }

    public registration: Registration;

    public otpComponentVM: OTPComponentVM;

    public showOtpPanel = false;

    public register() {
        this.taskRunner.run(async () => {

            var response = await this.securityApiClient.register(this.registration.toJSObject());
            if (response.data) {
                if (response.data.success) {
                    if (response.data.additionalData) {
                        this.registration.registrationId = parseInt(response.data.additionalData);
                        this.showOtpPanel = true;
                    }
                } else {
                    this.notifications.addDanger("Sign Up Error", "validation error", NotificationDuration.Standard);
                }
            }
        }).catch((ex: string) => {
            this.notifications.addDanger("Sign Up Error", "An error has occurred", NotificationDuration.Standard);
        });
    }
}

@observer
export default class RegistrationPersonalDetailsComponent extends React.Component<IRegistrationPersonalDetailsComponentProps> {

    public render() {

        const viewModel = this.props.viewModel;

        return (
            <div>
                <section>
                    <div className="register-personal-details">
                        <Neo.Form model={viewModel.registration} allReadOnly={viewModel.showOtpPanel}>

                            <h4>Personal Information</h4>
                            <p> <span className="required-indicator">* </span>indicates a required field</p>
                            <Neo.GridLayout lg={2}  >
                                <Neo.FormGroup bind={viewModel.registration.meta.firstName} label={<><span className="required-indicator">* </span>Name</>} />
                                <Neo.FormGroup bind={viewModel.registration.meta.surname} label={<><span className="required-indicator">* </span>Surname</>} />
                            </Neo.GridLayout>

                            <h4 className="mt-3">Contact Information</h4>
                            <Neo.GridLayout lg={2}>
                                <Neo.FormGroup bind={viewModel.registration.meta.emailAddress} label={<><span className="required-indicator">* </span>E-mail address</>} />
                                <Neo.FormGroup bind={viewModel.registration.meta.cellNo} label={<><span className="required-indicator">* </span>Cellphone</>} />
                            </Neo.GridLayout>

                            <h4 className="mt-3">Security</h4>
                            <Neo.GridLayout lg={2}>
                                <Neo.FormGroup bind={viewModel.registration.meta.password} input={{ type: "password" }} label={<><span className="required-indicator">* </span>Password</>} />
                                <Neo.FormGroup bind={viewModel.registration.meta.renterPassword} input={{ type: "password" }} label={<><span className="required-indicator">* </span>Re-enter password</>} />
                            </Neo.GridLayout>

                            <h4 className="mt-3"><span className="required-indicator">* </span>Where would you like to receive your OTP?</h4>
                            <Neo.GridLayout lg={2} className="mb-3">
                                <Neo.Input bind={viewModel.registration.meta.cellphoneOtp} input={{ type: "checkbox" }} label={"Cellphone"} />
                                <Neo.Input bind={viewModel.registration.meta.emailOtp} input={{ type: "checkbox" }} label={"E-mail"} />
                            </Neo.GridLayout>

                            <div className="my-4">
                                <Neo.Button onClick={viewModel.register} disabled={!viewModel.registration.isValid || viewModel.showOtpPanel}>NEXT STEP</Neo.Button>
                            </div>
                        </Neo.Form>
                    </div>

                </section>

                {viewModel.showOtpPanel && <OTPComponent taskRunner={viewModel.taskRunner} registration={viewModel.registration} />}
            </div>
        );
    }
}
