import { NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class InvestorForeignTax extends APIModelBase {

    public investorForeignTaxID: number = 0;

    @Rules.Required()
    public taxNumber: string = "";

    @Rules.Required()
    public countryID: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

    }

    public toString(): string {
        if (this.isNew || !this.taxNumber) {
            return "New investor foreign tax";
        } else {
            return this.taxNumber;
        }
    }

}