import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import APIResponse from '../Models/APIResponse';
import Investor from '../Models/Registration/Investor';
import InvestorAddressDetail from '../Models/Investors/InvestorAddressDetail';
import InvestorBankDetail from '../Models/Investors/InvestorBankDetail';
import InvestorTaxDetail from '../Models/Investors/InvestorTaxDetail';
import UpdateIdentityCommand from '../Models/Investors/UpdateIdentityCommand';
import UpdateAddressCommand from '../Models/Investors/UpdateAddressCommand';
import { IDCheckResult } from '../Models/Enums/IDCheckResult';
import InvestorOTPDetails from "../Models/Investors/InvestorOTPDetails";

export interface IInvestorApiClient {

    /**
     * This methods returns the current logged in Investor.
     */
    getInvestor(): AxiosPromise<Model.PlainTrackedObject<Investor>>;

    /**
     * Method used to Change the Investor's Cell Number.
     * An OTP will be generated and split, the first half is sent to the new number and the other half to the Email Address.
     * Notifications will be sent to The old number and Email.
     */
    changeCellNo(CellNo: string): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /** 
     * Method used to Change the Investor's Default OTP Communication Preference.
     * An OTP will be generated and sent to the investor's Current Cell Number.
     */
    beginChangeOTPMethod(communicationPreferenceTypeID: number): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /** 
     * Changes the otp preference once the opt has been entered by the user.
     * @param otp One time pin.
     */
    endChangeOTPMethod(otp: string): AxiosPromise<string>;

    /**
     * This method is used to verify the Investor's new Cell Number.
     * The OTP provided will be the concatenated OTP from the SMS and the Email.
     */
    verifyCellNo(OTP: string): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    checkIDNumber(investorId: number, idNo: string): AxiosPromise<IDCheckResult>;

    /**
     * Updates the Investor Status to "Ready to Invest", provided the user has been FICA verified.
     */
    putReadyToInvest(): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * Updates investor details and validates rules based on current investor status.
     * Password is required if the investor
     */
    putInvestor(InvestorModel: Model.PartialPlainObject<Investor>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * This methods returns the current logged in investor's address details.
     */
    getInvestorAddressDetails(): AxiosPromise<Model.PlainTrackedObject<InvestorAddressDetail>>;

    /**
     * Method used to update the investor's address information.
     */
    updateInvestorAddressDetail(InvestorAddressDetail: Model.PlainObject<UpdateAddressCommand>): AxiosPromise<void>;

    /**
     * Updates investor address details only.
     */
    updateInvestorBankDetail(InvestorBankDetailModel: Model.PartialPlainObject<InvestorBankDetail>[]): AxiosPromise<void>;

    /**
     * Method used to update the investor's tax detail information.
     */
    updateInvestorTaxDetail(taxDetail: Model.PlainObject<InvestorTaxDetail>): AxiosPromise<void>;

    /**
     * Updates the current investors id number and links the identity documents to the investor.
     */
    updateInvestorIdentity(command: Model.PartialPlainObject<UpdateIdentityCommand>): AxiosPromise<void>;

    /**
     * This methods returns the current logged in Investor's details needed for the OTP.
     */
     getInvestorOTPDetails(): AxiosPromise<Model.PlainTrackedObject<InvestorOTPDetails>>;
}

@injectable()
export default class InvestorApiClient extends Data.ApiClientBase implements IInvestorApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/investor`);
    }

    public getInvestor(): AxiosPromise<Model.PlainTrackedObject<Investor>> {
        return this.axios.get(`${this.apiPath}/GetInvestor`);
    }

    public changeCellNo(CellNo: string): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/ChangeCellNo`, CellNo);
    }

    public beginChangeOTPMethod(communicationPreferenceTypeID: number): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/begin-change-otp-method/${communicationPreferenceTypeID}`);
    }

    public endChangeOTPMethod(otp: string): AxiosPromise<string> {
        return this.axios.post(`${this.apiPath}/end-change-otp-method`, otp, { headers: { 'Content-Type': 'application/json' }});
    }

    public verifyCellNo(OTP: string): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/VerifyCellNo`, OTP);
    }

    public checkIDNumber(investorId: number, idNo: string): AxiosPromise<IDCheckResult> {
        return this.axios.post(`${this.apiPath}/verify-idno?investorId=${investorId}&idNo=${idNo}`);
    }

    public putReadyToInvest(): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/PutReadyToInvest`);
    }

    public putInvestor(InvestorModel: Model.PartialPlainObject<Investor>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.put(`${this.apiPath}/PutInvestor`, InvestorModel);
    }

    public getInvestorAddressDetails(): AxiosPromise<Model.PlainTrackedObject<InvestorAddressDetail>> {
        return this.axios.get(`${this.apiPath}/get-investor-address-details`);
    }

    public updateInvestorAddressDetail(InvestorAddressDetail: Model.PlainObject<UpdateAddressCommand>): AxiosPromise<void> {
        return this.axios.put(`${this.apiPath}/address-details`, InvestorAddressDetail)
    }

    public updateInvestorBankDetail(bankDetails: Model.PartialPlainObject<InvestorBankDetail>[]): AxiosPromise<void> {
        return this.axios.put(`${this.apiPath}/bank-details`, bankDetails);
    }

    public updateInvestorTaxDetail(taxDetail: Model.PlainObject<InvestorTaxDetail>): AxiosPromise<void> {
        return this.axios.put(`${this.apiPath}/tax-details`, taxDetail)
    }

    public updateInvestorIdentity(command: Model.PartialPlainObject<UpdateIdentityCommand>): AxiosPromise<void> {
        return this.axios.post(`${this.apiPath}/investor-identity`, command);
    }

    public getInvestorOTPDetails(): AxiosPromise<Model.PlainTrackedObject<InvestorOTPDetails>> {
        return this.axios.get(`${this.apiPath}/GetInvestorOTPDetails`);
    }
}