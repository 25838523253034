import { AxiosResponse } from 'axios';

export default class DocumentHandler {

    // takes in an array buffer and a download indicator which specifies if it will download or open a new window with the document.
    // converts to array buffer into a Uint8ArrayConstructor which is then used to create a blob which can be used to create an objectURL 
    // so that the file can be downloaded or opened in a new window
    public static convertAndDownloadDocument(response: AxiosResponse<ArrayBuffer>, download: boolean) {
        const arr = new Uint8Array(response.data);
        const blob = new Blob([arr], { type: response.headers["content-type"] });
        let downloadUrl = URL.createObjectURL(blob),
            filename = response.headers.x_filename;
        let a = document.createElement('a');

        if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
        } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.append(a);
            if (download) {
                a.click();
            } else {
                window.open(a.href);
            }
        }
    }

}
