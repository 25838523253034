import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { ITaskRunner, NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';
// eslint-disable-next-line
import InvestorTaxDetail from '../../../Models/Investors/InvestorTaxDetail';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import TaxDetails from '../../Investor/Components/TaxDetails';
import Investor from '../../../Models/Registration/Investor';

export interface ITaxDetailsComponentProps {
    taskRunner: ITaskRunner;
    investor: Investor;
    onRegisterStateChange: (state: RegistrationState) => void;
}

@injectable()
@NeoModel
export class TaxDetailsComponentVM extends Views.ViewModelBase {

    constructor(
        investor: Investor,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);

        // Force investor to make selections.
        if (!investor.investorTaxDetail.taxNumber) {
            investor.investorTaxDetail.isRegisteredForLocalTax = null;
        }
        if (investor.investorTaxDetail.investorForeignTaxes.length === 0) {
            investor.investorTaxDetail.hasForeignTaxDetails = null;
        }

        this.investorTaxDetail = investor.investorTaxDetail;
    }

    public investorTaxDetail: InvestorTaxDetail;

    public async updateTaxDetails() {
        this.taskRunner.run("Updating investor tax details", async () => {

            this.investorTaxDetail.beforeSave();
            await this.investorApiClient.updateInvestorTaxDetail(this.investorTaxDetail.toJSObject());
        });
    }
}

@observer
export default class TaxDetailsComponent extends React.Component<ITaxDetailsComponentProps> {

    private viewModel = new TaxDetailsComponentVM(this.props.investor, this.props.taskRunner);

    private async updateTaxDetails() {
        await this.viewModel.updateTaxDetails();

        NotifyUtils.addSuccess("Tax Details", "You have successfully updated your tax details", NotificationDuration.Standard)
        this.props.onRegisterStateChange(RegistrationState.Mandate);
    }

    public render() {
        const viewModel = this.viewModel;

        return (
            <div>
                <section>
                    <div className="tax-details">
                        <h4 className="mb-4">Your tax details</h4>
                        
                        <TaxDetails taxDetail={viewModel.investorTaxDetail} />

                        <div className="my-4">
                            <Neo.Button className="NextStep" onClick={() => this.updateTaxDetails()} disabled={!viewModel.investorTaxDetail.isValid}>NEXT STEP</Neo.Button>
                        </div>

                    </div>
                </section >
            </div >
        );
    }
}