import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { EnumHelper, ITaskRunner, NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';
import { IDType } from '../../../Models/Registration/Enums/IDType';
import UpdateIdentityCommand from '../../../Models/Investors/UpdateIdentityCommand';
import IdentityDetails from '../../Investor/Components/IdentityDetails';
import Investor from '../../../Models/Registration/Investor';

interface IIdentificationComponentProps {
    taskRunner: ITaskRunner;
    investor: Investor;
    onRegisterStateChange: (state: RegistrationState) => void;
}

@injectable()
@NeoModel
export class IdentificationComponentVM extends Views.ViewModelBase {

    constructor(
        investor: Investor,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);

        this.command = new UpdateIdentityCommand(investor.investorID);
        this.command.idNo = investor.idNo;
    }

    
    public command: UpdateIdentityCommand;

    public async saveIdentity() {
        if (this.command.isValid) {
            await this.taskRunner.run(() => this.investorApiClient.updateInvestorIdentity(this.command.toJSObject()));
        }
    }
}

@observer
export default class IdentificationComponent extends React.Component<IIdentificationComponentProps> {

    private viewModel = new IdentificationComponentVM(this.props.investor, this.props.taskRunner);

    private async saveIdentity() {
        await this.viewModel.saveIdentity();

        const msg = "You have successfully uploaded your " + EnumHelper.getItemMetadata(IDType, this.viewModel.command.idType!).display;
        NotifyUtils.addSuccess("Success", msg, NotificationDuration.Standard);
        this.props.onRegisterStateChange(RegistrationState.PoliticalSensitivity);
    }

    public render() {

        const viewModel = this.viewModel;

        return (
            <div>
                <h4>Upload your ID</h4>

                <IdentityDetails command={this.viewModel.command} />
                
                <div className="my-4">
                    <Neo.Button 
                        onClick={() => this.saveIdentity()} 
                        disabled={!viewModel.command.isValid}>Next Step</Neo.Button>
                </div>
            </div>
        );
    }
}

