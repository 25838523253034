import { Neo } from '@singularsystems/neo-react';
import React from 'react';
import StatusImage from '../../../App/Components/StatusImage';
import InvestorTaxDetail from '../../../Models/Investors/InvestorTaxDetail';
import TaxDetails from '../../Investor/Components/TaxDetails';
import AccountInformationVM from '../AccountInformationVM';

interface IAccountIdentityInfoProps {
    viewModel: AccountInformationVM;
}

export default class TaxDetailsInfo extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel, 
            investor = viewModel.investor!;

        return (
            <div>
                <div className="info-panel-status-header">
                    <h4>Tax Details</h4>
                    <StatusImage status={investor.investorFICAStatusTaxNumber} />
                </div>
                
                <div className="mt-3">
                    <TaxDetails taxDetail={investor.investorTaxDetail} readOnly />
                </div>

                <Neo.Button className="mt-3" onClick={() => viewModel.beginEditTaxDetails()}>Change</Neo.Button>

            </div>
        )
    }
}

export class TaxDetailsEditModal extends React.Component<IAccountIdentityInfoProps> {

    public render() {
        const viewModel = this.props.viewModel;
        
        return (
            <Neo.Modal title="Tax Details" bindModel={viewModel.meta.editTaxDetails} 
                size="lg"
                closeButton={false}
                acceptButton={{ text: "Save Details", onClick: () => viewModel.saveTaxDetails()}}>

                {(taxDetails: InvestorTaxDetail) => (
                    <div>
                        <TaxDetails taxDetail={taxDetails} />
                    </div>
                )}
            </Neo.Modal>
        )
    }
}