import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import mobileIcon from '../assets/img/mobile.svg';
import wwwIcon from '../assets/img/worldwide-icon.svg';
import whatsAppIcon from '../assets/img/whatsapp-icon.svg';
import mailIcon from '../assets/img/mail-icon.svg';

@observer
export default class ContactUsView extends Views.ViewBase {

    constructor(props: unknown) {
        super("Contact Us", Views.EmptyViewModel, props);
    }

    public render() {
        return (
            <div className="contact-us-view">
			    <h2>Contact Us</h2>

                <div className="page-container mt-4">
                    <Neo.GridLayout lg={2}>
                        <div className="contact-details-container">
                            <table className="contact-details">
                                <tr>
                                    <td>{this.renderContactMethod("Telephone", "010 446 7892", mobileIcon)}</td>
                                    <td>{this.renderContactMethod("WhatsApp Messages", "063 984 6347", whatsAppIcon)}</td>
                                </tr>
                                <tr>
                                    <td>{this.renderContactMethod("E-mail", <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za</a>, mailIcon)}</td>
                                    <td>{this.renderContactMethod("Web", <a href="https://www.etfsa.co.za">www.etfsa.co.za</a>, wwwIcon)}</td>
                                </tr>
                            </table>
                        </div>
                        <div>
                            <iframe 
                                title="map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.072593689313!2d28.055811316126594!3d-26.161765168605463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950c51c077b161%3A0xcc099b5fd40d300!2s51%20West%20St%2C%20Houghton%20Estate%2C%20Johannesburg%2C%202198!5e0!3m2!1sen!2sza!4v1643704251297!5m2!1sen!2sza" 
                                width="100%" 
                                height="600"
                                style={{ border: "none" }}
                                loading="lazy"></iframe>
                        </div>
                    </Neo.GridLayout>
                </div>
				
            </div>
        );
    }

    private renderContactMethod(description: string, detail: any, image: string) {
        return <div className="contact-method">
            <img src={image} alt={description} />
            <div className="contact-method-name">{description}</div>
            <div className="contact-method-value">{detail}</div>
        </div>
    }
}