import React from 'react';
import { observer } from 'mobx-react';
import { Utils } from '@singularsystems/neo-core';

interface IInfoIconProps extends React.HTMLProps<HTMLSpanElement> {
    toolTip?: string;
}

@observer
export default class InfoIcon extends React.Component<IInfoIconProps> {

    public render() {
        const { toolTip, ...props} = this.props;

        props.className = Utils.joinWithSpaces(props.className, "info-icon");

        return (
            <span {...props} data-tip={this.props.toolTip}><i className="fa fa-fw fa-info" /></span>
        );
    }
}