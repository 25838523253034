import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import ETViewBase from '../ETViewBase';
import LoginVM from './LoginVM';
import { observer } from 'mobx-react';
import ForgotPasswordView from './ForgotPasswordView';
import ForgotUsernameView from './ForgotUsernameView';

@observer
export default class Login extends ETViewBase<LoginVM> {

    constructor(props: unknown) {
        super("Login", LoginVM, props);

        this.hideHeader = true;
        this.sidebarType = "main-logo";

        this.forgotPassword = this.forgotPassword.bind(this);
        this.forgotUsername = this.forgotUsername.bind(this);
    }

    private forgotPassword(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault()
        this.navigation.navigateToView(ForgotPasswordView);
    }

    private forgotUsername(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault()
        this.navigation.navigateToView(ForgotUsernameView);
    }

    public render() {
        const loginDetails = this.viewModel.loginDetails;

        return (
            <div className="login-page">
                <section>
                    <div>
                        <div className="account-sign-up">
                            <span>Don't have an account?</span>
                            <Neo.Button className="ml-3" isOutline onClick={() => this.viewModel.register()}>Sign Up</Neo.Button>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="login-details-entry public-form-container">
                        <Neo.Form model={loginDetails} onSubmit={() => this.viewModel.signIn()}>
                            <div className="mb-5">
                                <h2>Sign in to ETFSA</h2>
                                <p className="larger-text">Enter your details below</p>
                            </div>

                            <div className="login-password-container">
                                <Neo.FormGroup bind={loginDetails.meta.userName} />
                                <a href="/" tabIndex={-1} onClick={this.forgotUsername}>Forgot Username?</a>
                            </div>

                            <div className="login-password-container">
                                <Neo.FormGroup bind={loginDetails.meta.password} input={{ type: "password" }} />
                                <a href="/" tabIndex={-1} onClick={this.forgotPassword}>Forgot Password?</a>
                            </div>

                            <div className="text-right">
                                <Neo.Button isSubmit>SIGN IN</Neo.Button>
                            </div>
                        </Neo.Form>
                    </div>

                </section>
            </div>
        );
    }
}