import { observer } from 'mobx-react';
import React from 'react';
import PopoverButton from '../../../App/Components/PopoverButton';
import { AppService, Types } from "../../../Services/AppService";
import InvestorLinkedPortfolioLookup from '../../../Models/Investors/InvestorLinkedPortfolioLookup';
import AppLayout from '../../../Services/AppLayout';

interface IInvestmentSelectionComponentProps {
    hideInvestmentType?: boolean;
}

@observer
export default class InvestmentSelectionComponent extends React.Component<IInvestmentSelectionComponentProps> {

    private investorService = AppService.get(Types.Services.CurrentInvestorService);

    private onPortfolioClick(portfolio: InvestorLinkedPortfolioLookup) {
        this.investorService.investorData!.selectedPortfolio = portfolio;
        AppLayout.globalTaskRunner!.waitFor(this.investorService.getPortfolioAsync());
    }

    public render() {
        const investorData = this.investorService.investorData,
            investorInfo = investorData?.investor;

        return investorInfo && (
            <div className="investor-selection-component">
                <div className="investor-type-heading">
                    <div className="investor-type">
                        <h4>{investorInfo.investorType} Account</h4>
                        <PopoverButton menuItems={[{ text: "No other linked accounts found.", onClick: () => {} }]} />
                    </div>
                    <div className="sub-text">
                        {investorInfo.fullName}
                    </div>
                </div>
                {!this.props.hideInvestmentType && <div className="investment-type-heading">
                    <div>
                        <h4>Investment Type</h4>
                        <PopoverButton menuItems={ investorInfo.portfolioList.map(p => ({ text: p.portfolioType, onClick: () => this.onPortfolioClick(p) }))} />
                    </div>
                    <div className="sub-text">
                        {investorData!.selectedPortfolio?.portfolioType ?? ""}
                    </div>
                </div>}
            </div>
        )
    }
}