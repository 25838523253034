import { Attributes, ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import { MaintenanceDocumentType } from '../Base/Enums/MaintenanceDocumentType';

@NeoModel
export class Portfolio extends ModelBase {

    public guid: string = Utils.emptyGuid();

    public portfolioID: number = 0;

    public portfolioTypeID: number = 0;

    @Attributes.Date()
    public termsAcceptedDate: Date | null = null;

    public reinvestDividends: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.guid) {
            return "New portfolio";
        } else {
            return this.guid;
        }
    }
}

export class PostPortfolio {

    public portfolioTypeID: number = 0;

    // Client only properties / methods
}

@NeoModel
export class PortfolioSign extends ModelBase {

    public portfolioTypeID: number = 0;

    // Client only properties / methods

    @Attributes.NoTracking()
    public acceptTerms: boolean = false;

    @Attributes.NoTracking()
    public maintenanceDocumentType: MaintenanceDocumentType = MaintenanceDocumentType.TermsAndConditions;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => !c.acceptTerms, "You must accept the investment mandate.")
    }

    public toString(): string {
        return "Open investment account";
    }
}

@NeoModel
export class PortfolioReinvestDividends extends ModelBase {

    public portfolioTypeID: number = 0;

    public reinvestDividends: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New portfolio reinvest dividends";
        } else {
            return "Portfolio Reinvest Dividends";
        }
    }
}