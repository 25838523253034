import { EnumHelper } from '@singularsystems/neo-core';

export enum IDType {
    IDBook = 1,
    IDCard = 2,
    Passport = 3
}

EnumHelper.decorateEnum(IDType, decorator => {
    decorator.describe(IDType.IDBook, "ID Book / Birth Certificate");
    decorator.describe(IDType.IDCard, "ID Card");
});