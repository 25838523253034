import { NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../ApiModelBase';

@NeoModel
export default class UpdatePassword extends APIModelBase {

    public queryString: string = "";

    @Rules.Required()
    public newPassword: string = "";

    // Client only properties / methods

    @Rules.Required()
    public confirmPassword: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(up => up.confirmPassword.length !== 0 && up.confirmPassword !== up.newPassword, "Confirm password does not match")
    }

    public toString(): string {
        if (this.isNew || !this.queryString) {
            return "New update password";
        } else {
            return this.queryString;
        }
    }
}