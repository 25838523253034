import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import ClickLink from '../../../App/Components/ClickLink';
import InvestorTaxDetail from '../../../Models/Investors/InvestorTaxDetail';
import { YesNoList } from '../../../Models/Enums/YesNo';
import { AppService, Types } from '../../../Services/AppService';
import { FileUtils } from '@singularsystems/neo-core';

interface ITaxDetailsProps {
    taxDetail: InvestorTaxDetail;
    readOnly?: boolean;
}

@observer
export default class TaxDetails extends React.Component<ITaxDetailsProps> {

    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);
    private appDataCache = AppService.get(Types.Services.AppDataCache);
    private downloadTask = AppService.get(Types.Neo.TaskRunner);

    private async downloadDocument(documentID: number) {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getInvestorDocumentById(documentID));
        FileUtils.showSaveFile(response);
    }

    public render() {
        const { taxDetail, readOnly } = this.props;

        return (
            <div>
                <Neo.FormContext allReadOnly={readOnly}>
                <p><span hidden={readOnly} className="required-indicator">* </span>indicates a required field</p>
                    <Neo.FormGroup bind={taxDetail.meta.isRegisteredForLocalTax} label={<><span className="required-indicator">* </span>Are you registered for tax</>} radioList={{ items: YesNoList }} />
                    {taxDetail.isRegisteredForLocalTax &&
                        <div>
                            <Neo.FormGroup bind={taxDetail.meta.taxNumber} label={<><span hidden={readOnly} className="required-indicator">* </span>Tax Number</>} />

                            {!readOnly &&
                                <div className="upload-section mt-3">
                                    <label><span className="required-indicator">* </span>SARS issued document</label>
                                    <Neo.FileContext
                                        uploadEndpoint={this.documentApiClient.getInvestorDocumentUploadUrl()}
                                        afterUpload={docId => taxDetail.documentId = docId}
                                        allowedExtensions={this.documentApiClient.allowedInvestorDocumentExtensions}
                                        taskRunnerLoadingText={"Uploading Tax Document"}>
                                        <Neo.FileDropArea className={!taxDetail.documentId ? "neo-file-drop" : "neo-file-drop-uploaded"}>
                                            <Neo.FileUploadButton className={!taxDetail.documentId ? "upload-icon" : "upload-completed-icon"}></Neo.FileUploadButton>
                                            {taxDetail.documentId &&
                                                <p className="upload-text">Upload complete</p>
                                            }
                                            <p className="upload-text">Drag your file here or browse to {!taxDetail.documentId ? "upload" : "update"}.</p>
                                            <p className="file-type-text">Jpeg or pdf</p>
                                            {taxDetail.documentId &&
                                                <p className="file-download-text">
                                                    <ClickLink onClick={() => this.downloadDocument(taxDetail.documentId!)}>Download <i className="fa fa-download" /></ClickLink>
                                                </p>
                                            }
                                            <Neo.FileUploadProgress variant={"dark"} type={"animated"} reversed />
                                        </Neo.FileDropArea>
                                    </Neo.FileContext>
                                </div>}
                        </div>}

                    <hr className="my-4" />

                    <Neo.FormGroup bind={taxDetail.meta.hasForeignTaxDetails} label={<><span className="required-indicator">* </span>Are you a resident for tax purposes anywhere other than South Africa?</>} radioList={{ items: YesNoList }} />

                    {taxDetail.hasForeignTaxDetails &&
                        <div className="additional-tax-countries mt-3">

                            {taxDetail.investorForeignTaxes.map(taxInfo => (
                                <div key={taxInfo.entityIdentifier}>
                                    <Neo.FormGroup bind={taxInfo.meta.countryID} select={{ itemSource: this.appDataCache.countries }} label={<><span hidden={readOnly} className="required-indicator">* </span>Country</>} />
                                    <Neo.FormGroup bind={taxInfo.meta.taxNumber} label={<><span hidden={readOnly} className="required-indicator">* </span>Tax Number</>} />
                                    {taxDetail.investorForeignTaxes.length > 1 && !readOnly &&
                                        <div className="mb-3">
                                            <ClickLink onClick={e => taxDetail.removeForeignTaxItem(taxInfo)}>
                                                REMOVE
                                            </ClickLink>
                                        </div>}
                                </div>
                            ))}

                            {!readOnly &&
                                <div className="my-3 text-right">
                                    <ClickLink onClick={e => taxDetail.investorForeignTaxes.addNew()}>
                                        ADD ANOTHER COUNTRY
                                    </ClickLink>
                                </div>}
                        </div>}
                </Neo.FormContext>
            </div>
        )
    }
}