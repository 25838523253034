import { Neo } from '@singularsystems/neo-react';
import React from 'react';
import OtpInput from "react-otp-input";
import { NotificationDuration } from "../../Models/Enums/NotificationDuration";
import { OtpType } from "../../Models/Registration/Enums/OtpType";
import { AppService, Types } from "../../Services/AppService";
import { OtpModel } from "../../Services/OtpService";

interface IOTPModalProps {
     
}

export default class OTPModal extends React.Component<IOTPModalProps> {

    private otpService = AppService.get(Types.Services.OtpService);
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications);

    handleChange = (otp: string) => {
        this.otpService.currentModel!.otp = otp;
    };

    public render() {
                       
        return (
            <Neo.Modal 
                title="One Time Pin (OTP)"
                bindModel={this.otpService.meta.currentModel}
                onClose={() => this.otpService._onOtpCancelled()}
                closeButton={false}
                showCancelButton={true}
                >
                    {(model: OtpModel) => this.renderModalBody(model)}
            </Neo.Modal>
        )
    }

    private renderModalBody(model: OtpModel) {
        const investor = this.otpService.currentInvestor;
        const communicationType = this.getCommunicationMethod(investor!.otpMethod, investor!.emailAddress, investor!.cellNo)

        return (
            <div className="otp-container text-center">
                <div className="mb-2">
                    <strong>An OTP has been sent to your {communicationType === OtpType.Email ? `email address (${investor!.emailAddress}).` : `cellphone (${investor!.cellNo}).`}</strong>
                </div>
                <div className="center-otp-input">
                    <div className="otpInput">
                        <OtpInput
                            value={model.otp}
                            className="form-control"
                            onChange={this.handleChange}
                            numInputs={4}
                            separator={<span></span>}
                            inputStyle={"otp"}
                            isInputNum={true}
                        />
                    </div>
                </div>
                
                <div className="mt-3">
                    <Neo.Button variant="primary"  onClick={() => {this.otpService._onOtpEntered()}}>
                        SUBMIT
                    </Neo.Button>
                </div>
                <div className="mt-2">
                    <Neo.Link className="link-smaller pb-2" onClick={() => {this.resendOtp();}}>DID NOT RECEIVE ONE?</Neo.Link>
                </div>
            </div>
        )
    }

    public resendOtp() {
        this.otpService.sendOtp();
        const investor = this.otpService.currentInvestor;
        if (investor) {
            if (investor!.otpMethod === OtpType.Email) {
                this.notifications.addPrimary("New OTP Sent", `A new OTP has been sent to your email address (${investor.emailAddress})`, NotificationDuration.Long)
            } else {
                this.notifications.addPrimary("New OTP Sent", `A new OTP has been sent to your cellphone (${investor.cellNo})`, NotificationDuration.Long)
            }
        }
    }

    private getCommunicationMethod(otpMethod: number, email: string, cellNo: string) {
        if (otpMethod === OtpType.CellNo || otpMethod === OtpType.Email) {
            return otpMethod;
        } else if (email !== "") {
            otpMethod = OtpType.Email;
        } else {
            otpMethod = OtpType.CellNo;
        }
        return otpMethod;
    }
}