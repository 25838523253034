import { NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class AssetClass extends ApiModelBase {

    public assetClassID: number = 0;

    public assetClassName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.assetClassName) {
            return "New asset class";
        } else {
            return this.assetClassName;
        }
    }
}