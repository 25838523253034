import * as React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import InvestmentSelectionComponent from './Components/InvestmentSelectionComponent';
import DashboardVM, { DashboardViewType } from './DashboardVM';
import openBox from '../../assets/img/opened-white-box.svg';
import etpIcon from '../../assets/img/et-ps-icon.svg';
import calendarIcon from '../../assets/img/calendar-icon.svg';
import cashIcon from '../../assets/img/cash-icon.svg';
import valueIcon from '../../assets/img/total-value-icon.svg';
import caret from "../../assets/img/caret.svg";
import { EnumHelper, Misc, NumberUtils } from '@singularsystems/neo-core';
import AppPortfolioData from '../../Models/Portfolio/AppPortfolioData';
import { PortfolioBalance } from '../../Models/Portfolio/PortfolioBalance';
import ETLoader from '../../App/Components/ETLoader';
import ClickLink from '../../App/Components/ClickLink';
import BreadCrumb from '../../App/Components/BreadCrumb';
import ETPGrid from './Components/ETPGrid';
import InfoIcon from '../../App/Components/InfoIcon';
import PieChart from './Components/PieChart';
import StringConstants from '../../Services/StringConstants';
import WithdrawCash from '../Investor/Trades/WithdrawCash';
import Switch from "../Investor/Trades/Switch";
import DistributionGrid from './Components/DistributionGrid';
import ProductView from '../Investor/Trades/Product';
import PurchaseView from '../Investor/Trades/Purchase';
import { PortfolioType } from '../../Models/Portfolio/PortfolioType';
import DashboardLineChart from './Components/DashboardLineChart';

class DashboardParams {
    viewType = {};
    instrumentCode = {};
}

@observer
export default class Dashboard extends Views.ViewBase<DashboardVM, DashboardParams> {
    static params = new DashboardParams();

    constructor(props: unknown) {
        super("", DashboardVM, props);
    }

    viewParamsUpdated() {
        this.viewModel.viewType = this.viewParams.viewType.value as DashboardViewType;
        this.viewParams.viewType.description = this.viewModel.viewType ? EnumHelper.getItemMetadata(DashboardViewType, this.viewModel.viewType).display : "";

        if (this.viewParams.viewType.value === DashboardViewType.Withdrawal && this.viewParams.instrumentCode !== null) {
            this.viewModel.beginWithdraw(this.viewParams.instrumentCode.asString());
        } else if (this.viewParams.viewType.value === DashboardViewType.Switch && this.viewParams.instrumentCode !== null) {
            this.viewModel.beginSwitch(this.viewParams.instrumentCode.asString());
        } else if (this.viewModel.withdrawCashVM !== null) {
            this.viewModel.withdrawCashVM = null;
        }
    }

    public render() {
        const viewModel = this.viewModel,
            portfolioData = viewModel.investorService.portfolioData,
            portfolioBalance = viewModel.portfolioBalance,
            hasInvestments = !portfolioBalance || portfolioBalance.hasInvestments,
            isFicad = viewModel.investorInfo?.ficaVerified;

        return (
            <div>
                <div className="dashboard-header">
                    <InvestmentSelectionComponent />

                    {portfolioBalance &&
                        (
                            <div className="dashboard-top-right">
                                <InfoIcon className="mr-2" toolTip={"Internal Rate of Return (IRR).\nPlease see FAQs for more details."} />
                                <span className={"mr-2 fa " + (portfolioBalance.irr < 0 ? "fa-arrow-down text-negative" : "fa-arrow-up text-positive")} />
                                <span className="irr-value mr-3">
                                    {NumberUtils.format(Math.abs(portfolioBalance.irr / 100), Misc.NumberFormat.PercentDecimals)}
                                </span>
                                <Neo.Button disabled={!isFicad} tooltip={isFicad ? "" : StringConstants.notFicadTooltip} variant="info" onClick={() => this.navigation.navigateToView(ProductView)}>Invest</Neo.Button>
                            </div>
                        )
                    }
                </div>

                {portfolioData &&
                    <div>
                        {!hasInvestments &&
                            <div className="dashboard-invest-now">
                                <div>
                                    <img src={openBox} alt="Invest Now" />
                                </div>

                                <Neo.Button disabled={!isFicad} tooltip={isFicad ? "" : StringConstants.notFicadTooltip} className="mt-3" onClick={() => this.navigation.navigateToView(ProductView)}>Invest Now</Neo.Button>
                            </div>}

                        {hasInvestments &&
                            <>
                                {!viewModel.viewType && this.renderPortfolioSummary(portfolioData, portfolioBalance)}
                                {viewModel.viewType &&
                                    <>
                                        <div className="mt-3 mb-5">
                                            <BreadCrumb rootItem={{ label: "Home", link: "/" }} />
                                        </div>

                                        {viewModel.viewType === DashboardViewType.Withdrawal && viewModel.withdrawCashVM !== null &&
                                            <WithdrawCash viewModel={viewModel.withdrawCashVM} />
                                        }

                                        {viewModel.viewType === DashboardViewType.Switch && viewModel.switchVM !== null &&
                                            <Switch viewModel={viewModel.switchVM} />
                                        }

                                        {viewModel.viewType === DashboardViewType.Distributions &&
                                            <DistributionGrid viewModel={viewModel} />
                                        }
                                    </>}
                            </>}
                    </div>}
            </div>
        );
    }

    private getTotalContributionSummary(portfolioTypeID: number, netContributions: number | undefined, taxYearContributions: number | undefined) {

        return (
            <div className="content-box">
                <div className="header">
                    <img src={calendarIcon} alt={'Total contributions to date'} />
                </div>
                <div className="value-wrapper">
                    {[
                        { header: "Current Tax Year Contributions", contributions: taxYearContributions, showFor: [PortfolioType.TaxFree] },
                        { header: "Total Contributions to Date", contributions: netContributions, showFor: [PortfolioType.Discretionary, PortfolioType.TaxFree] }
                    ].map(c => this.renderValue(c.header, c.contributions, !c.showFor.includes(portfolioTypeID)))}
                </div>
            </div>
        );
    }


    private GetCashCardLinks() {

        return [
            <ClickLink
                className='text-left'
                softDisable={!this.viewModel.canSwitchCash}
                tooltip={this.viewModel.canSwitchCash ? "" : this.viewModel.disabledToolTip }
                onClick={() => this.viewParams.setValues({ viewType: DashboardViewType.Switch, instrumentCode: this.viewModel.shareCashZAR })}>{"Reinvest"}
            </ClickLink>,
            <ClickLink
                className='text-left'
                softDisable={!this.viewModel.canWithdrawCash}
                tooltip={this.viewModel.canWithdrawCash ? "" : this.viewModel.disabledToolTip }
                onClick={() => this.viewParams.setValues({ viewType: DashboardViewType.Withdrawal, instrumentCode: this.viewModel.shareCashZAR })}>{"Withdraw"}
            </ClickLink>,
        ];
    }

    private renderPortfolioSummary(data: AppPortfolioData, balance: PortfolioBalance | null) {

        const allocationByClass = data.assetAllocationByClass;
        const portfolioData = this.viewModel.investorService.portfolioData;

        return (
            <div className="mt-4">
                <div className="dashboard-value-summary mb-4">
                    <Neo.GridLayout md={2} xl={4} withGaps>
                        {this.getValueSummary("ETPs", etpIcon, balance?.availableBalance, <ClickLink onClick={() => this.viewModel.beginViewStatement(portfolioData?.portfolio.portfolioTypeID)}>Download Statement</ClickLink>)}
                        {this.getTotalContributionSummary(balance?.portfolioTypeID!, balance?.netContributions, balance?.currentTaxYearContributions)}
                        {this.getValueSummary("Cash", cashIcon, (balance?.cashAvailable ?? 0) + (balance?.cashReserved ?? 0), this.GetCashCardLinks())}
                        {this.getValueSummary("Total value of investments", valueIcon, balance?.totalBalance,
                            <ClickLink className='text-right' onClick={() => this.viewParams.viewType.value = DashboardViewType.Distributions}>See Dividend and Fee Transactions</ClickLink>)
                        }
                    </Neo.GridLayout>
                </div>

                {balance &&
                    <div>
                        {portfolioData &&
                            <>
                                <ETPGrid viewModel={this.viewModel} balance={balance}
                                    onWithdraw={instrumentCode => this.viewParams.setValues({ viewType: DashboardViewType.Withdrawal, instrumentCode: instrumentCode })}
                                    onSwitch={instrumentCode => this.viewParams.setValues({ viewType: DashboardViewType.Switch, instrumentCode: instrumentCode })}
                                    onPurchase={instrumentCode => this.navigation.navigateToView(PurchaseView, { instrumentCode: instrumentCode })} />
                                <div className="row mt-5">
                                    <div className="col-md-6 col-xl-9">
                                        <DashboardLineChart data={portfolioData} />
                                    </div>
                                    <div className="col-md-6 col-xl-3">
                                        {allocationByClass &&
                                            <div className="content-box grey">
                                                <h4>Asset Allocation</h4>
                                                <PieChart graphData={allocationByClass} animate />
                                            </div>}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        );
    }

    private getValueSummary(header: string, icon: string, value?: number, link?: JSX.Element | Array<JSX.Element>) {
        return (
            <div className="content-box">
                <div className="header">
                    <img src={icon} alt={header} />
                </div>
                <div className="value-wrapper">
                    {this.renderValue(header, value)}
                </div>
                <div className="links-wrapper">
                    {link &&
                        (Array.isArray(link)
                            ? link.map(c => <div className="link">{c}<img className="link-icon" src={caret} alt="link icon" /></div>)
                            : <div className="link">{link}<img className="link-icon" src={caret} alt="link icon" /></div>
                        )
                    }
                </div>
            </div>
        );
    }

    private renderValue(header: string, value: number | undefined, IsVisible: boolean = false) {
        return (
            !IsVisible && (
                <div className="value">
                    <small>{header}</small>
                    {value === undefined && <ETLoader />}
                    {value !== undefined &&
                        <div className='amount'>
                            {value === 0 ? "-" : NumberUtils.format(value, Misc.NumberFormat.CurrencyDecimals)}
                        </div>
                    }
                </div>
            )
        );
    }
}