import React from 'react';
import { InfoPanel, InfoPanelColumn } from '../../App/Components/InfoPanel';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import AccountInformationVM, { InfoPanelView } from './AccountInformationVM';
import AccountIdentityInfo, { AccountIdentityEditModal } from './Components/AccountIdentityInfo';
import AccountAddressInfo, { AccountAddressEditModal } from './Components/AccountAddressInfo';
import { BankDetailsInfo, ThirdPartyBankDetailsInfo, BankDetailsEditModal, ThirdPartyBankDetailsEditModal } from './Components/BankDetailsInfo';
import TaxInfo, { TaxDetailsEditModal } from './Components/TaxInfo';
import { PortfolioType } from '../../Models/Portfolio/PortfolioType';
import InvestorLookup from '../../Models/Investors/InvestorLookup';
import { EnumHelper } from '@singularsystems/neo-core';
import InvestmentInfo from './Components/InvestmentInfo';
import { PortfolioSign } from '../../Models/Registration/Portfolio';
import ClickLink from '../../App/Components/ClickLink';

interface IAccountInformationProps {
    viewModel: AccountInformationVM;
}

@observer
export default class AccountInformationPanel extends React.Component<IAccountInformationProps> {

    private selectView(view: InfoPanelView) {
        this.props.viewModel.selectedView = view;
        this.props.viewModel.panelController.forward("InvestorDetails");
    }

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <>
                <InfoPanel controller={viewModel.panelController} arrowAnchorSelector="#user-settings-wrapper">
                    <div className="account-information-panel">
                        <InfoPanelColumn name="Home" backToText="Settings">
                            {this.renderHomeView()}
                        </InfoPanelColumn>

                        <InfoPanelColumn name="InvestorDetails" renderBackLink>
                            <Neo.Loader task={this.props.viewModel.taskRunner}>
                                {viewModel.investor &&
                                    <>
                                        {viewModel.selectedView === InfoPanelView.Identity && <AccountIdentityInfo viewModel={viewModel} />}
                                        {viewModel.selectedView === InfoPanelView.Address && <AccountAddressInfo viewModel={viewModel} />}
                                        {viewModel.selectedView === InfoPanelView.BankDetails && <BankDetailsInfo viewModel={viewModel} />}
                                        {viewModel.selectedView === InfoPanelView.AdditionalBankDetails && <ThirdPartyBankDetailsInfo viewModel={viewModel} />}
                                        {viewModel.selectedView === InfoPanelView.TaxDetails && <TaxInfo viewModel={viewModel} />}
                                    </>}
                            </Neo.Loader>
                        </InfoPanelColumn>

                        {viewModel.investmentVM &&
                            <InvestmentInfo viewModel={viewModel.investmentVM} />}

                        <InfoPanelColumn name="StartInvestment" renderBackLink>
                            <Neo.Loader task={this.props.viewModel.taskRunner}>
                                {viewModel.newInvestmentCommand && this.renderStartInvestment(viewModel.newInvestmentCommand)}
                            </Neo.Loader>
                        </InfoPanelColumn>
                    </div>
                </InfoPanel>

                <AccountIdentityEditModal viewModel={viewModel} />
                <AccountAddressEditModal viewModel={viewModel} />
                <BankDetailsEditModal viewModel={viewModel} />
                <ThirdPartyBankDetailsEditModal viewModel={viewModel} />
                <TaxDetailsEditModal viewModel={viewModel} />
            </>
        );
    }

    private renderHomeView() {
        const viewModel = this.props.viewModel;

        return (
            <Neo.Loader task={viewModel.taskRunner}>
                <h4>Investor Details</h4>

                {this.renderViewLink("ID", InfoPanelView.Identity)}
                {this.renderViewLink("Proof of Residence", InfoPanelView.Address)}

                <h4 className="mt-5">Investments & Debit Orders</h4>
                {viewModel.appInvestorData.investor &&
                    <>
                        {this.renderInvestmentLink(viewModel.appInvestorData.investor, PortfolioType.Discretionary)}
                        {this.renderInvestmentLink(viewModel.appInvestorData.investor, PortfolioType.TaxFree)}
                    </>}

                <h4 className="mt-5">Other</h4>

                {this.renderViewLink("Bank Details", InfoPanelView.BankDetails)}
                {this.renderViewLink("Tax Details", InfoPanelView.TaxDetails)}
                {this.renderViewLink("3rd Party Bank Account", InfoPanelView.AdditionalBankDetails)}
            </Neo.Loader>
        );
    }

    private renderStartInvestment(newInvestment: PortfolioSign) {
        const viewModel = this.props.viewModel,
            portfolioTypeName = EnumHelper.getItemMetadata(PortfolioType, newInvestment.portfolioTypeID).display;

        return (
            <div>
                <h4>Open {portfolioTypeName} Account</h4>

                <p>Please read and accept the investment mandate to open your {portfolioTypeName.toLowerCase()} investment account.</p>

                <ClickLink onClick={() => this.props.viewModel.documentApiClient.downloadMaintenanceDocument(newInvestment.maintenanceDocumentType, viewModel.taskRunner)}>
                    View full mandate
                </ClickLink>

                <Neo.Form model={newInvestment} onSubmit={() => viewModel.createInvestment()}>
                    <Neo.FormGroup className="mt-3" bind={newInvestment.meta.acceptTerms} label="I have read and agree to the investment mandate" />

                    <Neo.Button className="mt-1" isSubmit>Start Investing</Neo.Button>
                </Neo.Form>
            </div>

        );
    }

    private renderInvestmentLink(investor: InvestorLookup, portfolioType: PortfolioType) {
        const viewModel = this.props.viewModel,
            portfolio = investor.portfolioList.find(c => c.portfolioTypeID === portfolioType);

        const portfolioTypeName = EnumHelper.getItemMetadata(PortfolioType, portfolioType).display;

        return this.renderMainLink(
            portfolioTypeName,
            () => portfolio ? viewModel.selectPortfolio(portfolio) : viewModel.beginNewInvestment(portfolioType),
            portfolio ? undefined : "fa-plus",
            portfolio ? undefined : `Open ${portfolioTypeName} account`);
    }

    private renderViewLink(text: string, view: InfoPanelView, icon?: string, tooltip?: string) {
        return this.renderMainLink(text, () => this.selectView(view), icon, tooltip);
    }

    private renderMainLink(text: string, onClick: () => void, icon?: string, tooltip?: string) {
        return (
            <a className="link-with-arrow" href="/" data-tip={tooltip} data-tip-align="end" onClick={e => { e.preventDefault(); onClick(); }}>
                {text} <i className={"fa fa-fw " + (icon ?? "fa-angle-right")} />
            </a>
        );
    }
}