import { NeoModel, ModelBase, Attributes, Validation } from "@singularsystems/neo-core";
import { InvestableType } from "../Base/Enums/InvestableType";
import TradeCost, { TradeFees } from "../Base/TradeCost";
import { Switch, TradeRequest } from '../Base/TradeRequest';
import Instrument from "./Instrument";

class InstrumentDetail extends ModelBase {

    public instrumentID: number = 0;

    @Attributes.Float()
    public amount: number = 0;

    // Client only properties / methods

    @Attributes.NoTracking()
    public instrument: string = "";

    @Attributes.NoTracking()
    public estimatedUnits: number = 0;

    @Attributes.NoTracking()
    public hasTradeCosts: boolean = true

    @Attributes.NoTracking()
    public tradeFees = new TradeFees();

    @Attributes.NoTracking()
    public instrumentMinimumTradeValue: number = 0;

    @Attributes.NoTracking()
    public modelPortfolioMinimumTradeValue: number = 0;

    @Attributes.NoTracking()
    public minimumRandValue: number = 0;

    @Attributes.NoTracking()
    public minimumTradeValue: number = 0;

    @Attributes.NoTracking()
    public minimumEstimatedUnits: number = 1;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.instrumentID === null, "Please choose an instrument.");
        rules.failWhen(c => c.amount < c.minimumRandValue, "Minimum amount has not been reached for this instrument. Please enter a higher amount.");
        rules.failWhen(c => c.amount < c.minimumTradeValue, c => `Make sure the entered amount has reached R${c.instrumentMinimumTradeValue} for ETFs and R${c.modelPortfolioMinimumTradeValue} for Model Portfolios.`);
        rules.failWhen(c => c.estimatedUnits < c.minimumEstimatedUnits, `Estimated units need to be more than ${this.minimumEstimatedUnits}, please increase the amount for this instrument or choose a different instrument.`);
    }

    public setTradeValues(instrument: Instrument, tradeCosts: TradeCost) {

        this.instrumentMinimumTradeValue = tradeCosts.instrumentMinimumTradeValue;
        this.modelPortfolioMinimumTradeValue = tradeCosts.modelPortfolioMinimumTradeValue;

        if (instrument.investableTypeID === InvestableType.Instrument) {
            this.minimumRandValue = tradeCosts.instrumentMinimumTradeValue;
        } else {
            this.minimumRandValue = tradeCosts.modelPortfolioMinimumTradeValue;
            this.minimumEstimatedUnits = 0; // model portfolios won't have a buy price, thus the estimated units shouldn't be validated
        }

        this.minimumTradeValue = this.minimumRandValue;
        this.minimumRandValue = this.minimumRandValue > instrument.buyPrice ? this.minimumRandValue : instrument.buyPrice;
    }
}

@NeoModel
export class SwitchInstrumentDetail extends InstrumentDetail {

    @Attributes.NoTracking()
    public percentage: number = 0;

    @Attributes.NoTracking()
    public minimumTradePercentage: number = 0;

    @Attributes.NoTracking()
    public minimumPercentageValue: number = 0;

    protected addBusinessRules(rules: Validation.Rules<this>): void {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.percentage < c.minimumPercentageValue, "Minimum amount has not been reached for this instrument. Please enter a higher percentage.").onProperties(c => c.percentage);
        rules.failWhen(c => c.percentage < c.minimumTradePercentage, c => `Make sure the entered percentage equates to a trade amount of at least R${c.instrumentMinimumTradeValue} for ETFs and R${c.modelPortfolioMinimumTradeValue} for Model Portfolios.`).onProperties(c => c.percentage);
    }

    public setSwitchTradeValues(parentSwitch: Switch, instrument: Instrument) {

        this.setTradeValues(instrument, parentSwitch.tradeCosts);
        this.amount = Math.trunc(this.amount * 100) / 100;
        this.minimumTradePercentage = this.minimumTradeValue / parentSwitch.valueAvailable;
        this.minimumPercentageValue = this.minimumRandValue / parentSwitch.valueAvailable;

        // Set estimated units and trade fees
        if (this.amount > 0) {
            this.tradeFees = instrument.hasTradeCosts ? parentSwitch.tradeCosts.getFees(this.amount) : new TradeFees();

            if (instrument.investableTypeID === InvestableType.Instrument) {
                var estimatedUnitsCalculation = 0;
                var sellAmountFees = parentSwitch.tradeCosts.getFees(this.amount).totalFees;
                var sellAmountMinusFees = this.amount - sellAmountFees;

                estimatedUnitsCalculation = ((sellAmountMinusFees * this.amount / this.amount) - this.tradeFees.totalFees) / instrument.buyPrice;
                this.estimatedUnits = instrument.buyPrice > 0 ? Math.floor(estimatedUnitsCalculation) : 0;
            } else {
                this.estimatedUnits = 0;
            }
        }

        parentSwitch.setTradeFees();
    }
}

@NeoModel
export class PurchaseInstrumentDetail extends InstrumentDetail {

    protected addBusinessRules(rules: Validation.Rules<this>): void {
        super.addBusinessRules(rules);
    }

    public setPurchaseTradeValues(tradeRequest: TradeRequest, instrument: Instrument) {

        this.setTradeValues(instrument, tradeRequest.tradeCosts);

        // Set estimated units and trade fees
        if (this.amount > 0) {
            this.tradeFees = instrument.hasTradeCosts ? tradeRequest.tradeCosts.getFees(this.amount) : new TradeFees();

            if (instrument.investableTypeID === InvestableType.Instrument) {
                var estimatedUnitsCalculation = (this.amount - tradeRequest.tradeFees.totalFees) / instrument.buyPrice;
                this.estimatedUnits = instrument.buyPrice > 0 ? Math.floor(estimatedUnitsCalculation) : 0;
            } else {
                this.estimatedUnits = 0;
            }
        }

        tradeRequest.calculateFees();
    }
}