import { AppServices } from '@singularsystems/neo-core'
import { injectable } from 'inversify';

@injectable()
export class AppConfig extends AppServices.ConfigModel {

    public apiPath: string = "";

    public oldSitePath: string = "";

    public researchPageUrl: string = ""
    
    public brokerBankDetails = {
        accountName: "",
        bank: "",
        branch: "",
        branchCode: 0,
        accountNumber: 0,
        accountType: ""
    }
    /**
     * Transforms property values loaded from config.json
     */
    public initialise() {
    }
}