import { ModelBase, NeoModel } from "@singularsystems/neo-core";
import { injectable } from "inversify";
import InvestorOTPDetails from "../Models/Investors/InvestorOTPDetails";
import { AppService, Types } from "./AppService";

@injectable()
@NeoModel
export default class OtpService extends ModelBase {

    constructor(private securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient)) {
        super();
    }

    public currentModel: OtpModel | null = null;
    public currentInvestor: InvestorOTPDetails | null = null;
    private _promiseResolver: ((value: IShowOtpResult) => void) | null = null;

    public showOtpModal(investor: InvestorOTPDetails, sendOtp: boolean = false) : Promise<IShowOtpResult> {
        this.currentInvestor = investor;
        this.currentModel = new OtpModel();
        if (sendOtp) {
            this.sendOtp();
        }

        return new Promise((resolve, reject) => {
            this._promiseResolver = resolve;
        });
    }

    /**
     * Sends an otp to the investor if necessary.
     * Returns the previous otp used if it is still valid, else null.
     */
    public async sendOtp(): Promise<string | null> {
        if (this.currentModel) {
            this.currentModel.otp = "";
        }

        await this.securityApiClient.sendOTP();

        // For now the investor must always enter an otp.
        return null;
    }

    public _onOtpEntered()
    {
        if (this._promiseResolver) {
            this._promiseResolver({ otp: this.currentModel!.otp });
            this.currentModel!.otp = "";
            // this.currentModel = null;
        }
    }

    public _onOtpCancelled() {
        if (this._promiseResolver) {
            this._promiseResolver({ cancelled: true });
        }
    }

    /**
     * Stores the otp for later use.
     */
    public storeValidOtp(otp: string) {

    }
}

@NeoModel
export class OtpModel extends ModelBase {
    public otp: string = "";
}

export interface IShowOtpResult {
    otp?: string;
    cancelled?: boolean;
}