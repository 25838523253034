import React from 'react';
import { observer } from 'mobx-react';
import InvestmentInfoVM from './InvestmentInfoVM';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import { Misc, ModalUtils, NumberUtils } from '@singularsystems/neo-core';
import { InfoPanelColumn } from '../../../App/Components/InfoPanel';
import { DebitOrderDays, RecurringTradeRequest, RecurringTradeRequestDetail, RecurringTradeRequestPost } from '../../../Models/Base/RecurringTradeRequest';

interface IInvestmentInfoProps {
    viewModel: InvestmentInfoVM;
}

@observer
export default class InvestmentInfo extends React.Component<IInvestmentInfoProps> {

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <>
                <InfoPanelColumn name="InvestmentDetails" backToText="Investment" renderBackLink>
                    <Neo.Loader task={viewModel.taskRunner}>
                        <h4>{viewModel.portfolio.portfolioType} Investment</h4>

                        <div className="mt-4">
                            <strong>Debit Orders</strong>

                            <div className="mt-2">
                                {!viewModel.debitOrderDetails && !viewModel.taskRunner.isBusy &&
                                    <div>You have no debit orders on your {viewModel.portfolio.portfolioType.toLowerCase()} investment.</div>}

                                {viewModel.debitOrderDetails && this.renderDebitOrders(viewModel.debitOrderDetails)}
                            </div>
                        </div>
                    </Neo.Loader>
                    <Neo.Loader task={viewModel.downloadTask}>
                        <div className="mt-4 mb-4">
                            {viewModel.TAUDebitOrderList && !viewModel.taskRunner.isBusy
                                && (
                                    <div className="mt-2">
                                        <strong>Debit Order Mandates: Trades</strong>
                                        <div className="mt-2">
                                            {viewModel.TAUDebitOrderList
                                                .sort((a, b) => b.startDate?.getTime()! - a.startDate?.getTime()!)
                                                .map(c =>
                                                    this.renderDownloadLink("DebitOrderMandate_" + c.startDate!.toDateString() + "_" + NumberUtils.format(c.amount, Misc.NumberFormat.CurrencyDecimals, "R"), () => viewModel.downloadTradeDebitOrderMandate(c.contractNumber, c.selfLink), "fa fa-download"))
                                            }
                                        </div>
                                    </div>
                                )}
                        </div>
                    </Neo.Loader>
                </InfoPanelColumn>

                <InfoPanelColumn name="EditDebitOrder" renderBackLink>
                    <Neo.Loader task={viewModel.taskRunner}>
                        {viewModel.editingDebitOrder && this.renderEditDebitOrder(viewModel.editingDebitOrder)}
                    </Neo.Loader>
                </InfoPanelColumn>
            </>
        );
    }

    private renderDownloadLink(text: string, onClick: () => void, icon?: string) {
        return (
            <a className="link-with-arrow" href="/" onClick={e => { e.preventDefault(); onClick(); }}>
                {text} <i className={"fa fa-fw " + (icon ?? "fa-angle-right")} />
            </a>
        );
    }

    private renderDebitOrders(debitOrderDetails: RecurringTradeRequest) {
        const viewModel = this.props.viewModel;

        return (
            <div>
                {!debitOrderDetails.hasSentOtp
                    ? <>
                        <div className="space-between">
                            <span>Total monthly debit order amount:</span>
                            <span>{NumberUtils.format(debitOrderDetails.totalDebitOrderAmount, Misc.NumberFormat.CurrencyDecimals)}</span>
                        </div>
                        <div className="mt-3">
                            <Neo.FormGroupInline
                                label="Debit order day"
                                bind={debitOrderDetails.meta.pendingDebitOrderDay}
                                select={{ itemSource: viewModel.appDataCache.debitOrderDays, onItemSelected: day => this.beginChangeDebitOrderDay(debitOrderDetails, day) }} />
                        </div>
                        <div className="panel-grid-container">
                            <NeoGrid.Grid items={debitOrderDetails.instruments}>
                                {(item, meta) => (
                                    <NeoGrid.Row showInHeader={false}>
                                        <NeoGrid.Column display={meta.instrumentName} />
                                        <NeoGrid.Column display={meta.amount} numProps={{ currencySymbol: "R" }} />
                                        <NeoGrid.ButtonColumn
                                            editButton={{ size: "sm", variant: "primary", onClick: () => viewModel.editDebitOrder(item) }}
                                            deleteButton={{ size: "sm", onClick: () => this.beginCancelDebitOrder(item) }}>

                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>
                        </div>
                    </>
                    : <Neo.Form model={debitOrderDetails}>
                        <p>
                            You have been sent a one time pin. Please enter it below to change your debit order.
                        </p>
                        <Neo.FormGroup bind={debitOrderDetails.meta.otp} label="One time pin" />

                        <Neo.Button onClick={() => viewModel.changeDebitOrderDay(debitOrderDetails.pendingDebitOrderDay, debitOrderDetails.otp)}>Submit</Neo.Button>
                    </Neo.Form>
                }
            </div>);
    }

    private renderEditDebitOrder(debitOrder: RecurringTradeRequestPost) {
        const viewModel = this.props.viewModel;

        return (
            <div>
                {!debitOrder.hasSentOtp &&
                    <Neo.Form model={debitOrder} onSubmit={() => viewModel.confirmChangeDebitOrder()}>
                        <h4 className="mb-0">{debitOrder.instrumentName}</h4>
                        <span className="text-muted">{viewModel.portfolio.portfolioType} investment</span>

                        <div className="my-3">
                            <Neo.FormGroupInline xs={6} label="Monthly Investment" bind={debitOrder.meta.amount} prependText="R"
                                onChange={() => viewModel.debitOrderEstimatedUnits()} />
                        </div>

                        <div className="brokerage-section">
                            <Neo.FormGroupInline display={debitOrder.fees.meta.brokerage} numProps={{ currencySymbol: "R" }} />
                            <Neo.FormGroupInline display={debitOrder.fees.meta.strateFee} numProps={{ currencySymbol: "R" }} label="STRATE" />
                            <Neo.FormGroupInline display={debitOrder.fees.meta.vat} numProps={{ currencySymbol: "R" }} />
                            <Neo.FormGroupInline display={debitOrder.fees.meta.totalFees} numProps={{ currencySymbol: "R" }} editorStyle={{ fontWeight: 600 }} />
                        </div>

                        <div className="text-right mt-3">
                            <Neo.Button variant="light" onClick={() => viewModel.panelController.back()}>Cancel</Neo.Button>
                            <Neo.Button isSubmit className="ml-2" disabled={!(debitOrder.isDirty && debitOrder.isValid)}>Submit</Neo.Button>
                        </div>
                    </Neo.Form>}

                {debitOrder.hasSentOtp &&
                    <Neo.Form model={debitOrder}>
                        <p>
                            You have been sent a one time pin. Please enter it below in order to change your debit order.
                        </p>
                        <Neo.FormGroup bind={debitOrder.meta.otp} label="One time pin" />

                        <Neo.Button onClick={() => viewModel.submitChangeDebitOrder()}>Submit</Neo.Button>
                    </Neo.Form>}
            </div>
        );
    }

    private async beginChangeReinvestDividends() {
        const viewModel = this.props.viewModel,
            portfolio = viewModel.portfolio;

        const prompt = portfolio.pendingReinvestDividends ?
            `Change ${portfolio.portfolioType} investment to reinvest dividends?` :
            `Change ${portfolio.portfolioType} investment to payout dividends?`;

        const result = await ModalUtils.showYesNoDismissible(portfolio.portfolioType + " Investment", prompt);

        if (result === Misc.ModalResult.Yes) {
            viewModel.updateReInvestDividends();
        } else {
            portfolio.pendingReinvestDividends = portfolio.reinvestDividends;
        }
    }

    private async beginChangeDebitOrderDay(debitOrder: RecurringTradeRequest, day?: DebitOrderDays) {
        if (day) {
            const viewModel = this.props.viewModel,
                portfolio = viewModel.portfolio;

            const result = await ModalUtils.showYesNoDismissible(
                portfolio.portfolioType + " Investment",
                `Change debit order date to ${day.description}?`, async () => {
                    let otp = await viewModel.taskRunner.waitFor(viewModel.otpService.sendOtp());
                    if (otp) {
                        viewModel.changeDebitOrderDay(day.dayOfMonth, otp);
                    } else {
                        viewModel.debitOrderDetails!.hasSentOtp = true;
                    }
                });

            if (result !== Misc.ModalResult.Yes) {
                debitOrder.pendingDebitOrderDay = debitOrder.debitOrderDay;
            }
        }
    }

    private beginCancelDebitOrder(debitOrder: RecurringTradeRequestDetail) {

        ModalUtils.showYesNoDismissible(
            "Cancel Debit Order",
            `Are you sure you want to cancel the debit order for ${debitOrder.instrumentName}?`,
            () => this.props.viewModel.cancelDebitOrder(debitOrder));
    }
}