import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class InvestorFicaStatusLookup extends LookupBase {

    public investorID: number = 0;

    @Attributes.Integer()
    public identityStatus: number = 0;

    @Attributes.Integer()
    public addressStatus: number = 0;

    @Attributes.Integer()
    public taxStatus: number = 0;

    @Attributes.Integer()
    public bankDetailsStatus: number | null = null;

    @Attributes.Integer()
    public thirdPartyBankDetailsStatus: number | null = null;

    public identityFailedReason: string = "";

    public addressFailedReason: string = "";

    public taxFailedReason: string = "";
}