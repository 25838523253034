import { Attributes, Misc, NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import { IDCheckResult } from '../Enums/IDCheckResult';
import { IDType } from '../Registration/Enums/IDType';
import { Gender } from '../Registration/Enums/Gender';

@NeoModel
export default class UpdateIdentityCommand extends ValueObject {

    constructor(public investorId: number) {
        super();
    }

    @Rules.Required("Identity required")
    public idNo: string = "";

    public gender: Gender | null = null;
    
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public birthDate: Date | null = new Date('1980-01-01');

    @Rules.Required()
    public idType: IDType | null = null;

    @Rules.Required()
    public documentId: number | null = null;

    public idCardCombined: boolean = false;

    @Attributes.Nullable()
    public additionalDocumentId: number | null = null;

    @Attributes.Nullable()
    public countryId: number | null = null

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.idType === IDType.IDCard && !c.idCardCombined && c.additionalDocumentId === null, "Back side of Id card must be provided.").onModel();
        rules.add(this.checkIDNo).onProperties(c => c.idNo);
        rules.failWhen(c => c.countryId === null && c.idType === IDType.Passport, "Country is required")
        rules.failWhen(c => c.idType === IDType.Passport && c.gender === null, "Gender is required.");
        rules.failWhen(c => c.idType === IDType.Passport && c.birthDate === null, "Birth Date is required.");
    }

    private checkIDNo(context: Validation.IRuleContext) {
        
        if (this.idNo) {
            context.beginAsync("Checking identification...", async asyncContext => {

                if (this.idNo) {
                    const result = await AppService.get(Types.ApiClients.InvestorApiClient).checkIDNumber(this.investorId, this.idNo);
                    if (result.data === IDCheckResult.IdAlreadyExists) {
                        asyncContext.addError("This ID number already exists");
                    } else if (result.data === IDCheckResult.InvalidIDNumber && this.idType !== IDType.Passport) {
                        asyncContext.addError("ID number is invalid");
                    }
                }
                
                asyncContext.endAsync();

            }, { delayMs: 1000 });
        }
    }

    public toString(): string {
        return "Update Identity Command";
    }
}