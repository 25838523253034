import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import { RegistrationState } from '../../../Models/Registration/Enums/RegistrationState';
import PoliticalSensitivity from '../../../Models/Base/PoliticalSensitivity';
import { YesNo } from '../../../Models/Enums/YesNo';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';

export interface IPoliticalSensitivityComponentProps {
    taskRunner: ITaskRunner;
    onRegisterStateChange: (state: RegistrationState) => void;
}

@injectable()
@NeoModel
export class PoliticalSensitivityComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        public politicalSensitivityApiClient = AppService.get(Types.ApiClients.PoliticalSensitivityApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

        super(taskRunner);
    }

    public politicalSensitivity: PoliticalSensitivity = new PoliticalSensitivity();

    public yesNoSelection: YesNo | null = null;

    public agree: boolean = false;

}

@observer
export default class PoliticalSensitivityComponent extends React.Component<IPoliticalSensitivityComponentProps> {

    private viewModel = new PoliticalSensitivityComponentVM(this.props.taskRunner);

    public putPoliticalSensitivity() {
        this.viewModel.taskRunner.run("Saving Political Sensitivity", async () => {
            var response = await this.viewModel.politicalSensitivityApiClient.saveInvestorPoliticalSensitivity(this.viewModel.politicalSensitivity.toJSObject());
            if (response.data) {
                if (response.data.success) {
                    this.viewModel.notifications.addSuccess("Political Sensitivity", "You have successfully updated your political sensitivity", NotificationDuration.Standard);
                    this.props.onRegisterStateChange(RegistrationState.ProofOfResidence);
                }
            } else {
                this.viewModel.notifications.addDanger("Sign Up Error", "validation error", NotificationDuration.Standard);
            }
        });
    }

    public setAssociation() {
        this.viewModel.politicalSensitivity.associatedInd = this.viewModel.yesNoSelection === YesNo.Yes;
        if (!this.viewModel.politicalSensitivity.associatedInd){
            this.viewModel.politicalSensitivity.duration = "";
            this.viewModel.politicalSensitivity.jurisdiction = "";
            this.viewModel.politicalSensitivity.position = "";
            this.viewModel.politicalSensitivity.year = "";
        }
    }

    private tcTextColor() {
        return this.viewModel.agree ? "#369afe" : "#636e95";
    }

    public render() {

        const viewModel = this.viewModel;
        return (
            <div className="register-card">
                <h4>Politically sensitive persons details as follows </h4>

                <p className="my-4">Domestic Prominent Influential Person (DPIP) or Foreign Prominent Public Official (FPPO) A DPIP is an individual who holds, including in an acting position for a period exceeding 6 (six) months or has held at any time in the preceding 12 (twelve) months, in the Republic of South Africa a prominent public function. </p>
                <p className="my-4">A FPPO is an individual who currently holds or has held at any time in the preceding 12 (twelve) months, in any foreign country a prominent public function.</p>
                <p> <span className="required-indicator">* </span>indicates a required field</p>

                <Neo.FormGroup bind={viewModel.meta.yesNoSelection}
                    label={<><span className="required-indicator">* </span>I consider myself to be, or to be associated with a DPIP or FPPO?</>}
                    radioList={{ enumType: YesNo }}
                    onChange={this.setAssociation.bind(this)} />

                <Neo.Card className="political-sensitivities-info" hidden={viewModel.yesNoSelection === YesNo.No || viewModel.yesNoSelection === null}>
                    <Neo.GridLayout md={2} lg={2}  >
                        <Neo.FormGroup bind={viewModel.politicalSensitivity.meta.jurisdiction} label={<><span className="required-indicator">* </span>Jurisdiction</>} />
                        <Neo.FormGroup bind={viewModel.politicalSensitivity.meta.duration} label={<><span className="required-indicator">* </span>Duration</>} />
                        <Neo.FormGroup bind={viewModel.politicalSensitivity.meta.year} label={<><span className="required-indicator">* </span>Year</>} />
                        <Neo.FormGroup bind={viewModel.politicalSensitivity.meta.position} label={<><span className="required-indicator">* </span>Position</>} />
                    </Neo.GridLayout>
                </Neo.Card>

                <Neo.Card className="notify-etfsa-checkbox">                
                    <Neo.FormGroup bind={viewModel.meta.agree} label={<><span className="required-indicator">* </span>I undertake to notify etfSA in writing if these circumstances change</>} style={{ color: this.tcTextColor() }} disabled={viewModel.yesNoSelection === null} />

                    <div className="my-4">
                        <Neo.Button onClick={this.putPoliticalSensitivity.bind(this)} disabled={!viewModel.agree || !viewModel.politicalSensitivity.isValid}>NEXT STEP</Neo.Button>
                    </div>
                </Neo.Card>
            </div>
        );
    }
}
