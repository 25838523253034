import { NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class BankAccountType extends ApiModelBase {

    public bankAccountTypeID: number = 0;

    public bankAccountTypeName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.bankAccountTypeName) {
            return "New bank account type";
        } else {
            return this.bankAccountTypeName;
        }
    }
}