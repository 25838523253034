import { ModelBase, NeoModel, Rules } from "@singularsystems/neo-core";
import { OtpType } from "../Registration/Enums/OtpType";

@NeoModel
export default class ChangeOTPPreference extends ModelBase {

    constructor(public newMethod: OtpType) {
        super();
    }

    public areYouSure: boolean = false;

    @Rules.Required()
    public otp: string = "";

    public get otpTypeViaText() {
        return this.newMethod === OtpType.CellNo ? "sms" : "email";
    }
}