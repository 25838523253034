import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import UpdateIdentityCommand from '../../../Models/Investors/UpdateIdentityCommand';
import { Gender } from '../../../Models/Registration/Enums/Gender';
import { IDType } from '../../../Models/Registration/Enums/IDType';
import { AppService, Types } from '../../../Services/AppService';
import ClickLink from '../../../App/Components/ClickLink';
import { FileUtils } from '@singularsystems/neo-core';

interface IIdentityDetailsProps {
    command: UpdateIdentityCommand;
}

@observer
export default class IdentityDetails extends React.Component<IIdentityDetailsProps> {

    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);
    private downloadTask = AppService.get(Types.Neo.TaskRunner);
    private appDataCache = AppService.get(Types.Services.AppDataCache);

    private async downloadDocument(documentID: number) {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getInvestorDocumentById(documentID));
        FileUtils.showSaveFile(response);
    }

    public renderDownloadButton(documentId: number | null) {
        return (
            documentId &&
                <p className="file-download-text">
                    <ClickLink onClick={() => this.downloadDocument(documentId!)}>Download <i className="fa fa-download" /></ClickLink>
                </p>       
        )
    }

    public render() {
        const { command } = this.props;

        return (
            <div className="upload-section">

                <Neo.Form>
                <p> <span className="required-indicator">* </span>indicates a required field</p>
                    <Neo.FormGroupInline bind={command.meta.idType} className="mt-4" suppressLabel radioList={{ enumType: IDType }} />
                    {command.idType !== null &&
                        <Neo.FormGroup bind={command.meta.idNo} label={<><span className="required-indicator">* </span> {command.idType === IDType.Passport ? "Passport Number" : "ID Number"} </>} />
                    }
                    {command.idType !== null && command.idType === IDType.Passport &&
                        <Neo.FormGroup bind={command.meta.countryId} select={{ itemSource: this.appDataCache.countries }} label={<><span className="required-indicator">* </span> Country </>} />
                    }
                    {command.idType !== null && command.idType === IDType.Passport &&
                        <Neo.FormGroup bind={command.meta.birthDate} dateProps={{formatString: "yyyy-MM-dd"}} label={<><span className="required-indicator">* </span> Date of birth </>}/>
                    } 
                    {command.idType !== null && command.idType === IDType.Passport && 
                        <Neo.FormGroup bind={command.meta.gender} radioList={{ enumType: Gender }} label={<><span className="required-indicator">* </span> Gender </>} />
                    }                 
                    {command.idType === IDType.IDCard &&
                        <Neo.FormGroup bind={command.meta.idCardCombined} label={"Document contains both sides of ID Card"} />
                    }

                </Neo.Form>

                {command.idType !== null &&
                    <div className="mt-4">
                        <label><span className="required-indicator">* </span>{command.idType === IDType.IDBook ? "ID Book / Birth Certificate" : (command.idType === IDType.IDCard ? command.idCardCombined ? "ID Card" : "Front of your Card" : "Passport")}</label>
                        <Neo.FileContext
                            uploadEndpoint={this.documentApiClient.getInvestorDocumentUploadUrl()}
                            afterUpload={docId => command.documentId = docId}
                            allowedExtensions={this.documentApiClient.allowedInvestorDocumentExtensions}
                            taskRunnerLoadingText={"Uploading ID"}>

                            <Neo.FileDropArea className={!command.documentId && !command.additionalDocumentId ? "neo-file-drop" : "neo-file-drop-uploaded"}>
                                <Neo.FileUploadButton className={!command.documentId ? "upload-icon" : "upload-completed-icon"}></Neo.FileUploadButton>
                                <p className="upload-text">
                                    {command.documentId &&                                      
                                        <p className="upload-text">Upload complete</p>
                                    }                                
                                    Drag {command.idType === IDType.IDCard ? "the front of your card" : "your file"} here or browse to {!command.documentId ? "upload" : "update"}.
                                </p>
                                <p className="file-type-text">Jpeg or pdf</p>
                                {this.renderDownloadButton(command.documentId)}
                                <Neo.FileUploadProgress variant={"dark"} type={"animated"} reversed />
                            </Neo.FileDropArea>
                        </Neo.FileContext>
                    </div>}

                {command.idType !== null && command.idType === IDType.IDCard && !command.idCardCombined &&
                    <div className="mt-4">
                        <label><span className="required-indicator">* </span>Back of your Card</label>
                        <Neo.FileContext
                            uploadEndpoint={this.documentApiClient.getInvestorDocumentUploadUrl()}
                            afterUpload={docId => command.additionalDocumentId = docId}
                            allowedExtensions={this.documentApiClient.allowedInvestorDocumentExtensions}>
                            <Neo.FileDropArea className={!command.additionalDocumentId ? "neo-file-drop" : "neo-file-drop-uploaded"}>
                                <Neo.FileUploadButton className={!command.additionalDocumentId ? "upload-icon" : "upload-completed-icon"}></Neo.FileUploadButton>
                                {command.additionalDocumentId &&
                                <p className="upload-text">Upload complete</p>
                                }
                                <p className="upload-text">Drag the back of your card here or browse to {!command.additionalDocumentId ? "upload" : "update"}.</p>
                                <p className="file-type-text">Jpeg or pdf</p>
                                {this.renderDownloadButton(command.additionalDocumentId)}
                                <Neo.FileUploadProgress variant={"dark"} type={"animated"} reversed />
                            </Neo.FileDropArea>
                        </Neo.FileContext>
                    </div>
                }
            </div>
        )
    }
}