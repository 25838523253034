import React from 'react';
import { Routing as NeoRouting } from '@singularsystems/neo-core';
import { Routing } from '@singularsystems/neo-react';
import { withRouter, Switch, Route, Redirect } from 'react-router';
import { AppService, Types } from '../../Services/AppService';
import RegisterView from '../../Views/Registration/RegisterView';
import { investorProfileRoute } from '../../Services/RouteService';

class RouteView extends Routing.RouteView {

    private routeService = AppService.get(Types.Services.RouteService);
    private investorService = AppService.get(Types.Services.CurrentInvestorService);
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);

    constructor(props: any) {
        super(props);

        this.routes = this.routeService.routes;
        this.switchComponent = Switch;
        this.routeComponent = Route;

        this.getForbiddenComponent = (route) => <h2>Forbidden</h2>;
        this.getSigningInComponent = (route) => <div>Signing in...</div>;
    }

    protected getAuthorisedComponent(route: NeoRouting.IRoute, path: string) {

        if (!route.allowAnonymous) {

            const investorData = this.investorService.investorData;
            if (investorData) {
                if (investorData.investor === null) {
                    return <div>Restoring profile...</div>
                }
                if (investorData.mustCompleteRegistration) {
                    return <Redirect to={this.navigation.getPathForView(RegisterView)} />
                }
                if (!investorData.readyToInvest && route.path !== investorProfileRoute.path) {
                    return <Redirect to={investorProfileRoute.path!} />
                }
            }
        }

        return super.getAuthorisedComponent(route, path);
    }

    
}

export default withRouter(RouteView);