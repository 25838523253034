import { NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class InvestorDocument extends ApiModelBase {

    public guid: string = Utils.emptyGuid();

    public documentID: number = 0;

    public documentTypeID: number = 0;

    public documentName: string = "";
    
    public portfolioTypeID: number | null = null

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.guid) {
            return "New investor document";
        } else {
            return this.guid;
        }
    }
}