import { NeoModel, Validation, Rules, Attributes } from '@singularsystems/neo-core';
import APIModelBase from '../ApiModelBase';

@NeoModel
export default class LoginDetails extends APIModelBase {

    @Rules.Required()
    @Attributes.Display("Username")
    public userName: string = "";

    @Rules.Required()
    public password: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.userName) {
            return "New login details";
        } else {
            return this.userName;
        }
    }
}