import React from 'react';
import WithdrawCashVM from './WithdrawCashVM';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { ReadOnlyBankAccountDetails } from '../Components/BankAccountDetails';
import { ModalUtils } from '@singularsystems/neo-core/dist/Components/Modal/ModalUtils';
import { Misc, NumberUtils } from '@singularsystems/neo-core';
import '../../../Styles/Registration/OTPComponent.scss';
import InvestorBankDetail from '../../../Models/Investors/InvestorBankDetail';
import TradeCostDetails from "../Components/TradeCostDetails";

interface IWithdrawCashProps {
    viewModel: WithdrawCashVM;
}

@observer
export default class WithdrawCash extends React.Component<IWithdrawCashProps> {

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <div className="transaction-page">
                <h3 className="page-header">{"Withdraw from " + viewModel.instrumentBalanceDetails?.instrument}</h3>

                <div className="page-container mt-4">

                    <div className="row justify-content-between">
                        <div className="col-md-5 offset-md-3">
                            <Neo.Form model={viewModel.currentSale} onSubmit={() => viewModel.setBankDetails()} className="p-2">

                                <h4>Total Costs for this Transaction</h4>

                                <label className="withdraw-amount">Enter an Amount</label>

                                <Neo.FormGroup
                                bind={viewModel.currentSale.meta.amount} 
                                isReadOnly={viewModel.isWithdrawAll} suppressLabel 
                                numProps={{ format: Misc.NumberFormat.CurrencyDecimals, currencySymbol: "R"}}
                                onChange={() => viewModel.setTransactionFees()}/>

                                {(viewModel.currentSale.isAboveThreshold && !viewModel.isWithdrawAll)&& <div className="error-text pb-2">Please note a sale of over 90% of your holdings will lead to a 100% withdrawal</div>}
                                <div className="link-smaller text-right pb-2">
                                    <Neo.Link onClick={() => viewModel.withdrawAvailableUnits()}>{viewModel.isWithdrawAll ? "ENTER SPECIFIC AMOUNT" : "WITHDRAW ALL"}</Neo.Link>
                                </div>

                                <div hidden={!viewModel.instrumentBalanceDetails?.hasTradeCosts ?? false}>
                                    <TradeCostDetails tradeFees={viewModel.currentSale.tradeFees}/>
                                </div>

                                <hr/>

                                <div className="row" style={{paddingBottom: "Inherit"}}>
                                    <div className="col-8">Estimated Final Amount:</div>
                                    <div className="col-4 text-right">{NumberUtils.format(viewModel.currentSale.amount - viewModel.currentSale.tradeFees.totalFees, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                </div>

                                <div className="text-right">
                                    <Neo.Button variant="primary" isSubmit disabled={!viewModel.currentSale.isValid}>Confirm</Neo.Button>
                                </div>
                            </Neo.Form>
                        </div>
                        <div className="order-first order-md-2 col-md-3 mb-3">
                            <div>
                                <div className="content-box">
                                    <label>Available Holdings</label>
                                    <div>{NumberUtils.format(viewModel.instrumentBalanceDetails?.valueAvailable ?? 0, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                </div>
                            </div>
                        </div>
                    </div>       
                </div>

                <Neo.Modal 
                    title="Deposit Account Details" 
                    bindModel={viewModel.meta.bankDetails}
                    size="lg"
                    closeButton={false}>
                    {(bankDetails: InvestorBankDetail) => (
                        <div className="row">
                            <div className="col-8 offset-md-4">
                                <ReadOnlyBankAccountDetails bankAccount={bankDetails!}/>
                                <div>
                                    <div style={{paddingTop: "10px"}}>
                                        <Neo.Link onClick={() => this.beginEditBankDetails()}>Edit Bank Details</Neo.Link>
                                    </div>
                                    <div>
                                    <Neo.Button className="mt-3" style={{width: "40%"}} onClick={() => { viewModel.showOtp(); }}>
                                        Submit
                                    </Neo.Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Neo.Modal>

                <Neo.Modal
                    title={"Withdraw from " + viewModel.instrumentBalanceDetails?.instrument + " complete"}
                    show={viewModel.showSuccessModal}
                    size="lg"
                    onClose={() => viewModel.navigateToDashboard(true)}
                    closeButton={false}
                    acceptButton={{ text: "GO TO MY DASHBOARD", onClick: () => viewModel.navigateToDashboard(true)}}>
                    <div>
                        Your withdrawal has been actioned and will be deposited into the following bank account.

                        {viewModel.investor?.primaryBankDetails && 
                            <div className="mt-4 mb-4">
                                <ReadOnlyBankAccountDetails bankAccount={viewModel.investor.primaryBankDetails}/>
                            </div>}

                        Contact us at <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za</a> for queries and changes.
                    </div>
                </Neo.Modal>
                

            </div>
        );
    }
    
    private beginEditBankDetails() {
        this.props.viewModel.bankDetails = null;
        ModalUtils.showYesNoDismissible(
            "Cancel Withdrawal",
            <div>
                <p>
                    Please note that your new bank details needs to be verified before you can submit this withdrawal instruction. 
                    Please look out for the verification email from <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za. </a>
                    Once received, please reload your withdrawal instruction. Do you want to continue?
                </p>
            </div>,
            () => this.props.viewModel.editBankDetails());
    }
} 