import { Attributes, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';
import { OtpType } from './Enums/OtpType';

@NeoModel
export default class Registration extends APIModelBase {

    public guid: string = Utils.emptyGuid();

    @Rules.Required()
    public firstName: string = "";

    @Rules.Required()
    public surname: string = "";

    @Rules.Required()
    @Rules.EmailAddress()
    public emailAddress: string = "";
    
    @Rules.Required()
    @Attributes.OnChanged<Registration>(c => c.validCellPhone)
    public cellNo: string = "";

    @Rules.Required()
    public password: string = "";

    public otpTypeId: OtpType = 0;

    public sendEmailOnly: boolean = false;

    // Client only properties / methods
    
    public registrationId: number = 0;
    
    @Rules.Required()
    public renterPassword: string = "";

    public get cellphoneOtp() {
        return this.otpTypeId === OtpType.CellNo;
    }
    public set cellphoneOtp(value: boolean) {
        this.otpTypeId = OtpType.CellNo;
    }

    public get emailOtp() {
        return this.otpTypeId === OtpType.Email;
    }
    public set emailOtp(value: boolean) {
        this.otpTypeId = OtpType.Email;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {

        rules.failWhen(registration => registration.renterPassword !== registration.password, "Passwords are not the same");

        rules.failWhen(registration => registration.cellphoneOtp === false && registration.emailOtp === false, "You must select an OTP option.")

        rules.failWhen(registration => registration.validCellPhone(registration.cellNo) === false, "Invalid phone number")
    }

    public validCellPhone(cellphone: string) {

        if (cellphone) {
            var pattern = new RegExp('^([0]|\\+[0-9]{1,3})[0-9]{9}$');
            return pattern.test(cellphone);
        } else {
            return true
        }
    }

}