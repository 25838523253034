import { Attributes, ModelBase, NeoModel } from "@singularsystems/neo-core";
import { AppService, Types } from '../../Services/AppService';
import InvestorLookup from './InvestorLookup';
import { RegistrationState } from '../Registration/Enums/RegistrationState';
import AccountInformationVM from "../../Views/InformationPanel/AccountInformationVM";
import InvestorLinkedPortfolioLookup from "./InvestorLinkedPortfolioLookup";
import InvestorFicaStatusLookup from "./InvestorFicaStatusLookup";
import DocumentPanelVM from "../../Views/InformationPanel/DocumentPanelVM";

@NeoModel
export default class AppInvestorData extends ModelBase {

    constructor(
        public investorId: number,
        private investorQueryApiClient = AppService.get(Types.ApiClients.InvestorQueryApiClient)) {

        super();

        this.accountInformationVM = new AccountInformationVM(investorId, this);
        this.documentsVM = new DocumentPanelVM(this);
    }

    private _investor: InvestorLookup | null = null;

    public selectedPortfolio: InvestorLinkedPortfolioLookup | null = null;

    private _ficaStatuses: InvestorFicaStatusLookup | null = null;

    public readonly accountInformationVM: AccountInformationVM;

    public readonly documentsVM: DocumentPanelVM;

    /**
     * Returns the current investor. May return null, and fetch the investor.
     */
    public get investor(): InvestorLookup | null {
        if (!this._investor) {
            this.fetchInvestor();
        }
        return this._investor;
    }

    public set investor(investor: InvestorLookup | null) {
        this._investor = investor;

        if (investor) {
            const selectedPortfolioTypeJSON = sessionStorage.getItem('selectedPortfolioTypeID');

            if (selectedPortfolioTypeJSON) {
                this.selectedPortfolio = investor.portfolioList.find(c => c.portfolioTypeID === Number(selectedPortfolioTypeJSON))!;
                sessionStorage.removeItem('selectedPortfolioTypeID');
            }

            if (!this.selectedPortfolio) {
                this.selectedPortfolio = investor.portfolioList.find(c => c.isPrimary) ?? investor.portfolioList[0] ?? null;
            }
        } else {
            this.selectedPortfolio = null;
        }
    }

    public get ficaStatuses() {
        if (!this._ficaStatuses) {
            this.fetchFicaInfo();
        }
        return this._ficaStatuses;
    }

    public clearFicaStatuses() {
        this._ficaStatuses = null;
    }

    public get mustCompleteRegistration() {
        return !this._investor || this._investor.investorStatusID < RegistrationState.Mandate;
    }

    public get readyToInvest() {
        return this._investor && this._investor.investorStatusID === RegistrationState.AccountCreated;
    }

    public async getInvestorAsync() {
        const investor = this.investor;
        if (investor !== null) {
            return Promise.resolve(this._investor!);
        } else {
            await this.investorPromise;
            return this.investor!;
        }
    }

    public async getFicaStatusesAsync() {
        const ficaStatuses = this.ficaStatuses;
        if (ficaStatuses !== null) {
            return Promise.resolve(this._ficaStatuses);
        } else {
            await this.ficaStatusPromise;
            return this.ficaStatuses;
        }
    }

    public dispose() {
        this.accountInformationVM.dispose();
    }

    @Attributes.Observable(false)
    private investorPromise: Promise<any> | null = null;

    private async fetchInvestor() {
        if (!this.investorPromise) {
            this.investorPromise = this.investorQueryApiClient.getInvestorLookup();
            const result = await this.investorPromise;

            this.investor = InvestorLookup.fromJSObject<InvestorLookup>(result.data);
            this.investorPromise = null;
        }

        return this._investor;
    }

    @Attributes.Observable(false)
    private ficaStatusPromise: Promise<any> | null = null;

    private async fetchFicaInfo() {
        if (!this.ficaStatusPromise) {
            this.ficaStatusPromise = this.investorQueryApiClient.getFicaStatus();
            const result = await this.ficaStatusPromise;

            this._ficaStatuses = InvestorFicaStatusLookup.fromJSObject<InvestorFicaStatusLookup>(result.data);
            this.ficaStatusPromise = null;
        }

        return this._ficaStatuses;
    }

    public refetchInvestor() {
        this.fetchInvestor();
    }
}