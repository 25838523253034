import { Attributes, List, LookupBase, Misc, NeoModel, NumberUtils, Rules, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';
import { PurchaseInstrumentDetail } from '../Instruments/InstrumentDetail';
import PurchaseTradeRequestBase from '../Library/PurchaseTradeRequestBase';
import TradeCost, { TradeFees } from './TradeCost';
import { InvestableType } from './Enums/InvestableType';

@NeoModel
export class RecurringTradeRequest extends PurchaseTradeRequestBase {

    @Attributes.Integer()
    public debitOrderDay: number = 0;

    public instruments = new List(RecurringTradeRequestDetail);

    public otp: string = "";

    // Client only properties / methods
   
    @Attributes.NoTracking()
    public hasSentOtp = false;

    public pendingDebitOrderDay: number = 0;

    public get totalDebitOrderAmount() {
        return this.instruments.sum(c => c.amount);
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.hasSentOtp && !c.otp, "Please enter your one time pin.").onProperties(c => c.otp);
    }

    protected onDeserialiseCompleted(wasCreated: boolean): void {
        this.pendingDebitOrderDay = this.debitOrderDay;
    }

    public toString(): string {
        if (this.isNew) {
            return "New recurring trade request";
        } else {
            return "Recurring Trade Request";
        }
    }
}

@NeoModel
export class RecurringTradeRequestDetail extends LookupBase {

    public instrumentID: number = 0;

    public instrumentName: string = "";

    @Attributes.Observable()
    @Attributes.Float()
    public amount: number = 0;

    // Client only properties / methods
}

@NeoModel
export class RecurringTradeRequestPost extends PurchaseTradeRequestBase {

    @Attributes.Integer()
    public debitOrderDay: number = 0;

    @Attributes.Nullable()
    public selectedInstruments = new List(PurchaseInstrumentDetail)

    public otp: string = "";

    // Client only properties / methods

    @Attributes.Float()
    @Attributes.NoTracking()
    public amount: number = 0;
    
    @Attributes.NoTracking()
    public tradeCosts: TradeCost | null = null;

    @Attributes.NoTracking()
    public instrumentName: string = "";

    @Attributes.NoTracking()
    public hasSentOtp = false;

    @Attributes.NoTracking()
    public investableType: InvestableType | null = null;

    public get fees() {
        return this.tradeCosts?.getFees(this.amount) ?? new TradeFees();
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.amount <= 0, "Please enter an amount above zero.");
        rules.failWhen(c => c.amount > c.tradeCosts?.maximumTradeValue!, c => `The entered amount is above the ${NumberUtils.format(c.tradeCosts?.maximumTradeValue!, Misc.NumberFormat.CurrencyDecimals)} limit.`)
        rules.failWhen(c => c.amount < c.tradeCosts?.instrumentMinimumTradeValue! && c.investableType! === InvestableType.Instrument, c => `The entered amount is below the minimum trade value of ${NumberUtils.format(c.tradeCosts?.instrumentMinimumTradeValue!, Misc.NumberFormat.CurrencyDecimals)}.`)
        rules.failWhen(c => c.amount < c.tradeCosts?.modelPortfolioMinimumTradeValue! && c.investableType! === InvestableType.ModelPortfolio, c => `The entered amount is below the minimum trade value of ${NumberUtils.format(c.tradeCosts?.modelPortfolioMinimumTradeValue!, Misc.NumberFormat.CurrencyDecimals)}.`)
        rules.failWhen(c => c.hasSentOtp && !c.otp, "Please enter a one time pin.").onProperties(c => c.otp);
    }

    public toString(): string {
        if (this.isNew || !this.otp) {
            return "New recurring trade request post";
        } else {
            return this.otp;
        }
    }
}
@NeoModel
export class RecurringTradeRequestCancel extends ApiModelBase {

    public portfolioTypeID: number = 0;

    public instrumentID: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New recurring trade request cancel";
        } else {
            return "Recurring Trade Request Cancel";
        }
    }
}

@NeoModel
export class DebitOrderDays extends ApiModelBase {

    @Attributes.Integer()
    public dayOfMonth: number = 0;

    public description: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.description) {
            return "New debit order days";
        } else {
            return this.description;
        }
    }
}
@NeoModel
export class TAUDebitOrder extends ApiModelBase {
    public contractNumber: string = "";

    public selfLink: string = "";

    public portfolioTypeID: number = 0;

    @Rules.Required()
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public startDate: Date | null = null;

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public nextCollectionDate: Date | null = null;

    @Attributes.Float()
    public amount: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.contractNumber) {
            return "New tau debit order";
        } else {
            return this.contractNumber;
        }
    }
}