import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import Instrument from '../Models/Instruments/Instrument';

export interface IInstrumentApiClient extends Data.IReadApiClient<Instrument, string> {

    /** 
     * Returns all Instruments Including Instrument information from Global.
     */
    getInstrumentList(): AxiosPromise<Array<Model.PlainTrackedObject<Instrument>>>;

    /** 
     * Returns all Tradeable Instruments for a particular portfolio type.
     */
    getTradeableInstrumentList(PortfolioTypeID: number): AxiosPromise<Array<Model.PlainTrackedObject<Instrument>>>;

    // Client only properties / methods
}

@injectable()
export default class InstrumentApiClient extends Data.ReadApiClient<Instrument, string> implements IInstrumentApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/Instrument`);
    }

    public getInstrumentList(): AxiosPromise<Array<Model.PlainTrackedObject<Instrument>>> {
        return this.axios.get(`${this.apiPath}/GetInstrumentList`);
    }

    public getTradeableInstrumentList(PortfolioTypeID: number): AxiosPromise<Array<Model.PlainTrackedObject<Instrument>>> {
        return this.axios.get(`${this.apiPath}/GetTradeableInstrumentList?PortfolioTypeID=${PortfolioTypeID}`);
    }

    // Client only properties / methods
}