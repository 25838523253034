import { Security } from '@singularsystems/neo-core';
import LoginResult from './LoginResult';

export default class AppUser implements Security.IUser {

    constructor(public userInfo: LoginResult) {

    }

    public get userName() {
        return this.userInfo.userName;
    }

    public get displayName() {
        return this.userInfo.fullName;
    }

    public get hasExpired() {
        return Date.now() >= this.userInfo.tokenInfo.expiryDate!.getTime();
    }

    public get accessToken() {
        return this.userInfo.tokenInfo.authToken;
    }
    
    public get investorID() : number | null {
        return this.userInfo.investorID;
    }
}