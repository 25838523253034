import { Misc } from '@singularsystems/neo-core';
import NumberUtils from '@singularsystems/neo-core/dist/NumberUtils';
import React from 'react';
import { TradeFees } from '../../../Models/Base/TradeCost';

interface ITradeFeesProps {
    tradeFees: TradeFees;
}

export default class TradeCostDetails extends React.Component<ITradeFeesProps> {

    private renderFee(label: string, amount: number) {
        return <div className="row-breakdown">
                    <div className="col-8">{label}:</div>
                    <div className="col-4 text-right">{NumberUtils.format(amount, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                </div>
    }

    public render() {
        const tradeFees = this.props.tradeFees;
        
        return (
            <div>
                {this.renderFee("Brokerage", tradeFees.brokerage)}
                {this.renderFee("STRATE", tradeFees.strateFee)}
                {this.renderFee("VAT", tradeFees.vat)}
                {this.renderFee("Estimated Total Fees", tradeFees.totalFees)}
            </div>
        )
    }
}