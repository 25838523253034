import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import { injectable } from 'inversify';
import OtpInput from 'react-otp-input';
import { NotificationDuration } from '../../../Models/Enums/NotificationDuration';
import { OtpType } from '../../../Models/Registration/Enums/OtpType';
import Home from '../../Dashboard/Dashboard';
import OTPRegistrationVerification from '../../../Models/Security/OTPRegistrationVerification';
import Registration from '../../../Models/Registration/Registration';

export interface IOTPComponentProps {
    taskRunner: ITaskRunner;
    registration: Registration;
}

@injectable()
@NeoModel
export class OTPComponentVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public registration: Registration,
        public securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        public investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);

        this.sendNewOtp = this.sendNewOtp.bind(this);
        this.checkOTP = this.checkOTP.bind(this);
        this.sendNewOtpQuestion = this.sendNewOtpQuestion.bind(this);

        this.otpRegistrationVerification.registrationId = registration.registrationId;
    }

    public showResendOTPModal: boolean = false;

    public otpRegistrationVerification = new OTPRegistrationVerification();

    public sendNewOtp() {
        this.taskRunner.run(async () => {
            var response = await this.securityApiClient.resendOtp(this.otpRegistrationVerification.toJSObject());
            if (response.data) {
                if (response.data.success) {
                    this.sendNewOtpQuestion();
                    this.notifications.addSuccess("OTP", "OTP Resent", NotificationDuration.Standard);
                } else {
                    this.notifications.addDanger("OTP", "OTP Resend failed. Please retry", NotificationDuration.Standard);
                }
            }
        });
    }

    public checkOTP() {
        this.taskRunner.run(async () => {
            var response = await this.securityApiClient.checkRegistrationOTPVerification(this.otpRegistrationVerification.toJSObject());
            if (response.data) {
                if (response.data.success) {
                    // Not understanding why you need an email verification after just confirming cell phone otp?
                    this.registration.otpTypeId = OtpType.Email;
                    this.registerWithEmail();
                } else {
                    this.notifications.addDanger("OTP", "OTP Incorrect", NotificationDuration.Standard);
                }
            }
        }).catch((ex: string) => {
            this.notifications.addDanger("OTP", "An error has occurred", NotificationDuration.Standard);
        });
    }

    public async registerWithEmail() {
        this.registration.otpTypeId = OtpType.Email;
        this.registration.sendEmailOnly = true;

        const response = await this.securityApiClient.register(this.registration.toJSObject());
        if (response.data)
            if (response.data.success) {
                if (response.data.additionalData) {
                    this.otpRegistrationVerification.registrationId = parseInt(response.data.additionalData);
                }
            } else {
                this.notifications.addDanger("Sign Up Error", "Validation error", NotificationDuration.Standard);
            }
    }

    public sendNewOtpQuestion() {
        this.showResendOTPModal = !this.showResendOTPModal;
    }
}

@observer
export default class OTPComponent extends React.Component<IOTPComponentProps> {

    constructor(props: IOTPComponentProps) {
        super(props);

        this.dismiss = this.dismiss.bind(this);
    }

    private viewModel = new OTPComponentVM(this.props.taskRunner, this.props.registration);   

    // Not used?
    // public async fetchInvestor() {
    //     var response = await this.viewModel.investorApiClient.fetchInvestorFromRegistration(this.viewModel.otpRegistrationVerification.toJSObject());
    //     if (response.data !== null) {
    //         this.registerViewParams.investor.set(response.data);
    //         this.registerViewParams.registerParams.registrationState = RegistrationState.AccountType;
    //     } else {
    //         this.viewModel.notifications.addDanger("Fetching Investor", "Fetching investor failed", NotificationDuration.Standard);
    //     }
    // }

    state = { otp: '' };

    handleChange = (otp: string) => {
        this.setState({ otp })
        if (otp.length === 4) {
            this.viewModel.otpRegistrationVerification.otp = otp;
            this.viewModel.checkOTP();
        }
    };

    public dismiss() {
        this.viewModel.navigation.navigateToView(Home);
    }

    public render() {

        return (
            <div>

                <Neo.Modal title="Custom buttons" bind={this.viewModel.meta.showResendOTPModal}
                    buttons={
                        [{ text: "Resend OTP", variant: "success", onClick: this.viewModel.sendNewOtp }, { text: "Cancel", variant: "danger", onClick: this.viewModel.sendNewOtpQuestion }]
                    }>
                    Would you like a new OTP to be re-sent to {this.props.registration.cellNo}?
                </Neo.Modal>

                <div className="otp-container">
                    <div className="otp-overlay" />
                    <div className="otp-right-panel">
                        {this.props.registration.otpTypeId === OtpType.CellNo &&
                            <div className="otpComponent">
                                <p className="header">One Time Pin (OTP)</p>
                                <p className="subHeader">Sent to your cellphone</p>
                                <div className="otpInput">
                                    <OtpInput
                                        value={this.state.otp}
                                        onChange={this.handleChange}
                                        numInputs={4}
                                        separator={<span></span>}
                                        inputStyle={"otp"}
                                        isInputNum={true}
                                        containerStyle={"otp-container"}
                                    />
                                </div>
                                <Neo.Button variant="link" className="resendLink" onClick={this.viewModel.sendNewOtpQuestion} hidden={this.viewModel.showResendOTPModal} >DID NOT RECEIVE ONE?</Neo.Button>
                            </div>}
                        {this.props.registration.otpTypeId === OtpType.Email &&
                            <div className="otp-component-email">
                                <p className="email-header">Verify E-mail</p>
                                <p className="email-sub-header">Verify your email address by clicking on the link in the mail sent to you</p>
                                <Neo.Button className="dismiss" onClick={this.dismiss} >DISMISS</Neo.Button>
                            </div>}
                    </div>
                </div>
            </div>

        );
    }
}