import React from 'react';
import { observer } from 'mobx-react';
import { InfoPanel, InfoPanelColumn } from '../../App/Components/InfoPanel';
import { AppService, Types } from '../../Services/AppService';
import { Neo } from '@singularsystems/neo-react';
import UserInformationVM from './UserInformationVM';
import ChangePassword from '../../Models/Security/ChangePassword';
import { OtpType } from '../../Models/Registration/Enums/OtpType';
import ChangeOTPPreference from '../../Models/Security/ChangeOTPPreference';
import LoginResult from '../../Models/Security/LoginResult';

interface IUserInformationPanelProps {
    
}

@observer
export default class UserInformationPanel extends React.Component<IUserInformationPanelProps> {

    private viewModel = AppService.get(Types.Services.UserInformationVM)

    public render() {
        const viewModel = this.viewModel,
            userInfo = viewModel.userInfo;

        return (
            <div>
                <InfoPanel controller={viewModel.userInfoPanelController} arrowAnchorSelector=".app-user-icon">
                    <InfoPanelColumn name="Home" backToText="Personal Details">
                        <h4>Personal Details</h4>
                        
                        {userInfo &&
                            <div className="mt-3">
                                <Neo.FormGroup display={userInfo.meta.userName} />
                                <Neo.FormGroup display={userInfo.meta.fullName} />
                                <Neo.FormGroup display={userInfo.meta.emailAddress} />
                                <Neo.FormGroup display={userInfo.meta.cellNo} />

                                <p style={{fontSize: 14}}>To update any of the details above, please contact <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za</a></p>
                                <Neo.FormGroup label="Send all OTP verifications via:" 
                                    bind={userInfo.meta.displayOtpType} 
                                    radioList={{ enumType: OtpType, inline: true }}
                                    onChange={() => viewModel.beginChangeOTPPreference()} />

                                <Neo.Button className="mt-3" onClick={() => viewModel.beginChangePassword() }>Change Password</Neo.Button>
                            </div>}
                    </InfoPanelColumn>

                    <InfoPanelColumn name="PasswordDetails" renderBackLink>
                        {viewModel.passwordDetails && this.renderChangePassword(viewModel, viewModel.passwordDetails)}
                    </InfoPanelColumn>

                    <InfoPanelColumn name="ChangeOTPPreference" renderBackLink>
                        {viewModel.otpChangeDetails && userInfo && this.renderChangeOTP(viewModel, userInfo, viewModel.otpChangeDetails)}
                    </InfoPanelColumn>
                </InfoPanel>
            </div>
        );
    }

    private renderChangePassword(viewModel: UserInformationVM, details: ChangePassword) {
        return <Neo.Loader task={viewModel.taskRunner}>
            <h4>Change Password</h4>

            <div className="mt-3">
                <Neo.Alert className="mb-3" show={details.passwordError !== null} onHidden={() => details.passwordError = null} variant="danger">
                    <pre>
                        {details.passwordError}
                    </pre>
                </Neo.Alert>

                <Neo.Form model={details} onSubmit={() => viewModel.changePassword()}>

                    <Neo.FormGroup bind={details.meta.oldPassword} input={{ type: "password" }} />
                    <Neo.FormGroup bind={details.meta.newPassword} input={{ type: "password" }} />
                    <Neo.FormGroup bind={details.meta.confirmPassword} input={{ type: "password", showPasswordButton: true }} />

                    <Neo.Button className="mt-3" isSubmit>Change</Neo.Button>
                </Neo.Form>
            </div>
        </Neo.Loader>
    }

    private renderChangeOTP(viewModel: UserInformationVM, userInfo: LoginResult, changeOtpDetails: ChangeOTPPreference) {
        return <Neo.Loader task={viewModel.taskRunner}>
            <h4>Change OTP Preference</h4>

            {userInfo.canChangeOtpType &&
                <>
                    {!changeOtpDetails.areYouSure && 
                        <div>
                            Are you sure you want to send future one time pins via {changeOtpDetails.otpTypeViaText}?

                            <div className="text-right mt-3">
                                <Neo.Button size="sm" onClick={() => viewModel.confirmChangeOTPPreference()}>Yes</Neo.Button>
                                <Neo.Button size="sm" variant="light" className="ml-2" onClick={() => viewModel.userInfoPanelController.back()}>No</Neo.Button>
                            </div>
                        </div>}

                    {changeOtpDetails.areYouSure &&
                        <div>
                            <p>
                                You have been sent a one time pin, please enter it below and click submit to send future one time pins via {changeOtpDetails.otpTypeViaText}.
                            </p>
                            
                            <Neo.Form model={changeOtpDetails} onSubmit={() => viewModel.endChangeOTPPreference()}>
                                <Neo.FormGroup bind={changeOtpDetails.meta.otp} label="One time pin" />

                                <Neo.Button isSubmit>Submit</Neo.Button>
                            </Neo.Form>
                            
                        </div>}
                </>}
            {!userInfo.canChangeOtpType && 
                <p>
                    Unfortunately you can't change your OTP preference until you have provided us with your cell phone no AND email address.
                </p>}
            
        </Neo.Loader>
    }
}