import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import FAQ from '../Models/Base/FAQ';

@NeoModel
class FAQCriteria extends ModelBase {

    public searchText: string = "";
}

@NeoModel
class FaqVM extends Views.ViewModelBase {

    constructor(public apiClient = AppService.get(Types.ApiClients.FAQApiClient)) {
        super();

        this.autoDispose(this.faqCriteria.onAnyPropertyChanged(() => this.performFilter()));
    }

    public faqCriteria = new FAQCriteria();

    private faqList = new List(FAQ);

    private filteredList: FAQ[] | null = null;

    public async initialise() {
        const result = await this.taskRunner.waitFor(this.apiClient.getFAQList());

        this.faqList.set(result.data);
    }

    private performFilter() {
        if (this.faqCriteria.searchText) {
            const search = new RegExp(this.faqCriteria.searchText, "gi");

            this.filteredList = this.faqList.filter(c => c.question.search(search) >= 0 || c.answer.search(search) >= 0);
        } else {
            this.filteredList = null;
        }
    }

    public getFaqList() {
        if (this.filteredList) {
            return this.filteredList;
        } else {
            return this.faqList;
        }
    }
}

@observer
export default class FAQView extends Views.PersistentViewBase<FaqVM> {

    constructor(props: unknown) {
        super("FAQ", FaqVM, props);
    }

    public render() {
        return (
            <div className="faq-page">
                <h2>FAQs</h2>

                <div className="page-container mt-4">
                    <div className="page-container-center mt-4">
                        <Neo.FormGroup bind={this.viewModel.faqCriteria.meta.searchText} suppressLabel placeholder="Search Frequently Asked Questions" />
                    </div>

                    <hr />

                    <div className="page-container-center pb-4">
                        {this.viewModel.getFaqList().map(faq => (
                            <div key={faq.faqid} className="faq-container">
                                <div className="faq-header">
                                    <h5>{faq.question}</h5>
                                </div>
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            </div>
                        ))}

                        {this.viewModel.getFaqList().length === 0 &&
                            <div>
                                Sorry, no questions match your search criteria.
                            </div>}
                    </div>

                </div>

            </div>
        );
    }
}