import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import { Cart } from '../Models/Cart/Cart';
import CartDetailLookup from '../Models/Cart/CartDetailLookup';

export interface ICartApiClient extends Data.IReadApiClient<Cart, string> {

    /**
     * Returns the cart linked to a portfolio.
     */
    getCart(portfolioID: number): AxiosPromise<Model.PlainTrackedObject<Cart>>;

    /**
     * Saves the cart linked to a portfolio.
     */
    putCart(cart: Model.PartialPlainObject<Cart>): AxiosPromise;

    /** 
     * Deletes a cart detail item linked to a cart.
     */
    deleteCartDetail(portfolioID: number, instrumentID: number | null): AxiosPromise;

    /**
     * Returns the list of cart detail lookup linked to a portfolio.
     */
    getCartDetailLookup(portfolioID: number): AxiosPromise<Array<Model.PlainObject<CartDetailLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class CartApiClient extends Data.ReadApiClient<Cart, string> implements ICartApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/cart`);
    }

    public getCart(portfolioID: number): AxiosPromise<Model.PlainTrackedObject<Cart>> {
        return this.axios.get(`${this.apiPath}/GetCart?portfolioID=${portfolioID}`);
    }

    public putCart(cart: Model.PartialPlainObject<Cart>): AxiosPromise {
        return this.axios.put(`${this.apiPath}/PutCart`, cart);
    }

    public deleteCartDetail(portfolioID: number, instrumentID: number | null): AxiosPromise {
        return this.axios.post(`${this.apiPath}/DeleteCartDetail?portfolioID=${portfolioID}&instrumentID=${instrumentID}`);
    }

    public getCartDetailLookup(portfolioID: number): AxiosPromise<Array<Model.PlainObject<CartDetailLookup>>> {
        return this.axios.get(`${this.apiPath}/GetCartDetailLookup?portfolioID=${portfolioID}`);
    }

    // Client only properties / methods
}