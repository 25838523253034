import { Attributes, Misc, NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class Distribution extends APIModelBase {

    public policyNo: string = "";

    public transactionType: string = "";

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public settlementDate: Date | null = null;

    public instrumentName: string = "";

    public instrumentCode: string = "";

    @Attributes.Float()
    public amount: number = 0;

    @Attributes.Float()
    public withholdingTax: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.policyNo) {
            return "New distribution";
        } else {
            return this.policyNo;
        }
    }
}