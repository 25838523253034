import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import InvestorDocument from '../Models/Investors/InvestorDocument';

export interface IInvestorDocumentApiClient {

    /**
     * Returns Tax Certificates
     */
    getTaxCertificates(): AxiosPromise<Array<Model.PlainTrackedObject<InvestorDocument>>>;

    // Client only properties / methods
}

@injectable()
export default class InvestorDocumentApiClient extends Data.ApiClientBase implements IInvestorDocumentApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/investor-document`);
    }

    public getTaxCertificates(): AxiosPromise<Array<Model.PlainTrackedObject<InvestorDocument>>> {
        return this.axios.get(`${this.apiPath}/getTaxCertificates`);
    }

    // Client only properties / methods
}