import { NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class InvestorAddressDetail extends APIModelBase {

    @Rules.Required()
    public countryID: number | null = null;

    @Rules.Required()
    public provinceID: number | null = null;

    public addressLine1: string = "";

    @Rules.Required("Address Line 1 is required")
    public addressLine2: string = "";

    public addressLine3: string = "";

    @Rules.Required()
    public city: string = "";

    @Rules.Required()
    public suburb: string = "";

    @Rules.Required()
    public postalCode: string = "";

    public documentId: number | null = null

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(iad => iad.postalCode.length > 10, "Postal code must contain 10 or less characters");
    }

    public toString(): string {
        if (this.isNew || !this.addressLine1) {
            return "New investor address detail";
        } else {
            return this.addressLine1;
        }
    }
}