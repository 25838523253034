import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import InvestorLookup from '../Models/Investors/InvestorLookup';
import InvestorFicaStatusLookup from '../Models/Investors/InvestorFicaStatusLookup';

export interface IInvestorQueryApiClient {

    /** 
     * Gets investor details for the users selected investor.
     */
    getInvestorLookup(): AxiosPromise<Model.PlainObject<InvestorLookup>>;

    /** 
     * Gets the fica status breakdown for the users selected investor.
     */
    getFicaStatus(): AxiosPromise<Model.PlainObject<InvestorFicaStatusLookup>>;

    // Client only properties / methods
}

@injectable()
export default class InvestorQueryApiClient extends Data.ApiClientBase implements IInvestorQueryApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/investor-query`);
    }

    public getInvestorLookup(): AxiosPromise<Model.PlainObject<InvestorLookup>> {
        return this.axios.get(`${this.apiPath}/lookup`);
    }

    public getFicaStatus(): AxiosPromise<Model.PlainObject<InvestorFicaStatusLookup>> {
        return this.axios.get(`${this.apiPath}/fica-status`);
    }

    // Client only properties / methods
}