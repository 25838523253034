import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import GraphDataCriteria from './GraphDataCriteria';

@NeoModel
export class GraphData extends LookupBase {

    @Attributes.ChildObject(GraphDataCriteria)
    public criteria: GraphDataCriteria | null = null;

    public totals = new List(TimeValue);

    public instruments = new List(InstrumentSeries);

    // Client only properties / methods
}

@NeoModel
export class TimeValue extends LookupBase {

    @Attributes.Date()
    public valueDate: Date = new Date();

    @Attributes.Float()
    public price: number = 0;

    @Attributes.Float()
    public value: number = 0;

    // Client only properties / methods
}

@NeoModel
export class InstrumentSeries extends LookupBase {

    public instrumentCode: string = "";

    public instrumentName: string = "";

    public assetClassId: number | null = null;

    public regionId: number | null = null;

    public values = new List(TimeValue);

    // Client only properties / methods
}

export interface IGraphData {
    title: string;

    seriesData: IGraphSeries[];
}

export interface IGraphSeries {
    seriesName?: string;

    values: TimeValue[];
}