import { Attributes, List, NeoModel, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';
import { InvestmentType } from '../Registration/Enums/InvestmentType';
import { PaymentType } from "../Registration/Enums/PaymentType";
import { TradeRequest } from "../Base/TradeRequest";

@NeoModel
export class Cart extends APIModelBase {

    public portfolioID: number = 0;

    public sourceOfFundsID: number = 0;

    public sourceOfFundsDescription: string = "";

    @Attributes.Integer()
    public investmentType: InvestmentType = InvestmentType.OnceOffInvestment;

    public paymentMethodID: PaymentType = PaymentType.EFT;

    @Attributes.Integer()
    @Attributes.Nullable()
    public debitOrderDay: number | null = 0;

    public sourceOfFundsDescriptionRequired: boolean = false;

    public cartDetails = new List(CartDetail);

    // Client only properties / methods

    public updateFromTradeRequest(tradeRequest: TradeRequest) {
        this.debitOrderDay = tradeRequest.debitOrderDay;
        this.investmentType = tradeRequest.investmentTypeID;
        this.sourceOfFundsID = tradeRequest.sourceOfFundsID!;
        this.sourceOfFundsDescription = tradeRequest.sourceOfFundsAdditionalInfo;
        this.paymentMethodID = tradeRequest.paymentMethodID;

        tradeRequest.selectedInstruments.forEach(instrument => {
            var existingCartDetail = this.cartDetails.find(c => c.instrumentID === instrument.instrumentID)

            if (existingCartDetail) {
                existingCartDetail.amount = instrument.amount;
            } else {
                var cartItem = this.cartDetails.addNew();
                cartItem.instrumentID = instrument.instrumentID;
                cartItem.amount = instrument.amount;
            }
        });
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.sourceOfFundsDescription) {
            return "New cart";
        } else {
            return this.sourceOfFundsDescription;
        }
    }
}

@NeoModel
export class CartDetail extends APIModelBase {

    public cartDetailID: number = 0;

    public cartID: number = 0;

    public instrumentID: number = 0;

    @Attributes.Float()
    public amount: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New investor cart details";
        } else {
            return "Investor Cart Details";
        }
    }
}