import { Attributes, ITaskRunner, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { IPopoverMenuItem } from '../../App/Components/PopoverButton';
import { AppService, Types } from '../../Services/AppService';
import Instrument from '../Instruments/Instrument';
import PortfolioCatalogue from '../Catalogue/PortfolioCatalogue';
import InvestorLinkedPortfolioLookup from '../Investors/InvestorLinkedPortfolioLookup';
import Distribution from '../Reporting/Distribution';
import { GraphData, IGraphData, IGraphSeries, TimeValue } from './GraphData';
import GraphDataCriteria, { TimeRange } from './GraphDataCriteria';
import { PortfolioBalance } from './PortfolioBalance';
import { IValueByClass } from './ValueByClass';

@NeoModel
export default class AppPortfolioData extends ModelBase {

    constructor(public portfolio: InvestorLinkedPortfolioLookup,
        private portfolioApiClient = AppService.get(Types.ApiClients.PortfolioApiClient),
        private reportingApiClient = AppService.get(Types.ApiClients.ReportingApiClient),
        private instrumentApiClient = AppService.get(Types.ApiClients.InstrumentApiClient),
        private appCache = AppService.get(Types.Services.AppDataCache)) {
        super();
    }

    private _portfolioBalance: PortfolioBalance | null = null;
    private _distributions = new List(Distribution);
    private _hasFetchedDistributions: boolean = false;
    private _hasFetchedInstruments: boolean = false;
    private _instruments = new List(Instrument);

    private portfolioCatalogue: PortfolioCatalogue | null = null;

    @Attributes.Observable(false)
    private portfolioBalancePromise: Promise<any> | null = null;
    @Attributes.Observable(false)
    private distributionPromise: Promise<any> | null = null;

    public get portfolioBalance() {
        if (!this._portfolioBalance) {
            this.fetchPortfolio();
        }
        return this._portfolioBalance;
    }

    public async getPortfolioAsync() {
        const balance = this.portfolioBalance;
        if (balance !== null) {
            return Promise.resolve(this._portfolioBalance!);
        } else {
            await this.portfolioBalancePromise;
            return this.portfolioBalance!;
        }
    }

    public get distributions() {
        if (!this._hasFetchedDistributions) {
            this.fetchDistributions();
        }
        return this._distributions;
    }

    public resetPortfolioData() {
        this._portfolioBalance = null;
    }

    private async fetchPortfolio() {
        if (!this.portfolioBalancePromise) {
            this.portfolioBalancePromise = this.portfolioApiClient.getPortfolioBalance(this.portfolio.portfolioTypeID);
            this.initialise();
            const result = await this.portfolioBalancePromise;

            this._portfolioBalance = PortfolioBalance.fromJSObject<PortfolioBalance>(result.data);
            this.portfolioBalancePromise = null;
        }

        return this._portfolioBalance;
    }

    private async fetchDistributions() {
        if (!this.distributionPromise) {
            this.distributionPromise = this.reportingApiClient.getDistributionList(this.portfolio.portfolioType);
            const result = await this.distributionPromise;

            this._distributions.set(result.data);
            this._hasFetchedDistributions = true;
            this.distributionPromise = null;
        }

        return this._distributions;
    }

    private async initialise() {
        this.portfolioCatalogue = await this.appCache.getPortfolioCatalogue();
        this.graphData = await this.fetchGraphData();
        this.fetchInstruments();
    }

    // Pie chart

    public get assetAllocationByClass(): IValueByClass[] | null {
        const balance = this.portfolioBalance;
        if (balance && this.portfolioCatalogue) {

            const allocations = balance.instruments.groupBy(c => `${c.assetClassId ?? 0} ${c.regionId ?? 0}`, (k, i, d) => {

                const assetClass = this.portfolioCatalogue!.assetClasses.find(c => c.assetClassID === i.assetClassId) ?? "";
                const region = this.portfolioCatalogue!.regions.find(c => c.regionID === i.regionId) ?? "";

                return {
                    assetClass: (assetClass && region) ? `${region} ${assetClass}` : "Uncategorised",
                    value: d.sum(c => c.valueTotal)
                };
            });

            if (balance.cashAvailable > 0) {
                allocations.push({ assetClass: "Cash", value: balance.cashAvailable });
            }

            return allocations.sortBy(c => c.value, "desc");
        }
        return null;
    }

    // Line chart

    public graphCriteria = new GraphDataCriteria();
    public graphData: GraphData | null = null;
    public graphTask = AppService.get(Types.Neo.TaskRunner);

    public async fetchGraphData(taskRunner?: ITaskRunner, criteria?: GraphDataCriteria) {
        let graphCriteria = criteria ?? this.graphCriteria, 
            graphTask = taskRunner ?? this.graphTask

        const result = await graphTask.waitFor(this.portfolioApiClient.getGraphData(this.portfolio.portfolioTypeID, graphCriteria.toQueryObject()));
        
        if (result.data) {
            return GraphData.fromJSObject<GraphData>(result.data);
        }

        return null;
    }

    public async setLineGraphTimeRange(timeRange: TimeRange) {
        if (timeRange !== this.graphCriteria.timeRange) {
            this.graphCriteria.timeRange = timeRange;
            this.graphData = await this.fetchGraphData();
        }
    }

    public get graphSeriesOptions(): IPopoverMenuItem[] {
        const menuItems: IPopoverMenuItem[] = [];
        menuItems.push({ text: "Summary", onClick: () => this.graphCriteria.seriesId = "" });
        menuItems.push({ text: "By Asset Class", onClick: () => this.graphCriteria.seriesId = "ByClass" });

        if (this.graphData) {
            for (let instrument of this.graphData.instruments) {
                menuItems.push({ text: instrument.instrumentName, onClick: () => this.graphCriteria.seriesId = instrument.instrumentCode });
            }
        }

        if (menuItems.length > 2) {
            menuItems[2].isDivider = true;
        }

        return menuItems;
    }

    public getGraphData(seriesId: string, graphData?: GraphData): IGraphData | null {
        let _graphData = graphData ?? this.graphData;

        if (_graphData) {
            if (seriesId && _graphData) {
                if (seriesId === "ByClass") {
                    return this.lineChartByClass;
                }
                const instrument = _graphData.instruments.find(c => c.instrumentCode === seriesId);
                if (instrument) {
                    return { title: instrument.instrumentName, seriesData: [{ values: instrument.values }] };
                } else {
                    return null;
                }
            }
            return { title: "Summary", seriesData: [{ values: _graphData.totals }] };
        }
        return null;
    }

    public async getGraphDataForInstrument(taskRunner: ITaskRunner, criteria: GraphDataCriteria) {
        let graphData = await this.fetchGraphData(taskRunner, criteria);

        if (graphData) {
            return this.getGraphData(criteria.seriesId, graphData);
        }

        return null;
    }

    private get lineChartByClass(): IGraphData {
        const data = this.graphData!;

        const byClass = data.instruments.groupBy(c => `${c.assetClassId ?? 0} ${c.regionId ?? 0}`, (k, i, d) => {

            const assetClass = this.portfolioCatalogue!.assetClasses.find(c => c.assetClassID === i.assetClassId) ?? "";
            const region = this.portfolioCatalogue!.regions.find(c => c.regionID === i.regionId) ?? "";

            const allValues = new Map<number, TimeValue>();

            for (let instrument of d) {
                for (let point of instrument.values) {
                    const timeValue = allValues.getOrAdd!(point.valueDate.getTime(), k => {
                        const timeValue = new TimeValue();
                        timeValue.valueDate = new Date(k);
                        return timeValue;
                    });
                    timeValue.value += point.value;
                }
            }

            return {
                seriesName: (assetClass && region) ? `${region} ${assetClass}` : "Uncategorised",
                values: Array.from(allValues.values()).sortBy(c => c.valueDate.getTime())
            } as IGraphSeries;
        });

        return { title: "By Asset Class", seriesData: byClass.sortBy(c => c.values[c.values.length - 1].value, "desc") };
    }

    public async fetchInstruments() {
        if (!this._hasFetchedInstruments) {
            const results = await this.instrumentApiClient.getTradeableInstrumentList(this.portfolio.portfolioTypeID);

            this._instruments.set(results.data);
            this._hasFetchedInstruments = true;
        }

        return this._instruments;
    }

    public getInstrumentName(instrumentCode: string) {
        return this._instruments.find(c=> c.instrumentCode === instrumentCode)?.instrumentName
    }
}