import { Data, ITaskRunner } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';

import { DocumentType } from '../Models/Enums/DocumentType';
import { MaintenanceDocumentType } from '../Models/Base/Enums/MaintenanceDocumentType';
import DocumentHandler from '../Services/DocumentHandler';

export interface IDocumentApiClient {

    /**
     * Uploads an investor document.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns Status.
    */
    getInvestorDocumentUploadUrl(): string;

    getInvestorDocument(documentType: DocumentType): AxiosPromise<Blob>;

    /**
     * Method used to fetch the document from the DB
    */
    fetchMaintenanceDocument(MaintenanceDocTypeID: number): AxiosPromise<ArrayBuffer>;

    /**
     * Method used to fetch public maintenance document from the DB
    */
    fetchPublicMaintenanceDocument(MaintenanceDocTypeID: number): AxiosPromise<ArrayBuffer>;

    getInstrumentProviderImageUrl(instrumentProviderId: number): string;

    /**
     * Method used to fetch investor document by ID
    */
    getInvestorDocumentById(documentID: number): AxiosPromise<ArrayBuffer>;
    
    // Client only

    readonly allowedInvestorDocumentExtensions: string[];

    downloadMaintenanceDocument(maintenanceDocumentType: MaintenanceDocumentType, taskRunner: ITaskRunner): Promise<void>;

    getTradeDebitOrderMandateDocument(contractNo: string, selfLink: string):  AxiosPromise<ArrayBuffer>;
}

@injectable()
export default class DocumentApiClient extends Data.ApiClientBase implements IDocumentApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/Document`);
    }

    public fetchMaintenanceDocument(MaintenanceDocTypeID: number): AxiosPromise<ArrayBuffer> {
        return this.axios.get(`${this.apiPath}/maintenance-document?MaintenanceDocTypeID=${MaintenanceDocTypeID}`, { responseType: "arraybuffer" });
    }

    public fetchPublicMaintenanceDocument(MaintenanceDocTypeID: number): AxiosPromise<ArrayBuffer> {
        return this.axios.get(`${this.apiPath}/public-maintenance-document?MaintenanceDocTypeID=${MaintenanceDocTypeID}`, { responseType: "arraybuffer" });
    }

    public getInvestorDocumentUploadUrl(): string {
        return `${this.apiPath}/investor-document`;
    }

    public getInvestorDocument(documentType: DocumentType): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}/investor-document?documentType=${documentType}`, { responseType: "blob" });
    }

    public getInstrumentProviderImageUrl(instrumentProviderId: number) {
        return `${this.apiPath}/instrument-provider-image?instrumentProviderId=${instrumentProviderId}`;
    }

    public getInvestorDocumentById(documentID: number): AxiosPromise<ArrayBuffer> {
        return this.axios.get(`${this.apiPath}/investor-document?documentID=${documentID}`, {responseType: "arraybuffer"});
    }

    public getTradeDebitOrderMandateDocument(contractNo: string, selfLink: string):  AxiosPromise<ArrayBuffer> {
        return this.axios.get(`${this.apiPath}/investor-document?ContractNo=${encodeURIComponent(contractNo)}&SelfLink=${encodeURIComponent(selfLink)}`, {responseType: "arraybuffer"});
    }

    // Client only

    public get allowedInvestorDocumentExtensions() {
        return [".jpg", ".jpeg", ".pdf"];
    }

    public async downloadMaintenanceDocument(maintenanceDocumentType: MaintenanceDocumentType, taskRunner: ITaskRunner) {
        const result = await taskRunner.waitFor(this.fetchMaintenanceDocument(maintenanceDocumentType));
        DocumentHandler.convertAndDownloadDocument(result, true);
    }
}