import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import Types from '../AppTypes';
import { AppService } from '../Services/AppService';
import APIResponse from '../Models/APIResponse';
import { Portfolio, PortfolioReinvestDividends, PortfolioSign, PostPortfolio } from '../Models/Registration/Portfolio';
import { PortfolioBalance } from '../Models/Portfolio/PortfolioBalance';
import GraphDataCriteria from '../Models/Portfolio/GraphDataCriteria';
import { GraphData } from '../Models/Portfolio/GraphData';

export interface IPortfolioApiClient {

    /**
     * Returns a single Portfolio based on the PortfolioTypeID supplied.
     */
    getPortfolio(PortfolioTypeID: number): AxiosPromise<Model.PlainTrackedObject<Portfolio>>;

    /**
     * Returns all Portfolios for the Logged in Investor.
     */
    getPortfolioList(): AxiosPromise<Array<Model.PlainTrackedObject<Portfolio>>>;

    /**
     * Creates a new Portfolio of the Portfolio Type specified in the request.
     */
    postPortfolio(Model: Model.PartialPlainObject<PostPortfolio>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * Sets the terms and conditions date on an existing portfolio.
     */
    putPortfolioSign(Model: Model.PartialPlainObject<PortfolioSign>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /** 
     * This method will set the Reinvest dividends indicator
     */
    reinvestDividends(Model: Model.PartialPlainObject<PortfolioReinvestDividends>): AxiosPromise<void>;

    /**
     * Creates a new Portfolio of the Portfolio Type specified in the request.
     * And then populates the portfolio's Terms Accepted Date.
     */
    portfolioOpenAndSign(model: Model.PartialPlainObject<PortfolioSign>): AxiosPromise<void>;

    /**
    * Method used to create new portfolio for an investor.
    */
    portfolioOpen(PortfolioSign: Model.PlainObject<PortfolioSign>): AxiosPromise<Model.PlainObject<APIResponse>>;

    /**
     * Returns the specified Portfolio's balance from Global based on the PortfolioTypeID supplied.
     */
    getPortfolioBalance(portfolioTypeId: number): AxiosPromise<Model.PlainTrackedObject<PortfolioBalance>>;

    getGraphData(portfolioTypeId: number, criteria: Model.PartialPlainNonTrackedObject<GraphDataCriteria>): AxiosPromise<Model.PlainTrackedObject<GraphData>>;

    /**
     * Creates a new Portfolio of the Portfolio Type specified in the request.
     */
    signPortfolio(RequiresInvestmentAdvice: boolean): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;
}

@injectable()
export default class PortfolioApiClient extends Data.ApiClientBase implements IPortfolioApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/portfolio`);
    }

    public getPortfolio(PortfolioTypeID: number): AxiosPromise<Model.PlainTrackedObject<Portfolio>> {
        return this.axios.get(`${this.apiPath}?PortfolioTypeID=${PortfolioTypeID}`);
    }

    public getPortfolioList(): AxiosPromise<Array<Model.PlainTrackedObject<Portfolio>>> {
        return this.axios.get(`${this.apiPath}/portfolio-list`);
    }

    public postPortfolio(Model: Model.PartialPlainObject<PostPortfolio>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}`, Model);
    }

    public putPortfolioSign(Model: Model.PartialPlainObject<PortfolioSign>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/sign`, Model);
    }

    public reinvestDividends(Model: Model.PartialPlainObject<PortfolioReinvestDividends>): AxiosPromise<void> {
        return this.axios.put(`${this.apiPath}/reinvest`, Model);
    }

    public portfolioOpenAndSign(model: Model.PartialPlainObject<PortfolioSign>): AxiosPromise<void> {
        return this.axios.post(`${this.apiPath}/open-signed`, model);
    }

    public portfolioOpen(PortfolioSign: Model.PlainObject<PortfolioSign>): AxiosPromise<Model.PlainObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/open-portfolio`, PortfolioSign)
    }

    public getPortfolioBalance(portfolioTypeId: number): AxiosPromise<Model.PlainTrackedObject<PortfolioBalance>> {
        return this.axios.get(`${this.apiPath}/balance/${portfolioTypeId}`);
    }

    public getGraphData(portfolioTypeId: number, criteria: Model.PartialPlainNonTrackedObject<GraphDataCriteria>): AxiosPromise<Model.PlainTrackedObject<GraphData>> {
        return this.axios.get(`${this.apiPath}/graph-data/${portfolioTypeId}?${Utils.getQueryString(criteria)}`);
    }

    public signPortfolio(RequiresInvestmentAdvice: boolean): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/sign-portfolio?RequiresInvestmentAdvice=${RequiresInvestmentAdvice}`)
    }
}