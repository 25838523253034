import React from 'react';
import DocumentPanelVM from './DocumentPanelVM';
import { observer } from 'mobx-react';
import { InfoPanel, InfoPanelColumn } from '../../App/Components/InfoPanel';
import { Neo } from '@singularsystems/neo-react';
import { MaintenanceDocumentType } from '../../Models/Base/Enums/MaintenanceDocumentType';
import { PortfolioType } from '../../Models/Portfolio/PortfolioType';

interface IDocumentPanelProps {
    viewModel: DocumentPanelVM;
}

@observer
export default class DocumentPanel extends React.Component<IDocumentPanelProps> {

    public render() {
        const viewModel = this.props.viewModel,
            portfolioList = viewModel.appInvestorData.investor?.portfolioList ?? [];

        return (
            <div>
                <InfoPanel controller={viewModel.panelController} arrowAnchorSelector="#user-documents-wrapper">
                    <div className="downloads-info-panel">
                        <InfoPanelColumn name="Home" backToText="Downloads">
                            <h4>Downloads</h4>

                            <Neo.Loader task={viewModel.downloadTask}>
                                {this.renderMainLink("Tax Certificate", () => viewModel.viewTaxCertificates())}
                                {this.renderMainLink("Statement", () => viewModel.beginViewStatement())}
                                {this.renderMainLink("Advice Mandate", () => this.downloadDocument(MaintenanceDocumentType.InvestmentAdviceMandate), "fa fa-download")}
                                {portfolioList.find(c => c.portfolioTypeID === PortfolioType.Discretionary) &&
                                    this.renderMainLink("Discretionary Investment Mandate", () => this.downloadDocument(MaintenanceDocumentType.NormalMandate), "fa fa-download")}
                                {portfolioList.find(c => c.portfolioTypeID === PortfolioType.TaxFree) &&
                                    this.renderMainLink("Tax Free Investment Mandate", () => this.downloadDocument(MaintenanceDocumentType.TaxFreeMandate), "fa fa-download")}
                            </Neo.Loader>
                        </InfoPanelColumn>

                        <InfoPanelColumn name="Statement" renderBackLink>
                            {this.renderStatementCriteria()}
                        </InfoPanelColumn>

                        <InfoPanelColumn name="TaxCertificate" renderBackLink>
                            <h4>Tax Certificates</h4>
                            
                            <Neo.Loader task={viewModel.downloadTask}>
                                {viewModel.taxCertificates.length === 0
                                    ? <div>No tax certificates available.</div>
                                    : <>
                                        <Neo.FormGroup
                                            bind={viewModel.meta.selectedPortfolioType}
                                            select={{
                                                items: viewModel.appInvestorData.investor?.portfolioList,
                                                valueMember: "portfolioTypeID"
                                            }} />
                                        {viewModel.taxCertificateListByPortfolio?.map(c => this.renderMainLink(c.documentName, () => viewModel.downloadTaxCertificate(c.documentID), "fa fa-download"))}
                                    </>}
                            </Neo.Loader>
                        </InfoPanelColumn>
                    </div>
                </InfoPanel>
            </div>
        );
    }

    private renderMainLink(text: string, onClick: () => void, icon?: string) {
        return (
            <a className="link-with-arrow" href="/" onClick={e => { e.preventDefault(); onClick(); }}>
                {text} <i className={"fa fa-fw " + (icon ?? "fa-angle-right")} />
            </a>
        );
    }

    private downloadDocument(documentType: MaintenanceDocumentType) {
        this.props.viewModel.documentApiClient.downloadMaintenanceDocument(documentType, this.props.viewModel.downloadTask);
    }

    private renderStatementCriteria() {
        const viewModel = this.props.viewModel,
            criteria = viewModel.statementCriteria;

        return (
            <div>
                <h4>Statement</h4>
                <Neo.Form model={criteria} className="mt-4" onSubmit={() => viewModel.downloadStatement()}>
                    <Neo.FormGroup bind={criteria.meta.portfolioTypeId} select={{ items: viewModel.appInvestorData.investor?.portfolioList, valueMember: "portfolioTypeID" }} />
                    <Neo.FormGroup bind={criteria.meta.startDate} />
                    <Neo.FormGroup bind={criteria.meta.endDate} />

                    <div className="text-right mt-4">
                        <Neo.Button task={viewModel.downloadTask} isSubmit>Download</Neo.Button>
                    </div>
                </Neo.Form>
            </div>
        );
    }
}