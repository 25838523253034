import { List, Misc, ModalUtils } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import Instrument from "../../../Models/Instruments/Instrument";
import InvestorOTPDetails from "../../../Models/Investors/InvestorOTPDetails";
import { TradeRequest } from "../../../Models/Base/TradeRequest";
import { NotificationDuration } from "../../../Models/Enums/NotificationDuration";
import Investor from "../../../Models/Registration/Investor";
import { AppService, Types } from '../../../Services/AppService';
import StringConstants from "../../../Services/StringConstants";
import ProductView from "./Product";
import Dashboard from '../../Dashboard/Dashboard';
import { RecurringTradeRequest } from "../../../Models/Base/RecurringTradeRequest";
import InvestmentInfoVM from "../../InformationPanel/Components/InvestmentInfoVM";
import InvestorLinkedPortfolioLookup from "../../../Models/Investors/InvestorLinkedPortfolioLookup";
import { TradeConfirmationVM } from "../../Registration/Components/TradeConfirmationComponent";
import AccountInformationVM from "../../InformationPanel/AccountInformationVM";
import { InvestmentType } from "../../../Models/Registration/Enums/InvestmentType";
import { PaymentType } from "../../../Models/Registration/Enums/PaymentType";
import { PurchaseInstrumentDetail } from "../../../Models/Instruments/InstrumentDetail";
import SourceOfFunds from "../../../Models/Catalogue/SourceOfFunds";
import { Cart } from "../../../Models/Cart/Cart";
import { runInAction } from "mobx";

export default class PurchaseVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private currentInvestorService = AppService.get(Types.Services.CurrentInvestorService),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private investorApiClient = AppService.get(Types.ApiClients.InvestorApiClient),
        private recurringTradeRequestApiClient = AppService.get(Types.ApiClients.RecurringTradeRequestApiClient),
        private otpService = AppService.get(Types.Services.OtpService),
        private tradeRequestApiClient = AppService.get(Types.ApiClients.TradeRequestApiClient),
        private cartApiClient = AppService.get(Types.ApiClients.CartApiClient),
    ) {
        super(taskRunner);
    }

    public tradeRequest = new TradeRequest();
    public investorOTPDetails: InvestorOTPDetails | null = null;
    public instruments = new List(Instrument);
    public recurringTradeRequest: RecurringTradeRequest | null = null;
    public investmentVM: InvestmentInfoVM | null = null;
    public portfolio: InvestorLinkedPortfolioLookup | null = null;
    public accountInformation: AccountInformationVM | null = null;
    public tradeConfirmation = new TradeConfirmationVM();
    public cart = new Cart();
    public purchaseSubmitted = false;

    get availableInstruments() {
        return this.instruments.filter(i => i.instrumentID !== -1 && !this.tradeRequest.selectedInstruments.find(c => c.instrumentID === i.instrumentID));
    }

    get sourceOfFundPlaceholder() {
        return this.appDataCache.sourceOfFunds.currentData.find(c => c.sourceOfFundsID === this.tradeRequest.sourceOfFundsID)?.additionalInfoPrompt ?? "";
    }

    get showFreeTextInput() {
        return this.appDataCache.sourceOfFunds.currentData.find(c => c.sourceOfFundsID === this.tradeRequest.sourceOfFundsID)?.requiresAdditionalInfo ?? false;
    }

    public async load(instrumentCode: string) {
        this.portfolio = this.currentInvestorService.investorData!.selectedPortfolio;
        this.accountInformation = this.currentInvestorService.investorData?.accountInformationVM!;
        this.instruments = await this.currentInvestorService.portfolioData?.fetchInstruments()!;

        this.appDataCache.debitOrderDays.get();
        this.appDataCache.sourceOfFunds.get();

        let results = await this.taskRunner.waitForAll({
            tc: this.appDataCache.tradeCosts.getDataAsync(),
            rtr: this.recurringTradeRequestApiClient.getRecurringTradeRequestDetails(this.portfolio?.portfolioTypeID!)
        });

        this.tradeRequest.portfolioTypeID = this.portfolio!.portfolioTypeID;
        this.tradeRequest.tradeCosts = results.tc;

        if (results.rtr.data) {
            this.recurringTradeRequest = RecurringTradeRequest.fromJSObject<RecurringTradeRequest>(results.rtr.data);
            this.tradeRequest.debitOrderDay = this.recurringTradeRequest.debitOrderDay;
            this.tradeRequest.initialSourceOfFundsID = this.recurringTradeRequest.sourceOfFundsID;
            this.tradeRequest.initialSourceOfFundsAdditionalInfo = this.recurringTradeRequest.sourceOfFundsAdditionalInfo;
        }

        var cartResult = await this.taskRunner.waitFor(this.cartApiClient.getCart(this.portfolio!.portfolioID));
        if (cartResult.data) {
            this.cart.set(cartResult.data);
        }

        if (instrumentCode.length > 0) {
            this.preloadInstrument(instrumentCode);
        }

        if (!this.cart.isNew) {
            runInAction(() => this.setTradeRequestFromCart());
        }

        if (!this.investorOTPDetails || !this.accountInformation.investor) {
            const results = await this.taskRunner.waitForAll({ otpDetails: this.investorApiClient.getInvestorOTPDetails(), investor: this.investorApiClient.getInvestor() });

            this.investorOTPDetails = InvestorOTPDetails.fromJSObject<InvestorOTPDetails>(results.otpDetails.data);
            this.accountInformation.investor = Investor.fromJSObject<Investor>(results.investor.data);
        } else {
            ModalUtils.showMessage('Error loading investor details', 'Please try again.');
        }
    }

    public calculateTradeFees() {
        this.tradeRequest.selectedInstruments.forEach(si => {
            const instrument = this.instruments.find(i => i.instrumentID === si.instrumentID);

            if (instrument) {
                si.setPurchaseTradeValues(this.tradeRequest, instrument);
            } else {
                // Instrument in the dropdown was cleared.
                this.tradeRequest.calculateFees();
            }
        });
    }

    public editBankDetails() {
        this.currentInvestorService!.investorData!.accountInformationVM.investor!.primaryBankDetails = null;
        this.currentInvestorService!.investorData!.accountInformationVM.beginEditBankDetails();
        this.tradeRequest = new TradeRequest();
        this.navigateToDashboard();
    }

    public navigateToDashboard(success: boolean = false) {
        if (success) {
            this.currentInvestorService.portfolioData!.resetPortfolioData();
            this.navigation.navigateToView(Dashboard);
        } else {
            this.navigation.navigateToView(ProductView);
        }
    }

    public preloadInstrument(selectedInstrumentCode: string | null, amount: number = 0, instrumentID: number | null = 0) {

        let foundInstrument: Instrument | undefined;

        if (selectedInstrumentCode) {
            foundInstrument = this.instruments.find(c => c.instrumentCode === selectedInstrumentCode);
        } else if (instrumentID) {
            foundInstrument = this.instruments.find(c => c.instrumentID === instrumentID);
        }

        if (foundInstrument) {
            if (!this.tradeRequest.selectedInstruments.find(c => c.instrumentID === foundInstrument?.instrumentID)) {
                let instrument = new PurchaseInstrumentDetail();

                instrument.amount = amount;
                instrument.instrumentID = foundInstrument.instrumentID;
                instrument.instrument = foundInstrument.instrumentName;

                this.tradeRequest.selectedInstruments.push(instrument);
            }
        } else {
            this.notifications.addDanger("Error Loading Instrument", "One or more supplied instrument codes does not exist or is currently not tradeable.", NotificationDuration.Standard);
        }
    }

    public async showOtp(resendOtp: boolean = true) {
        const result = await this.sendOtp(resendOtp);
        if (result.cancelled) {
            if ((await ModalUtils.showYesNo("Cancel Trade Request", "Are you sure you want to cancel the trade request?")) === Misc.ModalResult.No) {
                this.showOtp(false);
            } else {
                this.otpService.currentModel = null;
            }
        }
    }

    private async sendOtp(sendOtp: boolean = true) {
        const result = await this.otpService.showOtpModal(this.investorOTPDetails!, sendOtp);

        if (result.otp) {
            this.tradeRequest.otp = result.otp;
            this.submitInstruction();
        }

        return result;
    }

    private async submitInstruction() {
        if (this.tradeRequest.portfolioTypeID !== null) {
            await this.taskRunner.run(async (options) => {
                options.onError = (e) => {
                    if (!e.isServerError) {
                        var errorDetail = e.body.toLocaleLowerCase();
                        if (errorDetail.includes("otp")) {
                            if (errorDetail.includes("locked out")) {
                                this.otpService.currentModel = null;
                                this.navigateToDashboard();
                            } else {
                                this.showOtp(false);
                            }
                        };
                    } else {
                        this.notifications.addWarning("OTP", StringConstants.getErrorNotificationText("during the submission of your OTP"), NotificationDuration.Long);
                    }
                };

                if (this.tradeRequest.investmentTypeID === InvestmentType.OnceOffInvestment) {
                    await this.tradeRequestApiClient.purchase(this.tradeRequest.toPurchase().toJSObject());
                } else {
                    this.tradeRequest.paymentMethodID = PaymentType.OnceOffCollection;
                    await this.recurringTradeRequestApiClient.postRecurringTradeRequest(this.tradeRequest.toRecurringTradeRequest().toJSObject());
                }

                this.otpService.currentModel = null;

                this.tradeRequest.paymentMethodID === PaymentType.OnceOffCollection
                    ? this.tradeConfirmation.showSuccessModal = true
                    : this.tradeConfirmation.showBankDetails = true;

                this.cartApiClient.deleteCartDetail(this.cart.portfolioID, null)

                this.cart = new Cart();
                this.tradeRequest = new TradeRequest();
                this.tradeConfirmation = new TradeConfirmationVM();

                this.purchaseSubmitted = true;
            });
        } else {
            this.notifications.addWarning("Issue during submission of purchase", "Please try again", NotificationDuration.Long);
        }
    }

    public showAccountInformationPanel() {
        this.currentInvestorService.toggleAccountInformationPanel();
        this.accountInformation!.selectPortfolio(this.portfolio!);
    }

    public setSourceOfFundsValues(item: SourceOfFunds) {
        this.tradeRequest.isSourceOfFundsAdditionalInfoRequired = item.requiresAdditionalInfo;

        if (!item.requiresAdditionalInfo) {
            this.tradeRequest.sourceOfFundsAdditionalInfo = "";
        }
    }

    public setTradeRequestFromCart() {
        this.tradeRequest.debitOrderDay = this.cart.debitOrderDay ?? 0;
        this.tradeRequest.investmentTypeID = this.cart.investmentType;
        this.tradeRequest.sourceOfFundsID = this.cart.sourceOfFundsID;
        this.tradeRequest.isSourceOfFundsAdditionalInfoRequired = this.cart.sourceOfFundsDescriptionRequired;
        this.tradeRequest.sourceOfFundsAdditionalInfo = this.cart.sourceOfFundsDescription;
        this.tradeRequest.paymentMethodID = this.cart.paymentMethodID;

        if (this.cart.cartDetails.length > 0) {
            this.cart.cartDetails.forEach(instrument => {
                this.preloadInstrument(null, instrument.amount, instrument.instrumentID);
            });
        } else {
            this.tradeRequest.ensureAtLeastOneInstrument();
        }

        this.calculateTradeFees();
    }

    public checkAndSaveCart() {
        var validation = this.tradeRequest.validator.getRuleResult(TradeRequest.validTradeRules);

        if (validation!.isValid && this.tradeRequest.isValid) {
            runInAction(() => this.saveCart());
            return null;
        }
        else {
            if (!this.purchaseSubmitted) {
                this.notifications.addDanger("Unable to save information", validation?.errors, NotificationDuration.Standard);
                return validation?.errors;
            }
        }
        return null;
    }

    public async saveCart() {
        if (this.tradeRequest.isDirty) {
            this.cart.portfolioID = this.currentInvestorService.portfolioData!.portfolio.portfolioID;
            this.cart.updateFromTradeRequest(this.tradeRequest);

            await this.taskRunner.run(async () => {
                this.cartApiClient.putCart(this.cart.toJSObject());
                this.notifications.addSuccess("Information updated", "Investment information updated successfully", NotificationDuration.Standard);
            });
        }
    }

    public async deleteCartDetail(instrumentId: number) {
        if (instrumentId !== 0) {
            await this.taskRunner.run(async () => {
                this.cartApiClient.deleteCartDetail(this.cart.portfolioID, instrumentId)
                this.notifications.addSuccess("Instrument deleted", "The instrument was successfully deleted", NotificationDuration.Standard);
                this.cart.cartDetails.removeWithoutTracking(this.cart.cartDetails.find(c => c.instrumentID === instrumentId)!);
            })
        }

        this.tradeRequest.ensureAtLeastOneInstrument();
    }
}