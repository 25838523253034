import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import DashboardVM from '../DashboardVM';
import { PortfolioBalance, PortfolioInstrumentBalance } from '../../../Models/Portfolio/PortfolioBalance';
import { IGraphData } from '../../../Models/Portfolio/GraphData';
import { observer } from 'mobx-react';
import StringConstants from '../../../Services/StringConstants';
import { TransformMetaType } from '@singularsystems/neo-core/dist/Model';
import { IButtonDropDownItem } from '@singularsystems/neo-core/dist/Components';
import ModalLineChart from './ModalLineChart';

interface IETPGridComponentProps {
    viewModel: DashboardVM;
    balance: PortfolioBalance;
    onWithdraw: (instrumentCode: string) => void;
    onSwitch: (instrumentCode: string) => void;
    onPurchase: (instrumentCode: string) => void;
}

@observer
export default class ETPGrid extends React.Component<IETPGridComponentProps> {

    getButtonOptions(item: PortfolioInstrumentBalance, meta: TransformMetaType<PortfolioInstrumentBalance>) {
        let options: IButtonDropDownItem[] = [{ text: "Buy More", onClick: () => this.props.onPurchase(item.instrumentCode) }];

        if (!item.valuePending) {
            options.push({ text: "Withdraw", onClick: () => this.props.onWithdraw(item.instrumentCode) });
            meta.valueAvailable.value > 0 && options.push({ text: "Switch", onClick: () => this.props.onSwitch(item.instrumentCode) });
        }

        return options;
    }
    
    public render() {
        const { viewModel, balance } = this.props;
        const isFicad = viewModel.investorInfo?.ficaVerified;
        const isReadyToInvest = viewModel.investorService.investorData?.readyToInvest;

        return (
            <div>
                <div className="grid-container">
                    <NeoGrid.Grid items={balance.instruments.filter(c => !c.isCashInstrument)}>
                        {(item, meta) => (
                            <NeoGrid.Row>
                                <NeoGrid.Column width={48} hideBelow="md">
                                    <img src={viewModel.documentApiClient.getInstrumentProviderImageUrl(item.instrumentProviderId!)} alt="logo" style={{ width: 32 }} />
                                </NeoGrid.Column>
                                <NeoGrid.Column label="ETP Name" display={meta.instrument} className="color-highlight" />
                                <NeoGrid.Column label="Units" display={meta.unitsAvailable} numProps={{ formatString: "#,##0.##", zeroText: "-" }} />
                                <NeoGrid.Column label="Share Price" display={meta.price} numProps={{ currencySymbol: "R", zeroText: "-" }} hideBelow="md" />
                                <NeoGrid.Column label="Pending Trade (R)" display={meta.valuePending} numProps={{ currencySymbol: "R", zeroText: "-" }} className="color-alt" hideBelow="md" />
                                <NeoGrid.Column label="Total Value (R)" display={meta.valueInclPending} numProps={{ currencySymbol: "R", zeroText: "-" }} className="color-alt" hideBelow="md" />
                                <NeoGrid.Column label="Value Available (R)" display={meta.availableValueInclPending} numProps={{ currencySymbol: "R", zeroText: "-" }} className="color-alt" />
                                <NeoGrid.Column alignment="center" hideBelow="lg">
                                    <Neo.Button 
                                        isOutline 
                                        disabled={!item.hasGraphDataAvailable}
                                        onClick={() => viewModel.showInstrumentGraphData(item.instrumentCode, item.isModelPortfolio)}>View Graph</Neo.Button>
                                </NeoGrid.Column>
                                <NeoGrid.ButtonColumn>
                                    <Neo.Button isOutline disabled={!isFicad || !isReadyToInvest || !item.isTradeable}
                                        tooltip={this.addToolTip(item.valuePending ? true : false, isFicad!, isReadyToInvest!, meta.valueAvailable.value > 0)} menuItems={this.getButtonOptions(item, meta)}>Transact</Neo.Button>
                                </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                        )}
                    </NeoGrid.Grid>
                </div>

                <Neo.Modal
                    title={viewModel.instrumentGraphData?.title ?? ""}
                    bindModel={viewModel.meta.instrumentGraphData}
                    closeButton={false}
                    size="lg">
                    {(graphData: IGraphData) => (
                        <div>
                            <ModalLineChart
                                graphTask={viewModel.instrumentGraphTask}
                                graphState={graphData} 
                                graphCriteria={viewModel.instrumentGraphCriteria}
                                setLineGraphTimeRange={ (timeRange) => viewModel.setLineGraphTimeRange(timeRange)}
                            />
                        </div>
                    )}
                </Neo.Modal>
            </div>
        );
    }

    private addToolTip(hasPending: boolean, isFicad: boolean, isReadyToInvest: boolean, hasAvailableValue: boolean) {
        return hasPending ? StringConstants.pendingTradesTooltip : (!isFicad ? StringConstants.notFicadTooltip : (!isReadyToInvest ? StringConstants.notReadyToInvestTooltip : (!hasAvailableValue ? StringConstants.noValueAvailable : "")));
    }

}