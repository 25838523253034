import { Attributes, NeoModel, Utils, Validation } from '@singularsystems/neo-core';
import InvestorAddressDetail from '../Investors/InvestorAddressDetail';
import InvestorBankDetail from '../Investors/InvestorBankDetail';
import InvestorOTPDetails from "../Investors/InvestorOTPDetails";
import InvestorTaxDetail from '../Investors/InvestorTaxDetail';
import APIModelBase from '../Library/APIModelBase';

@NeoModel
export default class Investor extends APIModelBase implements InvestorOTPDetails {

    public investorID: number = 0;

    public guid: string = Utils.emptyGuid();

    public firstName: string = "";

    public surname: string = "";

    public emailAddress: string = "";

    @Attributes.Integer()
    public otpMethod: number = 0;

    public cellNo: string = "";

    public idNo: string = "";

    @Attributes.Date()
    public birthDate: Date | null = null;

    public investorTypeID: number = 0;

    public investorStatusID: number = 0;

    @Attributes.Integer()
    public investorFICAStatusIdentity: number = 0;

    public identityStatusReason: string = "";

    @Attributes.Integer()
    public investorFICAStatusAddress: number = 0;

    public addressStatusReason: string = "";

    public hideTour: boolean = false;

    @Attributes.Integer()
    public investorFICAStatusTaxNumber: number = 0;

    public taxStatusReason: string = "";

    public requiresInvestmentAdvice: boolean = false;

    @Attributes.ChildObject(InvestorAddressDetail)
    public addressDetails = new InvestorAddressDetail();

    @Attributes.ChildObject(InvestorBankDetail)
    public primaryBankDetails: InvestorBankDetail | null = null;

    @Attributes.ChildObject(InvestorBankDetail)
    public thirdPartyBankDetails: InvestorBankDetail | null = null;

    @Attributes.ChildObject(InvestorTaxDetail)
    public investorTaxDetail = new InvestorTaxDetail();

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.guid) {
            return "New investor";
        } else {
            return this.guid;
        }
    }
}