import { NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../ApiModelBase';

@NeoModel
export default class ForgotPassword extends APIModelBase {

    @Rules.Required()
    public username: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.username) {
            return "New forgot password";
        } else {
            return this.username;
        }
    }
}