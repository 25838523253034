import { List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import Strategy from './Strategy';
import AssetClass from './AssetClass';
import Region from './Region';
import InstrumentProvider from '../Instruments/InstrumentProvider';

@NeoModel
export default class PortfolioCatalogue extends LookupBase {

    public assetClasses = new List(AssetClass);

    public regions = new List(Region);

    public instrumentProviders = new List(InstrumentProvider);

    public strategies = new List(Strategy);

    // Client only properties / methods
}