import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import { NotificationDuration } from '../../Models/Enums/NotificationDuration'
import LoginDetails from '../../Models/Security/LoginDetails';
import RegisterView from '../Registration/RegisterView';
import LoginResult from '../../Models/Security/LoginResult';
import Home from '../Dashboard/Dashboard';

@NeoModel
export default class LoginVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient),
        private authenticationService = AppService.get(Types.Services.AppAuthenticationService),
        private investorService = AppService.get(Types.Services.CurrentInvestorService),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    ) {

        super(taskRunner);
    }

    public loginDetails = new LoginDetails();

    public signIn() {
        this.taskRunner.run(async (opt) => {

            try {
                const response = await this.securityApiClient.login(this.loginDetails.toJSObject());

                const appUser = this.authenticationService.storeUser(LoginResult.fromJSObject<LoginResult>(response.data));

                if (this.investorService.investorData) {
                    this.investorService.investorData.investor = appUser.userInfo.investor;
                }
    
                // The RouteView app component will redirect to the correct location if the user is in the wrong state.
                this.navigation.navigateToView(Home);

            } catch (e) {
                this.notifications.addDanger("Login Error", "This account does not exist or your username and password do not match. Please make sure all fields are filled in correctly or reset your password.", NotificationDuration.Long);
                this.loginDetails.password = "";
            }
        });
    }

    public register() {
        this.navigation.navigateToView(RegisterView);
    }
}