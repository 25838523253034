import { NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { FicaStatus } from '../Enums/FicaStatus';
import APIModelBase from '../Library/APIModelBase';
import Bank from '../Catalogue/Bank';

@NeoModel
export default class InvestorBankDetail extends APIModelBase {

    public investorID: number = 0;

    @Rules.Required()
    public bankID: number | null = null;
    
    @Rules.Required()
    public accountNo: string = "";

    @Rules.Required()
    public bankAccountTypeID: number = 0;

    @Rules.Required()
    public branchCode: string = "";

    public firstName: string = "";

    public surname: string = "";
    
    public thirdPartyIDNo: string = "";

    public documentID: number | null = null;

    public ficaStatus: number = FicaStatus.Pending;

    public rejectedReason: string = "";

    public isThirdParty: boolean = false;

    // Client only properties / methods

    public setBranchCode(bank?: Bank | null) {  
        this.branchCode = bank?.defaultBranchCode ?? "";
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(ibd => !ibd.firstName?.trim() && ibd.isThirdParty , "First name is required").onProperties(c => c.firstName);
        rules.failWhen(ibd => !ibd.surname?.trim() && ibd.isThirdParty , "Surname is required").onProperties(c => c.surname);
        rules.failWhen(ibd => !ibd.thirdPartyIDNo?.trim() && ibd.isThirdParty, "ID number is required").onProperties(c => c.thirdPartyIDNo);
        rules.failWhen(ibd => !ibd.documentID && ibd.isThirdParty, "Document is required").onProperties(c => c.documentID);
    }

    public toString(): string {
        if (this.isNew || !this.accountNo) {
            return "New investor bank detail";
        } else {
            return this.accountNo;
        }
    }
}