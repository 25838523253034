import React from 'react';
import { TimeRange } from '../../../Models/Portfolio/GraphDataCriteria';
import PopoverButton from '../../../App/Components/PopoverButton';
import { EnumHelper } from '@singularsystems/neo-core';
import ETLoader from '../../../App/Components/ETLoader';
import AppPortfolioData from '../../../Models/Portfolio/AppPortfolioData';
import { observer } from 'mobx-react';
import { IGraphData } from '../../../Models/Portfolio/GraphData';
import LineChart from './LineChart';

interface IDashboardLineChartProps {
    data: AppPortfolioData;
    graphData?: IGraphData;
    suppressSeriesOptions?: boolean;
}

@observer
export default class DashboardLineChart extends React.Component<IDashboardLineChartProps> {

    public render() {
        
        const data = this.props.data,
            showSeriesOptions = !this.props.suppressSeriesOptions,
            graphData = this.props.graphData ?? data.getGraphData(data.graphCriteria.seriesId);
            
        return (
            <div>
                <div className="dashboard-main-graph-header">

                    <div className="graph-title">
                        {showSeriesOptions &&
                            <>
                                <h4>Performance</h4>
                                <small>Your Investment over a period of time</small>
                            </>}
                    </div>
                    {data.graphData && !data.graphTask.isBusy &&
                        <div className="graph-criteria content-box grey">
                            <div className="popover-drop-down">
                                <small>Period: {EnumHelper.getItemMetadata(TimeRange, data.graphCriteria.timeRange).display}</small>
                                <PopoverButton menuItems={EnumHelper.asList(TimeRange).map(c => ({ text: c.name, onClick: () => data.setLineGraphTimeRange(c.id as TimeRange) }))} />
                            </div>
                            {showSeriesOptions &&
                                <div className="popover-drop-down ml-3">
                                    <small>{graphData?.title}</small>
                                    <PopoverButton menuItems={data.graphSeriesOptions} dropDownClassName="instruments" />
                                </div>}
                        </div>}
                    {data.graphTask.isBusy && <ETLoader palette="blue" />}
                </div>
                <LineChart graphData={graphData!}/>
            </div>
        )
    }
}