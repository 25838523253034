import { EnumHelper } from "@singularsystems/neo-core";

export enum RegistrationState {
    PersonalDetails = 1,
    AccountType = 2,
    Identification = 3,
    PoliticalSensitivity = 4,
    ProofOfResidence = 5,
    BankDetails = 6,
    TaxDetails = 7,
    Mandate = 8,
    AccountCreated = 9
}

EnumHelper.decorateEnum(RegistrationState, e => {
    e.describe(RegistrationState.AccountCreated, "Completion")
})